import axios from 'axios'
import { addDocuments, getDocuments } from 'common/apis/maindata'
import { DocumentList } from 'components/DocumentList'
import { DocumentManager } from 'components/DocumentManager'
import React, { useState, useEffect, useMemo } from 'react'

export default function Documents({}) {
  const basePath = '/_/wx'
  const initDirectories = () => {
    const tree = {
      id: null,
      key: basePath,
      name: null,
      fullName: basePath,
      fileType: 1,
      extraProperties: {},
      title: '微信文档',
      children: [
        {
          id: null,
          key: `${basePath}/服务业务`,
          name: '服务业务',
          title: '服务业务',
          fullName: `${basePath}/服务业务`,
          fileType: 1,
          extraProperties: {},
          children: [],
        },
        {
          id: null,
          key: `${basePath}/产品技术资料`,
          name: '产品技术资料',
          title: '产品技术资料',
          fullName: `${basePath}/产品技术资料`,
          fileType: 1,
          extraProperties: {},
          children: [],
        },
      ],
    }
    return tree
  }
  const directoryTree = useMemo(initDirectories, basePath)
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>微信文档</div>
        </div>
        <div className='widget-body device-profile-details'>
          <DocumentManager
            basePath={'/_/wx'}
            initDirectory={directoryTree}
            onCreatedDirectory={({ action, item, data }) => {
              console.log(data)
            }}
          ></DocumentManager>
        </div>
      </div>
    </div>
  )
}
