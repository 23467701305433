import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Dialog, Icon, Tooltip, WithTooltip } from '@abb/abb-common-ux-react'
import { getUnAddUsers, getUserPage } from 'common/endpoints'
import { CommonIndex } from 'components/LoongInCommon'
import { t } from 'i18next'
import axios from 'axios'
import { addUserToOrganization } from 'common/endpoints'
import { getClientHeight } from 'slices/appSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'

import { getWechatTags, setWechatTags } from 'common/apis/wechat'

export const SearchUser = forwardRef(({ onRefresh }, ref) => {
  const refIndex = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const [users, setUsers] = useState([])
  const [action, setAction] = useState(null)
  const dispatch = useDispatch()
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //列定义
  //列定义
  const columns = [
    {
      title: 'factory.name',
      key: 'name',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = item.id <= 2
        if (isDeleted)
          return (
            <WithTooltip>
              <div>
                <Icon name='abb/lock-closed' style={{ marginRight: 10 }}></Icon>
                {item.name}
              </div>
              <Tooltip>
                <div>微信保留标签</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
  ]
  const addUsers = async (items, callback) => {
    const userIds = users.map((u) => u.id)
    const params = { wechatUsers: userIds, tags: items }
    axios.post(setWechatTags(action), params).then((response) => {
      if (response.status === 200 || response.status === 204) {
        dispatch(showNotification(NotificationType.Success, t('t_successToAdd')))
        if (callback) callback()
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_failToAdd')))
      }
    })
  }
  const onConfirm = () => {
    if (!checkItems || checkItems.length <= 0) {
      dispatch(showNotification(NotificationType.Alarm, t('t_plsSelectUser')))
      return
    }
    addUsers(
      checkItems.map((item) => {
        return item.id
      }),
      () => {
        setIsVisible(false)
        onRefresh && onRefresh()
      }
    )
  }
  const onCancel = () => {
    setIsVisible(false)
  }
  const showDialog = (action, users) => {
    setCheckItems([])
    setUsers(users)
    setAction(action)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))
  const onSelectionChange = (items) => {
    // console.log(items)
    setCheckItems(items)
  }
  //返回页面
  return (
    <Dialog
      style={{ width: '960px' }}
      showCloseButton={true}
      closeOnLostFocus
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={action === 'bind' ? '批量设置标签' : '批量移除标签'}
      standardButtonsOnBottom={[
        {
          text: t('b_confirm'),
          type: 'primary-blue',
          handler: onConfirm,
        },
        {
          text: t('b_cancel'),
          type: 'normal',
          handler: onCancel,
        },
      ]}
      onClose={() => setIsVisible(false)}
    >
      <div style={{ height: '420px' }}>
        <CommonIndex
          tableWidth={'100%'}
          ref={refIndex}
          columns={columns}
          getList={getWechatTags}
          canSearch={false}
          pageSize={6}
          enableSorting={false}
          tableHeight={300}
          className='admin-main-content2 with-padding'
          searchClassName='searching-form2'
          canSelection={true} //是否开启选择功能
          multipleChoice={true}
          onSelectionChange={onSelectionChange} //选择改变时触发
        />
      </div>
    </Dialog>
  )
})
