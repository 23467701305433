import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip } from '@abb/abb-common-ux-react'
import { getDistributorEngineerPage, deleteDistributorEngineer } from 'common/apis/maindata'
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'
import { t } from 'i18next'
import {  useParams } from 'react-router-dom'

export default function DistributorEngineers() {
  const urlParams = useParams()
  const distributorId = urlParams.id

  const [deletedItems, setDeletedItems] = useState([])
  const refIndex = useRef(null)

  //列定义
  const columns = [
    {
      title: 'distributor.name',
      key: 'distributorname',
      render:  (item, index) => {
        return item?.distributor?.name
      },
    },
    {
      title: 'distributorEngineer.name',
      key: 'name',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>数据已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: 'distributorEngineer.title',
      key: 'title',
    },
    {
      title: 'distributorEngineer.desc',
      key: 'desc',
      ellipsis: true,
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  const getGetListInput = (params) => {
    let x = { ...params }
    if (distributorId)
      x.distributorId = distributorId
    x.getDistributor = true
    return x
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getDistributorEngineerPage}
        getGetListInput={getGetListInput}
        deleteData={deleteDistributorEngineer}
        FormEdit={FormEdit}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        enableSorting={true}
        extraData={{distributorId}}
      />
    </>
  )
}
