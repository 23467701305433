const { useSelector } = require('react-redux')
const { getSettings } = require('slices/abpApplicationConfigurationSlice')

export const useSetting = (key) => {
  const settings = useSelector(getSettings)
  if (!settings || !settings.values) {
    return null
  }
  return settings.values[key]
}
