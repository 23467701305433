export const getWechatMenuGroups=()=>`api/Wechat/MenuGroups/list`
export const pullWechatMenus=()=>`api/Wechat/MenuGroups/pull`
export const publishWechatMenus=()=>`api/Wechat/MenuGroups/publish`

export const createWechatMenuGroup=()=>`api/Wechat/MenuGroups/create`
export const updateWechatMenuGroup=()=>`api/Wechat/MenuGroups/update`
export const deleteWechatMenuGroup=()=>`api/Wechat/MenuGroups/delete`

export const getWechatMenus=()=>`api/Wechat/Menus/list`
export const createWechatMenu=()=>`api/Wechat/Menus/create`
export const updateWechatMenu=()=>`api/Wechat/Menus/update`
export const deleteWechatMenu=()=>`api/Wechat/Menus/delete`

export const getWechatUsers=()=>`api/Wechat/Users/list`
export const pullWechatUsers=()=>`api/Wechat/Users/pull`

export const getWechatTags=()=>`api/Wechat/Tags/list`
export const pullWechatTags=()=>`api/Wechat/Tags/pull`
export const createWechatTag=()=>`api/Wechat/Tags/create`
export const updateWechatTag=()=>`api/Wechat/Tags/update`
export const deleteWechatTag=()=>`api/Wechat/Tags/delete`
export const setWechatTags=(action)=> `api/Wechat/Tags/${action}`

export const getWechatKeywords=()=>`api/Wechat/Keywords/list`
export const createWechatKeyword=()=>`api/Wechat/Keywords/create`
export const updateWechatKeyword=()=>`api/Wechat/Keywords/update`
export const deleteWechatKeyword=()=>`api/Wechat/Keywords/delete`

export const getWechatMaterials=()=>`api/Wechat/Materials/list`
export const getWechatMaterialsByContentType=()=>`api/Wechat/Materials/gets`
export const createWechatMaterial=()=>`api/Wechat/Materials/create`
export const updateWechatMaterial=()=>`api/Wechat/Materials/update`
export const deleteWechatMaterial=()=>`api/Wechat/Materials/delete`
export const uploadWechatMaterial=()=>`api/Wechat/Materials/upload`
export const downloadWechatMaterial=(path)=>`api/Wechat/Materials/download?path=${encodeURI(path)}`
export const pullWechatMaterials=()=>`api/Wechat/Materials/pull`
