import axios from 'axios'
import { downXlsSummary, getQuAnswerSummary, saveQuAnswer, saveQuestionnaires } from 'common/apis/questionnaire'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { Button, Card, Checkbox, Dropdown, DropdownOption, Input, NumericInput, RadioGroup, RadioOption, Radio } from '@abb/abb-common-ux-react'
import DatePicker from "react-datepicker"
import { getDictionary } from 'slices/dataDictionarySlice'
import { getDataDictionaryDetail } from 'common/dataDictionary'
import { Col, Row } from 'components/Grid'
import "./questionaires.scss"
import { useHistory } from 'react-router-dom'
import { getQuestionaireCache, setQuestionaireCache } from 'slices/questionaireSlice'
import { useMountedState } from 'common/useMountedState'
import moment from 'moment'
import { isEmail, isIdCard, isMobile, isVehicleNumber } from 'common/utils'
import { getEnv } from 'slices/envSlice'


export default function AnswerSummary() {
  const isMounted = useMountedState()
  const history = useHistory()
  const urlParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [main, setMain] = useState({})
  const [questions, setQuestions] = useState([])
  const dictionary = useSelector(getDictionary)
  const questionaireCache = useSelector(getQuestionaireCache)

  const env = useSelector(getEnv)
  const questionaireId = urlParams.id
  const initData = (data) => {

    setMain(data.main)
    setQuestions(data.questionSummary)
  }
  //读取数据
  const getData = (id) => {
    if (!isMounted()) return
    setIsLoading(true)
    axios.post(getQuAnswerSummary(id), {}).then((response) => {
      if (!isMounted()) return
      if (response.status === 200) {
        initData(response.data)
      } else {
        dispatch(showNotification(NotificationType.Warn, response.data.message))
      }
    }).catch(e => {
      console.log("error: ", e)
      dispatch(showNotification(NotificationType.Alarm, "出现错误"))
    }).finally(() => {
      setIsLoading(false)
    })
  }

  //获取当前问题的选项
  const getCurrentOptions = (question) => {
    let curOptions = question.OptionSummary.filter(c => (question.questionId && c.questionId == question.questionId))
    curOptions = curOptions.sort((a, b) => a.rowIndex - b.rowIndex);
    return curOptions
  }
  const getSortQuestions = () => {
    if (!questions) return []
    const sortQuestions = questions.sort((a, b) => a.rowIndex - b.rowIndex);
    return sortQuestions
  }

  const getImage = (question, option) => {
    if (question.answerChoice == option.rowIndex.toString()) {
      return '/images/' + option.optionPicture
    } else {
      return '/images/0-' + option.optionPicture
    }
  }
  const renderScore = (question, curOptions) => {
    return (
      <div style={{
        display: "flex", width: "100%", padding: "8px"
      }}>{
          curOptions.map((option, index) => {
            return (
              <div key={index}
                style={{
                  border: "1px solid #eee",
                  width: "20%",
                  cursor: "pointer",
                  padding: "4px 0"
                }}
                onClick={(e) => {
                  question.answerChoice = option.rowIndex.toString()
                  setQuestions([...questions])
                }}>
                <div style={{ textAlign: "center" }}>
                  <img src={getImage(question, option)} style={{ cursor: "pointer" }} alt='cover' />
                  <div style={{
                    fontSize: "12px",
                  }}>
                    {option.optionText}
                  </div>
                </div>

              </div>
            )
          })
        }
      </div>)
  }
  const getOptionCheck = (question, option) => {
    const choice = ',' + (question.answerChoice ?? '') + ','
    return choice.indexOf(',' + option.rowIndex.toString() + ',') >= 0
  }
  const setOptionCheck = (question, option) => {
    const answerChoice = (question.answerChoice ?? '')
    let choice = (answerChoice ? (',' + answerChoice) : '') + ','
    const isCheck = choice.indexOf(',' + option.rowIndex.toString() + ',') >= 0
    if (isCheck) {
      choice = choice.replace(',' + option.rowIndex.toString() + ',', ",")
    } else {
      choice += option.rowIndex.toString() + ","
    }
    if (choice.startsWith(',')) {
      choice = choice.substring(1);
    }
    if (choice.endsWith(',')) {
      choice = choice.substring(0, choice.length - 1);
    }
    question.answerChoice = choice
    setQuestions([...questions])
  }
  const renderCheck = (question, curOptions) => {
    return (<div style={{ width: "100%", padding: "8px" }}>{
      curOptions.map((option, index) => {
        return <div key={index} className='qu-check' style={{ paddingLeft: "8px", paddingBottom: "4px" }}>
          <Checkbox
            label={option.optionText}
            value={getOptionCheck(question, option)}
            sizeClass="small"
            style={{ marginLeft: "0px", }}
            onChange={() => {
              setOptionCheck(question, option)
            }}
            monochrome={false}
          /></div>
      })
    }
    </div>)
  }

  const renderRadio = (question, curOptions) => {
    return (
      <div role="radiogroup" className="qu-radio-group ABB_CommonUX_RadioGroup__root ABB_CommonUX_RadioGroup__light ABB_CommonUX_RadioGroup__smallSize">
        {
          curOptions.map((option, index) => {
            return <div className='qu-radio' key={index}><label
              onClick={(e) => {
                question.answerChoice = option.rowIndex
                setQuestions([...questions])
                e.stopPropagation()
                e.preventDefault()
              }}>
              <input role="radio" aria-checked="false" tabIndex={question.answerChoice == option.rowIndex ? "0" : "-1"} type="radio" name={"radio-" + question.rowIndex}
                value={option.rowIndex + ''} checked={question.answerChoice == option.rowIndex} onChange={(e) => { }} />
              <span></span>
              <span>{option.optionText}</span>
            </label></div>
          })
        }
      </div>
    )
  }

  //渲染选项
  const renderOptions = (question) => {
    const curOptions = getCurrentOptions(question);
    if (curOptions.length > 0) {
      if (question.inputType == "Radio") {
        return renderRadio(question, curOptions)
      } else if (question.inputType == "Check") {
        return renderCheck(question, curOptions)
      } else if (question.inputType == "Score") {
        return renderScore(question, curOptions)
      }
    }
  }
  const getSummaryText = (items) => {
    let text = ""
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      text += ";" + item.answerText
    }
    return text.substring(1)
  }
  //渲染问题内容
  const renderQuestionContent = (item, index) => {
    return (
      <div style={{ padding: "8px" }}>
        <div style={{ display: "flex", fontSize: "14px", fontWeight: 500, padding: "8px 0" }}>
          {renderRequired(item)}{index + 1 + "." + item.questionText}
        </div>
        {(item.questionType == "Choice") &&
          <div className="form-group" >
            {
              item.optionSummary.map((option, index) => {
                return (
                  <div key={index} className='qu-check' style={{ display: "flex", padding: "8px 12px", width: "600px" }}>
                    <div style={{ width: "40%", borderRight: "1px solid #eee" }}>{option.optionText}</div>
                    <div style={{ padding: "0 12px", width: "10%", }}>{option.answerChoiceCount}</div>
                    <div style={{ paddingLeft: "12px", backgroundColor: "#00efef", width: (0.5 * option.answerChoiceCount * 100 / item.totalCount) + "%" }}>{parseInt(option.answerChoiceCount * 100 / item.totalCount) + "%"}</div>
                  </div>
                )
              })
            }
          </div>
        }
        {(item.questionType == "Text" || item.questionType == "Others") &&
          <div className="form-group" >
            {
              getSummaryText(item.textSummary)
            }
          </div>
        }
      </div>
    )
  }
  //渲染所有问题
  const renderQuestions = () => {
    const sortQuestions = getSortQuestions()
    return sortQuestions.map((item, index) => {
      return (
        <div key={index} style={{ borderRadius: "4px", padding: "4px" }}>
          {renderQuestionContent(item, index)}
        </div>
      )
    })


  }
  //渲染问卷调查主数据
  const renderMain = () => {
    return (
      <div style={{ padding: "8px", borderRadius: "4px", }}>
        <div style={{ textAlign: "center", fontSize: "20px", color: "rgb(0, 149, 255)" }}>
          <label>{main.title}</label>
        </div>
        <div style={{ textAlign: "center", fontSize: "14px", color: "#777" }}>
          {main.description}
        </div>
      </div>
    )
  }
  const exportXls = () => { 
    window.open(
      `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downXlsSummary(questionaireId)}`
    );
  }
  //渲染主工具栏
  const renderMainTools = () => {
    return (
      <div style={{ padding: "8px" }}>
        <Row>
          <Col flex="auto">

          </Col>
          <Col flex="200px">
            {(main?.status == "Draft" || main?.status == "Suspend") && <Button text='编辑问卷' style={{ marginRight: "4px" }} type='primary-blue' sizeClass="small" onClick={e => editQuestionaire()} />
            }
            <Button text='导出XLS' type='primary-blue' sizeClass="small" onClick={e => exportXls()} style={{marginRight:"6px"}}/>
            <Button text='返回列表' type='normal' sizeClass="small" onClick={e => backToMain()} />
          </Col>
        </Row>
      </div>
    )
  }
  const renderRequired = (question) => {
    if (question.isRequired) {
      return <span style={{ fontSize: "12px", color: "red" }}>*</span>
    }

  }
  //回到问卷列表页
  const backToMain = () => {
    dispatch(setQuestionaireCache(null))
    history.push('/maindata/questionnaires')
  }
  const editQuestionaire = (item) => {
    history.push('/maindata/questionnaireEdit/' + main.id)
  }
  //初始化问题数据
  useEffect(() => {
    if (questionaireCache) {
      if (!isMounted()) return
      initData(JSON.parse(JSON.stringify(questionaireCache)))
    }
    else if (questionaireId) {
      getData(questionaireId)
    }
  }, [questionaireId, questionaireCache])
  //返回
  return (
    <div id="divQuestionnaires" className={'admin-main-content with-padding'} style={{ backgroundColor: "#f3f3f3" }}>
      <div style={{ backgroundColor: "#fff", padding: "12px" }}>
        {renderMainTools()}
        {renderMain()}
        {renderQuestions()}
      </div>
    </div>
  )
}

