export default {
  //system
  title: "Service Equipment Assistant",
  admin: "Admin",
  report: "Report",
  maindata: "Main data",
  ezreport: "EZ report",
  en_us: "English",
  zh_cn: "Simple Chinese",
  theme: "Theme",
  language: "Language",
  timeZone: "Time Zone",
  about: "About",
  logout: "Exit",
  howAddDeskop: "How to add deskop",
  version: "Version",
  tel: "Tel",
  feedback: "Feedback",
  termOfService: "Terms of Service",
  privacyPolicy: "Privacy Policy",
  newMessage: "New Message",
  refresh: "Refresh",
  noNewMessage: "No new message",
  more: "More",
  allHasRead: "Set All Read",
  timeZoneTo: "Set timezone to ",
  youHaveNotVisitPurview: "You have not purview of page",
  justNow: "just now",
  minuteBefore: " minutes ago",
  hourBefore: " hours ago",
  //menu
  m_overview: "Overview",
  m_admin_iconList: "Icon list",
  m_admin_users: "Users",
  m_admin_role: "Roles",
  m_admin_Organization: "Organizations",
  m_admin_Tenant: "Tenants",
  m_admin_dataDictionary: "Data dictionary",
  m_admin_auditLogs: "Audit logs",
  m_admin_clients: "Clients",

  m_admin_resource: "File admin",
  m_admin_logs: "Logs",
  m_admin_settings: "Settings",
  m_admin_iot: "IOT network Info.",
  m_report_smslogs: "Sms logs",

  m_maindata_customers: "Customers",
  m_maindata_distributors: "Distributors",
  m_maindata_factories: "Factories",
  m_maindata_projects: "Projects",
  m_maindata_deviceTypes: "Device types",
  m_maindata_materialCatalogs: "Material catalogs",
  m_maindata_materials: "Materials",
  m_maindata_applications: "Applications",
  m_maindata_clients: "Clients",
  m_maindata_instruments: "Instruments",
  m_maindata_rights: "Customer Rights Management",
  m_maindata_questionnaires: "Questionnaire Management",
  m_maindata_saprfc: "sap rfc test",

  m_ezreport_checkItems: "Check items",
  m_ezreport_testItems: "Test items",
  m_ezreport_testModals: "Test modals",
  m_ezreport_deviceTypes: "Device types",
  //button
  b_query: "Query",
  b_add: "Add",
  b_del: "Del",
  b_output: "Output",
  b_edit: "Edit",
  b_show: "Show",
  b_confirm: "Confirm",
  b_cancel: "Cancel",
  b_save: "Save",
  b_login: "Login",
  b_reset: "Reset",
  b_enable: "Enable",
  b_disable: "Disable",

  b_preview: "Preview",
  b_back_config: "Back Config",

  b_setSuperUser: "Set Administrator",
  b_setUser: "Set User",
  b_addOrgRoot: "Add root node",
  b_addChild: "Add child",
  b_addUser: "Add users",

  //标题
  t_noData: "No data",
  t_showing: "show",
  t_rowsPerPage: "row per page",
  t_page: "page",
  t_goToPage: "goto",
  t_hasDelete: "Data has been delete",
  t_deleteFail: "Fail to delete",
  t_successToSet: "Success to set",
  t_successToOperate: "Success to operate",
  t_confirmDeleteData: "Are you confirm to delete data？",
  t_titleDeleteData: "Delete data",
  t_keyword: "Keyword",
  t_pleaseInput: "Please input",
  t_pleaseSelect: "Please select",
  t_failToAdd: "Fail to add",
  t_successToAdd: "Success to add",
  t_failToUpdate: "Fail to update",
  t_successToUpdate: "Success to update",
  t_formError: "Form exists error",
  t_errorToReadData: "Error to read data",
  t_operate: "Operate",
  t_search: "Search",
  t_filter: "Filter",
  t_noExistsTenant: "No exists tenant",
  t_401Error: "Auth timeout,it will redirect to login",
  t_403Error: "No purview to visit this resource",
  t_dateBeg: "From",
  t_dateEnd: "To",
  t_all: "All",

  t_titleAddData: "Add data",
  t_titleEditData: "Edit data",
  t_titleShowData: "Show data",
  t_yes: "Yes",
  t_no: "No",

  t_grant_auth: "Grant auth",

  t_userHasDelete: "User has been deleted",
  t_plsSelectUser: "Please select users",
  t_connectString: "Connect String",
  //表单校验规则
  t_required: "Required",
  t_ruleEmail: "Email error",
  t_ruleMobile: "Mobile error",
  t_ruleId: "Mobile No. error",
  t_ruleUrl: "Url error",
  t_ruleIpv4: "Ipv4 error",
  t_ruleDate: "Date error",
  t_ruleDateTime: "DateTime error",
  t_ruleInt: "Integer error",
  t_ruleFloat: "Float error",
  t_rulePostalNo: "Postal No error",
  t_ruleQQ: "QQ error",
  t_ruleWechat: "Wechat error",
  t_ruleCarNo: "Car No error",
  t_ruleLetter: "String only letters",
  t_ruleCN: "String must contain Chinese",
  t_rulePassword: "Password strength verification error",
  //表单标题
  //用户管理
  "user.formTitle": "User Info.",
  "user.hasRoles": "Roles",
  "user.keyword": "Query User",
  "user.userName": "User name",
  "user.name": "Name",
  "user.email": "E-mail",
  "user.phoneNumber": "Phone",
  "user.isActive": "Is activite",
  "user.creationTime": "Create time",
  "user.password": "Password",
  "user.confirmPassword": "Password again",
  "user.twicePwdNotSame": "The passwords entered twice are inconsistent",

  //角色管理
  "role.formTitle": "Role Info.",
  "role.name": "Role name",
  "role.isDefault": "Is default",

  //登录
  "login.formTitle": "Login",
  "login.tenant": "Tenant",
  "login.account": "User name",
  "login.password": "Password",
  "login.rememberMe": "Remember me",
  "login.rule.plsInputUserName": "Please input user name",
  "login.rule.plsInputPwd": "Please input password",
  "login.msg.userOrPwdError": "Error for user name or password",
  "login.msg.plsInputUserName&pwd": "Please input user name and password",
  "login.msg.providerInfo": "Provider Info.",
  "login.msg.privacyPolicy": "Privacy policy",
  "login.msg.switchLanguage": "Click to swith language",

  //组织架构
  "organization.formTitle": "Organization Info.",
  "organization.parentDisplayName": "Parent Organ.",
  "organization.displayName": "Organ. name",

  //租户
  "tenant.formTitle": "Tenant Info.",
  "tenant.name": "Name",
  "tenant.adminEmail": "Admin E-mail",
  "tenant.adminPassword": "Admin password",
  "tenant.connectionString.formTitle": "Connection String Info.",
  "tenant.connectionString": "Connection String",
  //设置
  "settings.formTitle": "Setting Info.",

  //数据字典
  "dataDictionary.formTitle": "Data dictionary",
  "dataDictionary.typeDisplayText": "Dictionary type",
  "dataDictionary.code": "Code",
  "dataDictionary.displayText": "Display Text",
  "dataDictionary.order": "Order",
  "dataDictionary.description": "Description",
  "dataDictionary.isEnabled": "Status",

  //审计日志
  "auditLogs.url": "url",
  "auditLogs.userName": "User name",
  "auditLogs.executionTime": "Execution time",
  "auditLogs.executionDuration": "Execution duration(ms)",
  "auditLogs.exceptions": "Exceptions",
  "auditLogs.hasException": "Has exception",

  //工厂管理
  "factory.name": "Name",
  "factory.address": "Address",
  "factory.formTitle": "Factory",

  //客户管理
  "customer.name": "Customer name",
  "customer.code": "Customer code",
  "customer.category": "Category",
  "customer.manager": "Manager",
  "customer.serviceUnitId": "Service unit id",
  "customer.contact": "Contacts",
  "customer.phone": "Phone",
  "customer.email": "Email",
  "customer.area": "Area",
  "customer.adminEmail": "Admin email",
  "customer.adminPassword": "Admin password",
  "customer.address": "Address",
  "customer.formTitle": "Customer",

  "station.formTitle": "Station",
  "station.stationNo": "Station No.",
  "station.name": "Name",
  "station.type": "Type",
  "station.manager": "Manager",
  "station.address": "Address",
  "station.sld": "Single line",

  //应用管理
  "application.name": "Application name",
  "application.type": "Type",
  "application.code": "Code",
  "application.icon": "Icon",
  "application.externalUrl": "External url",
  "application.description": "Description",

  "application.formTitle": "Applications",

  //项目管理
  "project.name": "Project name",
  "project.customer": "Customer",
  "project.category": "Project category",
  "project.manager": "Project manager",
  "project.startDate": "Start date",
  "project.endDate": "End date",
  "project.no": "Project code",
  "project.description": "Description",
  "project.formTitle": "Project",

  //设备类型管理
  "deviceType.formTitle": "Device type",
  "deviceType.name": "Name",
  "deviceType.parentName": "Parent name",
  "metaData.code": "Param code",
  "metaData.name": "Param name",
  "metaData.dataType": "Data type",
  "metaData.attributes": "Attributes",
  "metaData.description": "Description",
  "metaData.formTitle": "Parameters",

  //设备管理
  "device.formTitle": "Device",

  "device.displayName": "Display name",
  "device.station": "Station",
  "device.type": "Device type",
  "device.parentName": "Parent device",
  "device.sn": "Serial No.",
  "device.orderNumber": "Order No.",
  "device.modal": "Modal",
  "device.factory": "Factory",
  "device.productDate": "Manufacture date",
  "device.pannelNo": "Return circuit No.",
  "device.pannelName": "Return circuit name",
  "device.busNo": "Bus No.",
  "device.description": "Device description",
  "deviceData.formTitle": "Device param",

  //物料目录管理
  "materialCatalog.formTitle": "Material path",
  "materialCatalog.name": "Path name",
  "materialCatalog.parentName": "Parent path name",

  //物料管理
  "material.name": "Material name",
  "material.code": "Material code",
  "material.modal": "Material modal",
  "material.provider": "Provider",
  "material.purchaseDate": "Purchase date",
  "material.warranty": "Term of validity",
  "material.lastCheck": "Last deteciton date",
  "material.status": "Status",
  "material.manager": "Device manager",
  "material.description": "Description",
  "material.formTitle": "Material",
  "materialData.formTitle": "Material params",
  //仪器管理
  "instrument.name": "Name",
  "instrument.assetCode": "Asset code",
  "instrument.modal": "Modal",
  "instrument.factory": "Factory",
  "instrument.purchaseDate": "Pruchase date",
  "instrument.validity": "Validity",
  "instrument.lastCheck": "Last deteciton date",
  "instrument.status": "Status",
  "instrument.manager": "Device manager",
  "instrument.description": "Description",
  "instrument.formTitle": "Devices",
  //维保区域
  "maintenanceCatalog.title": "Maintenance catalog",
  "maintenanceCatalog.childrenTitle": "Check(Test) item name",
  
  //经销商管理
  "distributor.code": "Code",
  "distributor.name": "Name",
  "distributor.level": "Level",
  "distributor.tag": "Tag",
  "distributor.startDate": "StartDate",
  "distributor.finishedDate": "FinishedDate",
  "distributor.manager": "Manager",
  "distributor.contact": "Contact",
  "distributor.phone": "Phone",
  "distributor.email": "E-mail",
  "distributor.area": "Area",
  "distributor.address": "Address",
  "distributor.status": "Status",
  "distributor.formTitle": "Distributor",
  //经销商工程师管理
  "distributorEngineer.menu": "Engineer Info.",
  "distributorEngineer.name": "Name",
  "distributorEngineer.avatar": "Avatar",
  "distributorEngineer.title": "Title",
  "distributorEngineer.desc": "Description",
  "distributorEngineer.distributorId": "Distributor",
  "distributorEngineer.formTitle": "Distributor Engineer",

  //Questionnaire Management
  "quQuestionnaire.title": "Questionnaire Title",
  "quQuestionnaire.category": "Questionnaire Category",
  "quQuestionnaire.description": "Questionnaire Description",
  "quQuestionnaire.startTime": "StartTime",
  "quQuestionnaire.finishedTime": "Finished Time",
  "quQuestionnaire.status": "Status",
  "quQuestionnaire.email": "Feedback E-mail",
  "quQuestionnaire.maxScore": "Maximum Score",
  "quQuestionnaire.issuer": "Issuer",
  "quQuestionnaire.issueTime": "Issue Time",
  "quQuestionnaire.Published": "Publish",
  "quQuestionnaire.Suspend": "Suspend",
  "quQuestionnaire.Resume": "Resume",
  "quQuestionnaire.Finished": "Finish",
  "quQuestionnaire.Invalid": "Invalidate",
  "quQuestionnaire.Preview": "Preview questionaire",
  "quQuestionnaire.Edit": "Edit questionaire",
  "quQuestionnaire.summary": "Summary",
  "quQuestionnaire.copy": "Copy",
  "quQuestionnaire.group": "Group",
  "quQuestionnaire.sumbitRemindMessage": "Remind message",
  "quQuestionnaire.noNeedLogin": "No need login",
  //quQuestion Questions
  "quQuestion.rowIndex": "Row Index",
  "quQuestion.questionnaireId": "Questionnaire",
  "quQuestion.questionText": "Question Title",
  "quQuestion.fillingPrompt": "Filling Prompt",
  "quQuestion.questionType": "Question Type",
  "quQuestion.isMultiple": "Multiple Choice",
  "quQuestion.inputType": "Input Type",
  "quQuestion.decimalDigits": "Decimal Digits",
  "quQuestion.isNeedFeedback": "Need Feedback",
  "quQuestion.feedbackPlaceHolder": "Feedback Placeholder",
  "quQuestion.isRequired": "Required",
  "quQuestion.minLength": "Minimum Length",
  "quQuestion.maxLength": "Maximum Length",
  "quQuestion.validateType": "Validation Type",
  "quQuestion.maxScore": "Maximum Score",
  "quQuestion.relevantInfo": "Relevant Information",
  "quQuestion.formTitle": "Questionnaire Information",
  //quOption Options
  "quOption.rowIndex": "Row Index",
  "quOption.questionnaireId": "Questionnaire",
  "quOption.questionId": "Question",
  "quOption.optionText": "Option Text",
  "quOption.optionPicture": "Option Picture Name",
  "quOption.optionScore": "Option Score",

}
