import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { useSelector } from 'react-redux'
import { createCheckPoint, updateCheckPoint } from 'common/apis/ezreport'
import { useEffect } from 'react'

export const CheckPointFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //初始化数据
  const initData = {
    name: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: '检查点名称',
      fieldName: 'name',
      maxLength: 128,
    },
    // {
    //   type: 'dropdown',
    //   dataType: 'text',
    //   label: '输入方式',
    //   fieldName: 'inputType',
    //   options: [
    //     { label: '单选', value: 'radio' },
    //     { label: '多选', value: 'check' },
    //     { label: '文本输入', value: 'input' },
    //     { label: '地理位置', value: 'location' },
    //   ],
    // },

    {
      type: 'text',
      dataType: 'number',
      label: '失效倍数',
      fieldName: 'failureMultiple',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '安全基本权重',
      suffix: '%',
      fieldName: 'weightSecurity',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '结构基本权重',
      suffix: '%',
      fieldName: 'weightStructure',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '保护基本权重',
      suffix: '%',
      fieldName: 'weightProtection',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '发热基本权重',
      suffix: '%',
      fieldName: 'weightExothermic',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '机电基本权重',
      suffix: '%',
      fieldName: 'weightElectromechanical',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '绝缘基本权重',
      suffix: '%',
      fieldName: 'weightInsulation',
    },
  ]
  useEffect(() => {}, [event])

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
    failureMultiple: [CommonRule.Require],
  }

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      failureMultiple: inputdata.failureMultiple,
      riskLevel: inputdata.riskLevel,
      inputType: 'radio',
      weights: {
        security: inputdata.weightSecurity ? inputdata.weightSecurity / 100.0 : undefined,
        structure: inputdata.weightStructure ? inputdata.weightStructure / 100.0 : undefined,
        protection: inputdata.weightProtection ? inputdata.weightProtection / 100.0 : undefined,
        exothermic: inputdata.weightExothermic ? inputdata.weightExothermic / 100.0 : undefined,
        electromechanical: inputdata.weightElectromechanical ? inputdata.weightElectromechanical / 100.0 : undefined,
        insulation: inputdata.weightInsulation ? inputdata.weightInsulation / 100.0 : undefined,
      },
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    const result = {
      itemId: inputdata.checkItemId,
      name: inputdata.name,
      failureMultiple: inputdata.failureMultiple,
      riskLevel: inputdata.riskLevel,
      inputType: 'radio',
      weights: {
        security: inputdata.weightSecurity ? inputdata.weightSecurity / 100.0 : undefined,
        structure: inputdata.weightStructure ? inputdata.weightStructure / 100.0 : undefined,
        protection: inputdata.weightProtection ? inputdata.weightProtection / 100.0 : undefined,
        exothermic: inputdata.weightExothermic ? inputdata.weightExothermic / 100.0 : undefined,
        electromechanical: inputdata.weightElectromechanical ? inputdata.weightElectromechanical / 100.0 : undefined,
        insulation: inputdata.weightInsulation ? inputdata.weightInsulation / 100.0 : undefined,
      },
    }
    console.log(result)
    return result
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='检查点信息'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createCheckPoint}
      updateData={updateCheckPoint}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={{
        ...formData,
        weightSecurity: formData?.weights?.security ? formData?.weights?.security * 100 : undefined,
        weightStructure: formData?.weights?.structure ? formData?.weights?.structure * 100 : undefined,
        weightProtection: formData?.weights?.protection ? formData?.weights?.protection * 100 : undefined,
        weightExothermic: formData?.weights?.exothermic ? formData?.weights?.exothermic * 100 : undefined,
        weightElectromechanical: formData?.weights?.electromechanical ? formData?.weights?.electromechanical * 100 : undefined,
        weightInsulation: formData?.weights?.insulation ? formData?.weights?.insulation * 100 : undefined,
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
