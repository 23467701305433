import React, { useState, useEffect, useRef } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { CommonConfirm, CommonList } from 'components/LoongInCommon'
import { Details } from './Details'
import { getAllSettings } from 'common/endpoints'
import axios from 'axios'
import { LeftPanel } from './LeftPanel'
import { Col, Row } from 'components/Grid'
import { LifeCycleModal } from './LifeCycleModal'
import { SearchModal } from './SearchModal'
import { TestItemFormEdit } from './TestItemFormEdit'
import { MaintenanceCatalogFormEdit } from './MaintenanceCatalogFormEdit'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

export default () => {
  // const [isOperating, setIsOperating] = useState(false)
  const [MaintenanceCatalogFormData, setMaintenanceCatalogFormData] = useState(null)
  const [TestItemFormData, setTestItemFormData] = useState(null)
  const [TestPointOptionFormData, setTestPointOptionFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activeItemId, setActiveItemId] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState('')
  const [deleteItem, setDeleteItem] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formMaintenanceCatalogRef = useRef(null)
  const formTestItemRef = useRef(null)
  const devTypRef = useRef(null)

  const lifeCycleRef = useRef(null)
  const [searchData, setSearchData] = useState(null)

  const setShowMaintenanceCatalogPanel = (visible) => {
    formMaintenanceCatalogRef.current.setShowSidePanel(visible)
  }
  const setShowTestItemPanel = (visible) => {
    formTestItemRef.current.setShowSidePanel(visible)
  }

  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex('')
      setMaintenanceCatalogFormData(null)
      setTestItemFormData(null)
      devTypRef.current.getTestItemTree()
    } else {
      devTypRef.current.getTestItemTree()
    }
  }
  return (
    <div>
      <div>
        <Row>
          <Col flex={400}>
            <LeftPanel
              ref={devTypRef}
              onActive={(item) => {
                if (item.type === 'testItem') {
                  setActiveItem(item)
                  setSearchData({ testItemId: item.key })
                } else {
                  setActiveItem(item)
                  setSearchData({ testItemId: '00000000-0000-0000-0000-000000000000' })
                }
                // refIndex.current.query()
              }}
              onAdd={(item) => {
                if (!item) {
                  setMaintenanceCatalogFormData({
                    name: '',
                  })
                  setShowMaintenanceCatalogPanel(true)
                } else if (item.type === 'catalog') {
                  setTestItemFormData({
                    name: '',
                    checkType: '',
                    checkTools: '',
                    description: '',
                    catalogId: item.key,
                  })
                  setActivDataIndex('')
                  setShowTestItemPanel(true)
                }
              }}
              onEdit={(item) => {
                if (item.type === 'catalog') {
                  setMaintenanceCatalogFormData({
                    id: item.key,
                    name: item.title,
                    deviceType: item.deviceType,
                  })
                  setActivDataIndex(item.key)
                  setShowMaintenanceCatalogPanel(true)
                } else {
                  setTestItemFormData({
                    id: item.key,
                    name: item.title,
                    testType: item.testType,
                    systemTest: item.systemTest,
                    weights: item.weights,
                    values:item.values,
                    failureMultiple:item.failureMultiple
                  })
                  setActivDataIndex(item.key)
                  setShowTestItemPanel(true)
                }
              }}
              onConfigLifeCycle={(item) => {
                lifeCycleRef.current.showDialog({
                  id: item.key,
                  name: item.title,
                  testType: item.testType,
                  systemTest: item.systemTest,
                  lifeCycleConfig: item.lifeCycleConfig,
                })
              }}
              onDel={(item) => {
                setDeleteItem(item)
              }}
            ></LeftPanel>
          </Col>
          <Col flex={'auto'}>
            <Details item={activeItem} />
          </Col>
        </Row>
      </div>
      <MaintenanceCatalogFormEdit
        ref={formMaintenanceCatalogRef}
        formData={MaintenanceCatalogFormData}
        onCancel={() => {
          setActivDataIndex('')
          setMaintenanceCatalogFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
      />
      <TestItemFormEdit
        ref={formTestItemRef}
        formData={TestItemFormData}
        onCancel={() => {
          setActivDataIndex('')
          setTestItemFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
      />

      <LifeCycleModal ref={lifeCycleRef} onRefresh={() => setTimeout(() => onSubmit(), 500)}></LifeCycleModal>
    </div>
  )
}
