import React, { useState, useRef, forwardRef } from 'react'
import { CommonIndex, CommonTable } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { getClientWidth } from 'slices/appSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { t } from 'i18next'
import { deleteWechatMenu, getWechatMenus, pullWechatMenus } from 'common/apis/wechat'
import { useMountedState } from 'common/useMountedState'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { Button } from '@abb/abb-common-ux-react'

export const MainIndex = forwardRef(({ groupId, groupName }, ref) => {
  const refIndex = useRef(null)
  const formOptionRef = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const [parent, setParent] = useState(null)
  const clientWidth = useSelector(getClientWidth)
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [isLoading, setIsLoading] = useState(false)

  //设置查询字段dataDictionaryId
  useEffect(() => {
    if (groupId)
      setSearchData({
        extraProperties: { groupId: groupId },
      })
    else if (refIndex && refIndex.current) refIndex.current.setList([])
  }, [groupId])
  //点击添加按钮时将dataDictionaryId注入到表单中
  const onAddBefore = () => {
    const item = {
      groupId,
      groupName,
      ...parent,
    }
    setParent(null)
    return item
  }
  //列定义
  const columns = [
    {
      title: '菜单项',
      key: 'name',
      isTree: true,
    },
  ]

  useEffect(() => {
    if (parent) {
      refIndex.current.add()
    }
  }, [parent])

  //其他行菜单
  const otherMenu = (item, index) => {
    return !item.parentId && item.type==='sub'
      ? [
          {
            onClick: () => {
              setParent({
                parentName: item.name,
                parentId: item.id,
              })
            },
            title: '添加子菜单',
          },
        ]
      : []
  }

  const pullTags = () => {
    setIsLoading(true)
    axios
      .post(pullWechatMenus())
      .then((e) => {
        if (!isMounted()) {
          return
        }
        dispatch(showNotification(NotificationType.Success, '同步微信菜单成功'))
        refIndex.current.query()
      })
      .catch((e) => {
        dispatch(
          showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : '同步微信菜单失败，请稍后重试或联系管理员！')
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const toolChildren = <Button text='从微信同步' isLoading={isLoading} type='normal' sizeClass='small' icon={'abb/download'} onClick={pullTags} />

  //返回页面
  return (
    <>
      <CommonIndex
        className='admin-main-content with-padding no-margin'
        ref={refIndex}
        otherMenu={otherMenu}
        toolChildren={toolChildren}
        columns={columns}
        isLoading={isLoading}
        toolWidth={280}
        searchData={searchData}
        getList={getWechatMenus}
        deleteData={deleteWechatMenu}
        FormEdit={FormEdit}
        tableWidth={clientWidth - 425}
        canAdd={!!groupId}
        canEdit
        canDelete
        enableSorting={false}
        onAddBefore={onAddBefore}
      />
    </>
  )
})
