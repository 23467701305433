import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonTree } from 'components/LoongInCommon'
// import { Checkbox } from "@abb/abb-common-ux-react"
// import { CommonRule } from "components/LoongInCommon"
// import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from 'axios'
// import { useDispatch, useSelector } from "react-redux"
import { t } from 'i18next'
import { getFormulasList, updateFormula } from 'common/apis/ezreport'
import { Col, Row } from 'components/Grid'
import { getClientWidth, getClientHeight } from 'slices/appSlice'
import { useSelector } from 'react-redux'
import { Button, Input } from '@abb/abb-common-ux-react'
// import {  getClientHeight,} from "slices/appSlice"

export default () => {
  const treeRef = useRef(null)
  //组织架构树的数据
  const [tree, setTree] = useState(null)
  const clientWidth = useSelector(getClientWidth)
  const clientHeight = useSelector(getClientHeight)
  const [currentFormula, setCurrentFormula] = useState(null)
  //调用接口获取权限树
  const getCheckItemTree = async (roleName) => {
    try {
      const response = await axios.post(getFormulasList())
      if (response.status === 200) {
        if (response.data) {
          console.log(response.data)
          setTree(response.data)
          // expandAll()
        }
      } else {
        console.log('error to get tree grant')
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    getCheckItemTree()
  }, [])

  const getCommonTableWidth = () => {
    let width = clientWidth - 100 - 400
    return width < 300 ? 300 : width
  }
  const commonTableWidth = getCommonTableWidth()

  const save = async () => {
    if (currentFormula) {
      await axios.post(updateFormula(), { name: currentFormula.key, expression: currentFormula.expression })
      getCheckItemTree()

    }
  }

  //返回页面
  return (
    <div style={{ backgroundColor: '#fff', height: '100%' }}>
      <Row>
        <Col flex={400}>
          <CommonTree
            ref={treeRef}
            items={tree}
            onActive={(item) => {
              if (!item.children) setCurrentFormula(item)
              else setCurrentFormula(null)
            }}
          />
        </Col>
        <Col flex={'auto'}>
          <div style={{ width: commonTableWidth, height: clientHeight - 130 }}>
            <Input className='full-height'
              onValueChange={(v) => setCurrentFormula({ ...currentFormula, expression: v })}
              disabled={!currentFormula}
              dataType='textarea'
              value={currentFormula?.expression}
            />
          </div>
          <Button text='保存' disabled={!currentFormula} onClick={save} />
        </Col>
      </Row>
    </div>
  )
}
