import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { createDataDictionaryDetail, updateDataDictionaryDetail } from 'common/endpoints'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createImportanceWeights, createParameterItems, updateImportanceWeights, updateParameterItems } from 'common/apis/maindata'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const rules = {
    key: [CommonRule.Require],
    title: [CommonRule.Require],
    weight: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    catalogName: '',
    key: '',
    title: '',
    weight: '',
  }
  //表单项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('dataDictionary.typeDisplayText'),
      fieldName: 'catalogName',
      visible: !formData || !!formData.catalogName,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '编码',
      fieldName: 'key',
      maxLength: 256,
      disabled: formData && formData.id,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '标题',
      fieldName: 'title',
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'number',
      label: '权重值',
      fieldName: 'weight',    
    }
  ]
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      catalogId: inputdata.catalog,
      title: inputdata.title,
      key: inputdata.key,
      weight: inputdata.weight,
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      catalogId: inputdata.catalog,
      title: inputdata.title,
      key: inputdata.key,
      weight: inputdata.weight,
    }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('dataDictionary.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createImportanceWeights}
      updateData={updateImportanceWeights}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
