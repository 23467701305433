import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { SlidingCard } from 'components/SlidingCard'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import { CommonIndex } from 'components/LoongInCommon'
import { getTeamMembers, removeTeamMember } from 'common/apis/maindata'
import { getProject } from 'slices/ezProjectSlice'
import { useImperativeHandle } from 'react'
import { getUsers } from 'slices/userSlice'
import axios from 'axios'
import { useMountedState } from 'common/useMountedState'

export const MemberList = forwardRef(({ readOnly = false, onRefresh, onEdit }, ref) => {
  const refTeamIndex = useRef(null)
  const refTestIndex = useRef(null)
  const [teamSearchData, setTeamSearchData] = useState(null)
  const [testSearchData, setTestSearchData] = useState(null)
  const project = useSelector(getProject)
  const users = useSelector(getUsers)
  const [safety, setSafety] = useState(null)
  const isMounted = useMountedState()
  const columns = [
    {
      title: '姓名',
      key: 'name',
      render: (item, index) => {
        const user = users.find((d) => d.id == item.memberId)
        return user?.name
      },
    },
    {
      title: '联系方式',
      key: 'phone',
      render: (item, index) => {
        const user = users.find((d) => d.id == item.memberId)
        return user?.phoneNumber
      },
    },
    {
      title: '邮件',
      key: 'email',
      render: (item, index) => {
        const user = users.find((d) => d.id == item.memberId)
        return user?.email
      },
    },
  ]
  useEffect(() => {
    if (project) {
      fetchSafety()
      setTeamSearchData({ projectId: project.id, role: 'action' })
      setTestSearchData({ projectId: project.id, role: 'test' })
    }
  }, [project])

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const fetchSafety = () => {
    axios
      .post(getTeamMembers(), {
        projectId: project.id,
        role: 'safety',
        filter: '',
        sorting: '',
        pageIndex: 1,
        pageSize: 5,
      })
      .then((response) => {
        if (!isMounted()) {
          return
        }
        var data = response.data.items[0]
        setSafety(data)
      })
  }
  const onWindowResize = () => {}
  const getTeamDeleteInput = (item) => {
    return { id: item.id, projectId: project.id, role: 'action' }
  }
  const getTestDeleteInput = (item) => {
    return { id: item.id, projectId: project.id, role: 'test' }
  }
  const teamActionRender = (item, index) => {
    return (
      <Button
        key={nanoid()}
        sizeClass='small'
        icon='abb/trash'
        type='discreet-black'
        shape='pill'
        onClick={() => {
          refTeamIndex.current.setDeleteItem(item)
        }}
      />
    )
  }
  const testActionRender = (item, index) => {
    return (
      <Button
        key={nanoid()}
        sizeClass='small'
        icon='abb/trash'
        type='discreet-black'
        shape='pill'
        onClick={() => {
          refTestIndex.current.setDeleteItem(item)
        }}
      />
    )
  }
  useImperativeHandle(ref, () => ({
    query, //刷新数据
  }))
  const query = () => {
    refTeamIndex.current.query()
    refTestIndex.current.query()
    fetchSafety()
  }
  return (
    <SlidingCard>
      <Table title='维保团队' sizeClass='medium' borderType='discreet'>
        <tbody>
          <tr>
            <td title='项目经理'>项目经理</td>
            <td>
              <span>{users.find((d) => d.id == project?.manager)?.name}</span>
            </td>
            <td width={80}></td>
          </tr>
          <tr>
            <td title='安全监督员'>安全监督员</td>
            <td>
              <span>{users.find((d) => d.id == safety?.memberId)?.name}</span>
            </td>
            <td width={80}>
              {!readOnly && (
                <Button
                  key={nanoid()}
                  sizeClass='small'
                  shape='pill'
                  type='discreet-black'
                  icon='abb/edit'
                  onClick={() => onEdit('safety', false, true, { id: safety?.id, projectId: project?.id, role: 'safety' })}
                  style={{ opacity: 1, marginRight: 8 }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <CommonIndex
        otherButton={readOnly?null:[
          <Button
            key={nanoid()}
            sizeClass='small'
            shape='pill'
            type='discreet-black'
            icon='abb/plus'
            onClick={() => onEdit('action', true, false)}
            style={{ opacity: 1, marginRight: 8 }}
          />,
        ]}
        title='执行团队成员'
        key={nanoid()}
        className={'admin-main-content3 with-padding'}
        ref={refTeamIndex}
        columns={columns}
        getList={getTeamMembers}
        searchData={teamSearchData}
        deleteData={removeTeamMember}
        getDeleteInput={getTeamDeleteInput}
        canDelete={!readOnly}
        pageSize={4}
        canSearch={false}
        refreshAfterDelete={true}
        pageInQuery={false}
        tableWidth={'100%'}
        tableHeight={'100%'}
        enableSorting={false}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        actionRender={readOnly?null:teamActionRender}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
      <CommonIndex
        otherButton={readOnly?null:[
          <Button
            key={nanoid()}
            sizeClass='small'
            shape='pill'
            type='discreet-black'
            icon='abb/plus'
            onClick={() => onEdit('test', true, false)}
            style={{ opacity: 1, marginRight: 8 }}
          />,
        ]}
        title='试验人员'
        key={nanoid()}
        className={'admin-main-content3 with-padding'}
        ref={refTestIndex}
        columns={columns}
        getList={getTeamMembers}
        searchData={testSearchData}
        deleteData={removeTeamMember}
        getDeleteInput={getTestDeleteInput}
        canDelete={!readOnly}
        pageSize={4}
        pageInQuery={false}
        canSearch={false}
        refreshAfterDelete={true}
        tableWidth={'100%'}
        tableHeight={'100%'}
        enableSorting={false}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        actionRender={readOnly?null: testActionRender}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
    </SlidingCard>
  )
})
