import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Checkbox } from "@abb/abb-common-ux-react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getCustomerNames } from "slices/maindataSlice"
import { createCheckPointOption, updateCheckPointOption } from "common/apis/ezreport"
import { useDataDictionary } from "hooks/useDataDictionary"

export const CheckPointOptionFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  // const [formItem, setFormItem] = useState([]);
  const dt = useDataDictionary('EvaluationRiskLevel')

  //初始化数据
  const initData = {
    name: "",
    code: "",
    checkPointId: "",
    checkPointName: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: "检查点名称",
      fieldName: "checkPointName",
      visible: !formData || !!formData.checkPointName,
      disabled: true,
    },
    {
      type: "dropdown",
      label: "类型",
      fieldName: "code",
      options: [
        { label: "正常", value: "1" },
        { label: "非正常", value: "2" },
        { label: "不适用", value: "3" },
        { label: "未采集", value: "4" },
      ],
      maxLength: 20,
    },
    {
      type: 'dropdown',
      label: '风险级别',
      fieldName: 'riskLevel',
      options: dt
        ? dt.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
    {
      type: "text",
      dataType: "textarea",
      label: "说明",
      fieldName: "description",
      maxLength: 200,
    },
    {
      type: "text",
      dataType: "text",
      label: "内容",
      fieldName: "name",
      maxLength: 200,
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: '选项分值表达式',
      fieldName: 'valueExpression',
      maxLength: 200,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      code: [CommonRule.Require],
      valueExpression: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      code: inputdata.code,
      riskLevel: inputdata.riskLevel,
      description: inputdata.description,
      title: inputdata.name,
      valueExpression:inputdata.valueExpression
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      parentId:inputdata.parentId,
      checkPointId: inputdata.checkPointId,
      code: inputdata.code,
      riskLevel: inputdata.riskLevel,
      description: inputdata.description,
      title: inputdata.name,
      valueExpression:inputdata.valueExpression
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title="检查点选项"
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createCheckPointOption}
      updateData={updateCheckPointOption}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
