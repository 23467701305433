import React from "react"
// import React, { useEffect } from "react"
import { Switch, Route, useRouteMatch, useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getEnv } from "slices/envSlice"
import { getUserProfile } from "slices/userProfileSlice"
import { AppMainContent } from "@abb/abb-common-ux-react"
import { SideNavMenu, SideNavMenuItem } from "components/SideNavMenu"
import decode from "jwt-decode"
import { ErrorBoundary } from "common/ErrorBoundary"
//各模块
import Dashboard from "./Dashboard"
import Projects from "./Projects"
import Project from "./Projects/Project"
import { useTranslation } from "react-i18next"
import { wfreport } from "../menu.js"
import translate from "../../i18n/translate"
import CheckItems from "./CheckItems"
import TestModals from "./TestModals"
import Stations from "./Projects/Stations"
import DeviceTypes from "./DeviceTypes"
import Reports from "./Reports"
import StationParameter from "./StationParameter"
import Formulas from "./Formulas"
import TestItems from "./TestItems"

function EzReport() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const navItems = translate(wfreport, t)

  const env = useSelector(getEnv)
  const userProfile = useSelector(getUserProfile)
  const oauth = useSelector((state) => state.oauth)
  const user = oauth?.accessToken ? decode(oauth?.accessToken) : null

  if (userProfile) {
    return (
      <>
        <SideNavMenu>
          {navItems.map((item, index) => (
            <SideNavMenuItem
              key={index}
              itemKey={index}
              permission={item.permission}
              icon={item.icon}
              title={item.title}
              onClick={() => history.push(item.path)}
              active={location.pathname === item.path || (item.path !== "/wfreport" && location.pathname.indexOf(item.path) === 0)}
            />
          ))}
        </SideNavMenu>
        <ErrorBoundary>
          <AppMainContent style={{ boxSizing: "border-box" }}>
            <Switch>
              <Route exact path={path} component={Dashboard} />
              <Route path={`${path}/projects/station/:id`} component={Stations} />
              <Route path={`${path}/projects/:id`} component={Project} />
              <Route path={`${path}/projects`} component={Projects} />
              <Route path={`${path}/reports`} component={Reports} />
              <Route path={`${path}/checkItems`} component={CheckItems} />
              <Route path={`${path}/formulas`} component={Formulas} />
              <Route path={`${path}/stationParameters`} component={StationParameter} />
              <Route path={`${path}/testitems`} component={TestItems}/>
              <Route path={`${path}/testModals`} component={TestModals} />
              <Route path={`${path}/deviceTypes`} component={DeviceTypes} />
            </Switch>
          </AppMainContent>
        </ErrorBoundary>
      </>
    )
  } else if (!userProfile) {
    return null
  } else {
    return (
      <div className="grid root-grid">
        <p>您没有访问此页面的权限</p>
      </div>
    )
  }
}

export default EzReport
