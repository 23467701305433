//QuQuestionnaires
export const getQuestionnairesPage = () => `/api/MainData/QuQuestionnaires/page`
export const getQuestionnaire = (id) => `/api/MainData/QuQuestionnaires/get-complex?id=${id}`
export const saveQuestionnaires = () => `/api/MainData/QuQuestionnaires/save-complex`
export const deleteQuestionnaires = () => `/api/MainData/QuQuestionnaires/delete`
export const chagngeStatus = () => `/api/MainData/QuQuestionnaires/change-status`
export const restorePublished = () => `/api/MainData/QuQuestionnaires/restore-published`
export const copyQuestionaireUrl = (id) => `/api/MainData/QuQuestionnaires/copy?id=${id}`

//QuAnswer
export const getQuAnswerPage = () => `/api/MainData/QuAnswer/page`
export const getQuAnswer = (id) => `/api/MainData/QuAnswer/get-complex?id=${id}`
export const saveQuAnswer = () => `/api/MainData/QuAnswer/save-complex`
export const deleteQuAnswer = () => `/api/MainData/QuAnswer/delete`
export const getQuAnswerSummary = (id) => `/api/MainData/QuAnswer/get-summary?questionnaireId=${id}`
export const downXlsSummary = (id) => `api/MainData/QuAnswer/down-xls-summary?questionnaireId=${id}`

