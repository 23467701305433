import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { useSelector } from 'react-redux'
import { getClientHeight } from 'slices/appSlice'
import { createDistributorEngineer, updateDistributorEngineer } from 'common/apis/maindata'
import { ImageSelector } from 'components/ImageSelector'
import { t } from 'i18next'
// import { getUsers } from 'slices/userSlice'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event, extraData}, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [rules, setRules] = useState({})
  const [currImg, setCurrImg] = useState(formData?.avatar)
  // const users = useSelector(getUsers)

  //初始化数据
  const initData = {
    name: '',
    title: '',
    avatar: '',
    desc: '',
    distributorId: null,
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('distributorEngineer.name'),
      fieldName: 'name',
      maxLength: 50,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('distributorEngineer.title'),
      fieldName: 'title',
      maxLength: 100,
    },
    {
      type: 'div',
      label: t('distributorEngineer.avatar'),
      fieldName: 'avatar',
      maxLength: 100,
      render: (item, callback) => {
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/RemoteSupport/Experts/Avatars'}
              onValueChanged={(d) => {
                setCurrImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: t('distributorEngineer.desc'),
      fieldName: 'desc',
      maxLength: 1024,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      // title: [CommonRule.Require],
      // desc: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      title: inputdata.title,
      avatar: currImg ?? inputdata.avatar,
      desc: inputdata.desc,
      distributorId: inputdata.distributorId
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    console.log(extraData?.distributorId)
    return {
      name: inputdata.name,
      title: inputdata.title,
      avatar: currImg,
      desc: inputdata.desc,
      distributorId: extraData?.distributorId
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('distributorEngineer.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createDistributorEngineer}
      updateData={updateDistributorEngineer}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      // onChangeBefore={(item, _, name) => {
      //   if (name === 'userId') {
      //     item.name = users.find((u) => u.id == item.userId)?.name
      //   }
      // }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
