import React, { useRef, useImperativeHandle, forwardRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createWechatMenu, getWechatKeywords, updateWechatMenu } from 'common/apis/wechat'
import { useMountedState } from 'common/useMountedState'
import axios from 'axios'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const isMounted = useMountedState()
  const [innerData, setInnerData] = useState({})
  const [rules, setRules] = useState({})
  const [formItem, setFormItem] = useState([])
  const [keywords, setKeywords] = useState([])
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const changeRules = () => {
    const r = {
      name: [CommonRule.Require],
      type: [CommonRule.Require],
    }
    if (
      innerData?.type === 'click' ||
      innerData?.type === 'scancode_push' ||
      innerData?.type === 'scancode_waitmsg' ||
      innerData?.type === 'pic_sysphoto' ||
      innerData?.type === 'pic_photo_or_album' ||
      innerData?.type === 'pic_weixin' ||
      innerData?.type === 'location_select'
    ) {
      r['key'] = [CommonRule.Require]
    }
    if (innerData?.type === 'view' || innerData?.type === 'miniprogram') {
      r['url'] = [CommonRule.Require]
    }
    if (innerData?.type === 'miniprogram') {
      r['appId'] = [CommonRule.Require]
      r['pagePath'] = [CommonRule.Require]
    }
    setRules(r)
  }
  //初始化数据
  const initData = {
    type: '',
    name: '',
    key: '',
    url: '',
    appId: '',
    pagePath: '',
    parentId: formData?.parentId,
    groupId: formData?.groupId,
  }

  const menuitems = [
    { label: '关键字', value: 'click' },
    { label: '打开网页', value: 'view' },
    { label: '扫码推事件', value: 'scancode_push' },
    { label: '扫码带提示', value: 'scancode_waitmsg' },
    { label: '系统拍照发图', value: 'pic_sysphoto' },
    { label: '拍照或者相册发图', value: 'pic_photo_or_album' },
    { label: '微信相册发图', value: 'pic_weixin' },
    { label: '发送位置', value: 'location_select' },
    { label: '小程序', value: 'miniprogram' },
  ]
  //表单项
  const changeFormItem = () => {
    const items = [
      {
        type: 'text',
        dataType: 'text',
        label: '菜单分组',
        fieldName: 'groupName',
        visible: !formData || !!formData.groupName,
        disabled: true,
      },
      {
        type: 'text',
        dataType: 'text',
        label: '上级菜单',
        fieldName: 'parentName',
        visible: !formData || !!formData.parentName,
        disabled: true,
      },
      {
        type: 'text',
        dataType: 'text',
        label: '名称',
        fieldName: 'name',
        maxLength: 256,
      },
      {
        type: 'dropdown',
        dataType: 'text',
        label: '菜单内容',
        fieldName: 'type',
        options: formData?.parentId ? menuitems : [{ label: '子菜单', value: 'sub' }, ...menuitems],
      },
    ]
    if (
      innerData?.type === 'click' ||
      innerData?.type === 'scancode_push' ||
      innerData?.type === 'scancode_waitmsg' ||
      innerData?.type === 'pic_sysphoto' ||
      innerData?.type === 'pic_photo_or_album' ||
      innerData?.type === 'pic_weixin' ||
      innerData?.type === 'location_select'
    ) {
      items.push({
        type: 'dropdown',
        searchable: true,
        label: '关键字',
        fieldName: 'key',
        options: keywords,
      })
    }
    if (innerData?.type === 'view' || innerData?.type === 'miniprogram') {
      items.push({
        type: 'text',
        dataType: 'text',
        label: '网页地址',
        fieldName: 'url',
        maxLength: 256,
      })
    }
    if (innerData?.type === 'miniprogram') {
      items.push({
        type: 'text',
        dataType: 'text',
        label: '小程序AppId',
        fieldName: 'appId',
        maxLength: 200,
      })
      items.push({
        type: 'text',
        dataType: 'text',
        label: '页面路径',
        fieldName: 'pagePath',
        maxLength: 256,
      })
    }
    setFormItem(items)
  }

  const fetchKeywords = () => {
    axios
      .post(getWechatKeywords(), { pageSize: 10000 })
      .then((e) => {
        if (!isMounted()) {
          return
        }
        if (e.data) {
          setKeywords(
            e?.data?.items?.map((i) => {
              return { value: i.keyword, label: i.name }
            })
          )
        }
      })
      .then((e) => {
        if (!isMounted()) {
          return
        }
      })
  }
  useEffect(() => {
    setInnerData(formData)
  }, [formData, keywords])

  useEffect(() => {
    changeFormItem()
    changeRules()
  }, [innerData])
  useEffect(() => {
    fetchKeywords()
  }, [])
  const onChange = (data, preData) => {
    setInnerData(data)
  }
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      type: inputdata.type,
      name: inputdata.name,
      key: inputdata.key,
      url: inputdata.url,
      appId: inputdata.appId,
      pagePath: inputdata.pagePath,
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      type: inputdata.type,
      name: inputdata.name,
      key: inputdata.key,
      url: inputdata.url,
      appId: inputdata.appId,
      pagePath: inputdata.pagePath,
      parentId: formData?.parentId,
      groupId: formData?.groupId,
    }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='菜单'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createWechatMenu}
      updateData={updateWechatMenu}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={innerData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onChange={onChange}
      height={clientHeight - 280}
      event={event}
    />
  )
})
