import { createSlice } from '@reduxjs/toolkit'

const envSlice = createSlice({
  name: 'env',
  initialState: {
    values: { initialized: false },
  },
  reducers: {
    setEnv(state, action) {
      state.values = { ...action.payload, initialized: true }
    },
  },
})

export const { setEnv } = envSlice.actions
export const getEnv = (state) => state?.env?.values
export default envSlice.reducer
