import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { deleteMaterial, getDevices, getMaterials, removeDevice, removeStation } from 'common/apis/maindata'
import { CommonConfirm, CommonIndex } from 'components/LoongInCommon'
import { StationFormEdit } from './StationFormEdit'
import { LeftPanel } from './LeftPanel'
import { Menu, MenuItem, SubMenu, MenuItemDivider, Button } from '@abb/abb-common-ux-react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { fetchProjectAsync, getProject } from 'slices/ezProjectSlice'
import { SearchDevice } from './SearchDevice'
import axios from 'axios'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { usePermission } from 'hooks/usePermission'

export default function Stations() {
  const urlParams = useParams()

  const [MaterialFormData, setMaterialFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState('')
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formDevTypRef = useRef(null)
  const refIndex = useRef(null)
  const devTypRef = useRef(null)
  const devSearchRef = useRef(null)
  const history = useHistory()
  const project = useSelector(getProject)
  const canUpdate = usePermission('MainData.Project.Update')

  useEffect(() => {
    if (urlParams.id) {
      dispatch(fetchProjectAsync(urlParams.id))
    }
  }, [urlParams.id])

  const [searchData, setSearchData] = useState(null)
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showDetail(item),
        title: t('t_detail'),
      },
    ]
  }
  //打开权限树
  const showDetail = (item) => {
    history.push('/maindata/device/' + item.id)
  }
  //列定义
  const columns = [
    {
      title: '设备',
      key: 'displayName',
    },
    {
      title: '型号',
      key: 'modal',
    },
    {
      title: '回路',
      key: 'pannelName',
    },
  ]
  const onAddBefore = () => {
    return {
      materialCatalogId: activeItem.key,
      materialCatalogName: activeItem.title,
    }
  }

  const setShowOrganSidePanel = (visible) => {
    formDevTypRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex('')
      setMaterialFormData(null)
      devTypRef.current.getMaterialCatalogsTree()
    } else {
      devTypRef.current.getMaterialCatalogsTree()
    }
  }

  const getDeleteInput = (item) => {
    return {
      projectId: project?.id,
      deviceId: [item.id],
    }
  }

  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(removeStation(), { projectId: project?.id, stationId: [deleteId] })
      if (response.status === 200 || response.status === 204) {
        setActivDataIndex('')
        setMaterialFormData(null)
        setDeleteId(null)
        devTypRef.current.getMaterialCatalogsTree()
        dispatch(showNotification(NotificationType.Success, t('t_hasDelete')))
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_deleteFail')))
      }
    } catch (e) {
      console.log(e)
      dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail')))
    } finally {
      setIsDeleting(false)
    }
  }
  const toolChildren = (
    <>
      <Button
        text='添加设备'
        type='primary-blue'
        sizeClass='small'
        icon={'abb/plus'}
        // isLoading={isOperating}
        onClick={() => {
          console.log(activeItem)
          devSearchRef.current.showDialog(activeItem.key)
        }}
        disabled={!activeItem || activeItem.type == 1}
      />{' '}
      <Button
        text='添加变电站'
        type='normal'
        sizeClass='small'
        icon={'abb/plus'}
        // isLoading={isOperating}
        onClick={() => {
          formDevTypRef.current.setShowSidePanel(true)
        }}
      />
    </>
  )
  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      projectId={project?.id}
      onActive={(item) => {
        setActiveItem(item)
        setSearchData({ projectId: project?.id, stationId: item.key })
      }}
      onDel={(item) => {
        setDeleteId(item.key)
      }}
    ></LeftPanel>
  )

  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getDevices}
        searchData={searchData}
        getDeleteInput={getDeleteInput}
        deleteData={removeDevice}
        otherMenu={canUpdate ? otherMenu : null}
        canSearch={false}
        canDelete={canUpdate?true:false}
        onAddBefore={onAddBefore}
        pageInQuery={false}
        toolChildren={canUpdate ? toolChildren : null}
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
      <StationFormEdit
        ref={formDevTypRef}
        onCancel={() => {
          setActivDataIndex('')
          setMaterialFormData(null)
        }}
        id={project?.id}
        customerId={project?.customerId}
        onSubmit={onSubmit}
      />
      <SearchDevice ref={devSearchRef} projectId={project?.id} onRefresh={() => setTimeout(() => refIndex.current.query(), 500)} />
      <CommonConfirm
        title={t('t_titleDeleteData')}
        content={t('t_confirmDeleteData')}
        isVisible={deleteId != null}
        onConfirm={onDeleteConfirm}
        confirmText={t('b_confirm')}
        onCancel={() => setDeleteId(null)}
        isLoading={isDeleting}
      />
    </>
  )
}
