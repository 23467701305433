import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'

import { addDeviceMaterials, getMaterials } from 'common/apis/maindata'
import { CommonIndex } from 'components/LoongInCommon'
import { LeftPanel } from './LeftPanel'
import { Dialog } from '@abb/abb-common-ux-react'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import axios from 'axios'

export const Materials = forwardRef(({device, onRefresh }, ref) => {
  const dispatch = useDispatch()
  const refIndex = useRef(null)
  const devTypRef = useRef(null)
  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  const [checkItems, setCheckItems] = useState([])

  const [searchData, setSearchData] = useState(null)
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showDetail(item),
        title: t('t_detail'),
      },
    ]
  }
  //打开权限树
  const showDetail = (item) => {
    history.push('/maindata/materials/detail/' + item.id)
  }
  //列定义
  const columns = [
    {
      title: 'material.code',
      key: 'code',
    },
    {
      title: 'material.purchaseCode',
      key: 'purchaseCode',
    },
    {
      title: 'material.name',
      key: 'name',
    },
    {
      title: 'material.modal',
      key: 'modal',
    },
    {
      title: 'material.provider',
      key: 'provider',
    },
    {
      title: 'material.warranty',
      key: 'provider',
      render: (item, index) => {
        return item.warranty + '年'
      },
    },
  ]
  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      onActive={(item) => {
        setSearchData({ catalogId: item.key })
      }}
    ></LeftPanel>
  )

  const onSelectionChange = (items) => {
    // console.log(items)
    setCheckItems(items)
  }

  const addMaterials = async (items, callback) => {
    try {
      axios
        .post(addDeviceMaterials(), {
          materials: items,
          deviceId: device.id,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            dispatch(showNotification(NotificationType.Success, t('t_successToAdd')))
            if (callback) callback()
          } else {
            dispatch(showNotification(NotificationType.Warn, t('t_failToAdd')))
          }
        })
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_failToAdd')))
    } finally {
    }
  }
  const onConfirm = () => {
    if (!checkItems || checkItems.length <= 0) {
      dispatch(showNotification(NotificationType.Alarm, t('t_plsSelectUser')))
      return
    }
    addMaterials(
      checkItems.map((item) => {
        return item.id
      }),
      () => {
        setIsVisible(false)
        onRefresh && onRefresh()
      }
    )
  }
  const onCancel = () => {
    setIsVisible(false)
  }

  //传递引用方法
  useImperativeHandle(ref, () => ({
    // setIsVisible,
    // setSearchData,
    showDialog,
  }))
  const showDialog = () => {
    setCheckItems([])
    setSearchData({})
    setIsVisible(true)
  }
  //返回页面
  return (
    <>
      <Dialog
        style={{ width: '1360px' }}
        showCloseButton={true}
        closeOnLostFocus
        closeOnEscape={false}
        dimBackground={true}
        isOpen={isVisible}
        title={t('b_addUser')}
        standardButtonsOnBottom={[
          {
            text: t('b_confirm'),
            type: 'primary-blue',
            handler: onConfirm,
          },
          {
            text: t('b_cancel'),
            type: 'normal',
            handler: onCancel,
          },
        ]}
        onClose={() => setIsVisible(false)}
      >
        <div style={{ height: '420px' }}>
          <CommonIndex
            ref={refIndex}
            tableWidth='100%'
            columns={columns}
            pageSize={6}
            getList={getMaterials}
            searchData={searchData}
            canAdd={false}
            canEdit={false}
            canDelete={false}
            enableSorting={false}
            leftPanelWidth={0.1}
            pageInQuery={false}
            leftPanel={leftPanel}
            tableHeight={300}
            refreshWhenOpen={false}
            canSelection={true} //是否开启选择功能
            canBatchDel={false}
            onSelectionChange={onSelectionChange}
            multipleChoice={true}
            searchClassName='searching-form-simple' //查询样式
            toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
          />
        </div>
      </Dialog>
    </>
  )
})
