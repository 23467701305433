import React, { useState, useEffect, useRef } from 'react'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import { SlidingCard } from 'components/SlidingCard'
import { Data } from './data'
import { Weight } from './weight'

export function DeviceData({ onDataEdit }) {
  const [event, setEvent] = useState('')
  return (
    <>
      <SlidingCard>
        <Data
          event={event}
          onCancel={() => {
            setEvent(null)
          }}
          onEdit={() => {
            setEvent(null)
            onDataEdit()
          }}
          key={nanoid()}
          borderType='discreet'
          otherButton={[
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/edit'
              onClick={() => {
                setEvent('showDataSidePanel')
              }}
              style={{ opacity: 1, marginRight: 8 }}
            />,
          ]}
          title={t('deviceData.formTitle')}
        />
        <Weight
          event={event}
          onCancel={() => {
            setEvent(null)
          }}
          onEdit={() => {
            setEvent(null)
            onDataEdit()
          }}
          key={nanoid()}
          borderType='discreet'
          otherButton={[
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/edit'
              onClick={() => {
                setEvent('showArgsSidePanel')
              }}
              style={{ opacity: 1, marginRight: 8 }}
            />,
          ]}
          title='设备权重'
        />
      </SlidingCard>
    </>
  )
}
