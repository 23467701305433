import axios from 'axios'
import { addDocuments, getDocuments } from 'common/apis/maindata'
import { DocumentManager } from 'components/DocumentManager'
import React, { useState, useEffect } from 'react'

export default function Documents({  }) {
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>微信文档</div>
        </div>
        <div className='widget-body device-profile-details'>
          <DocumentManager
            basePath={'/'}
            onCreatedDirectory={({ action, item, data }) => {
              console.log(data)
            }}
          ></DocumentManager>
        </div>
      </div>
    </div>
  )
}
