import React from 'react'
// import React, { useEffect } from "react"
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getEnv } from 'slices/envSlice'
import { getUserProfile } from 'slices/userProfileSlice'
import { AppMainContent } from '@abb/abb-common-ux-react'
import { SideNavMenu, SideNavMenuItem } from 'components/SideNavMenu'
import decode from 'jwt-decode'
import { ErrorBoundary } from 'common/ErrorBoundary'
import { useTranslation } from 'react-i18next'
import { wechat } from '../menu.js'
import translate from '../../i18n/translate'
import Dashboard from './Dashboard/index.jsx'
import Menus from './Menus/index.jsx'
import Tags from './Tags/index.jsx'
import Keywords from './Keywords/index.jsx'
import Materials from './Materials/index.jsx'
import Users from './Users/index.jsx'
import Documents from './Documents/index.jsx'

function Wechat() {
  // 动态加载
  // const Dashboard = React.lazy(() => import("./Dashboard"))
  // const Users = React.lazy(() => import("./Users"))
  // const Settings = React.lazy(() => import("./Settings"))
  // const IoT = React.lazy(() => import("./IoT"))
  // const Logs = React.lazy(() => import("./Logs"))
  // const Resource = React.lazy(() => import("./Resource"))

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const navItems = translate(wechat, t)

  const env = useSelector(getEnv)
  const userProfile = useSelector(getUserProfile)
  const oauth = useSelector((state) => state.oauth)
  const user = oauth?.accessToken ? decode(oauth?.accessToken) : null

  if (
    // (env.solution === "OnPremise" && user?.role === "admin") ||
    // userProfile?.visibilityCode === "Global"
    userProfile
  ) {
    return (
      <>
        <SideNavMenu>
          {navItems.map((item, index) => (
            <SideNavMenuItem
              key={index}
              itemKey={index}
              icon={item.icon}
              permission={item.permission}
              title={item.title}
              onClick={() => history.push(item.path)}
              active={location.pathname === item.path || (item.path !== '/wechat' && location.pathname.indexOf(item.path) === 0)}
            />
          ))}
        </SideNavMenu>
        <ErrorBoundary>
          <AppMainContent style={{ boxSizing: 'border-box' }}>
            <Switch>
              <Route exact path={path} component={Dashboard} />
              <Route path={`${path}/menus`} component={Menus} />
              <Route path={`${path}/tags`} component={Tags} />
              <Route path={`${path}/keywords`} component={Keywords} />
              <Route path={`${path}/materials`} component={Materials} />
              <Route path={`${path}/users`} component={Users} />
              <Route path={`${path}/documents`} component={Documents} />
            </Switch>
          </AppMainContent>
        </ErrorBoundary>
      </>
    )
  } else if (!userProfile) {
    return null
  } else {
    return (
      <div className='grid root-grid'>
        <p>{t('youHaveNotVisitPurview')}</p>
      </div>
    )
  }
}

export default Wechat
