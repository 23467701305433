import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule, CommonForm } from "components/LoongInCommon"
import { useDispatch, useSelector } from "react-redux"
import { createDistributor, updateDistributor } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getDictionary } from "slices/dataDictionarySlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { getUsers } from "slices/userSlice"
import { treeMap } from "common/utils"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"
export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel,event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const adminFormEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [rules, setRules] = useState({})
  const [adminRules, setAdminRules] = useState({})
  const dictionary = useSelector(getDictionary)
  const srvArea = getDataDictionaryDetail(dictionary, "srvArea")
  const distributorlevel = getDataDictionaryDetail(dictionary, 'Distributorlevel')
  const distributorStatus = getDataDictionaryDetail(dictionary,'DistributorStatus')
  const users = useSelector(getUsers)
  
  //初始化数据
  const initData = {
    code: "",
    name: "",
    level: "",
    tag: "",
    startDate: "",
    finishedDate: "",
    manager: null,
    contact: "",
    phone: "",
    email: "",
    area: "",
    address: "",
    status: "Normal",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("distributor.name"),
      fieldName: "name",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("distributor.code"),
      fieldName: "code",
      maxLength: 50,
    },
    {
      type: "dropdown",
      label: t("distributor.level"),
      fieldName: "level",
      searchable:true,
      options: distributorlevel
        ? distributorlevel.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
    {
      type: "text",
      dataType: "text",
      label: t("distributor.tag"),
      fieldName: "tag",
      maxLength: 250,
    },
    {
      type: "date",
      label: t("distributor.startDate"),
      fieldName: "startDate",
      dateFormat: "yyyy-MM-dd",
      showClearIcon: true,
    },
    {
      type: "date",
      label: t("distributor.finishedDate"),
      fieldName: "finishedDate",
      dateFormat: "yyyy-MM-dd",
      showClearIcon: true,
    },
    {
      type: "dropdown",
      label: t("distributor.manager"),
      fieldName: "manager",
      searchable:true,
      options: users
        ? users.map((c) => {
            return { label: c.name, value: c.id }
          })
        : [],
    },
    {
      type: "text",
      dataType: "text",
      label: t("distributor.contact"),
      fieldName: "contact",
      maxLength: 100,
    },
    {
      type: "text",
      dataType: "text",
      label: t("distributor.phone"),
      fieldName: "phone",
      maxLength: 50,
    },
    {
      type: "text",
      dataType: "text",
      label: t("distributor.email"),
      fieldName: "email",
      maxLength: 100,
    },
    {
      type: "dropdown",
      label: t("distributor.area"),
      fieldName: "area",
      options: srvArea
        ? srvArea.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
      maxLength: 100,
    },
    {
      type: "text",
      dataType: "text",
      label: t("distributor.address"),
      fieldName: "address",
      maxLength: 500,
    },
    {
      type: "dropdown",
      label: t("distributor.status"),
      fieldName: "status",
      options: distributorStatus
        ? distributorStatus.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return inputdata
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return inputdata
  }

  
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("distributor.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createDistributor}
      updateData={updateDistributor}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
    </CommonFormEdit>
  )
})
