import React, { useState, useEffect, useRef } from 'react'
import { getDeviceTypeBindList, getMaintenanceCatalogTree, setDeviceTypeBindSop, setDeviceTypeBind } from 'common/apis/ezreport'
import axios from 'axios'
import { CommonIndex, CommonTable } from 'components/LoongInCommon'
import { LeftPanel } from './LeftPanel'
import { Button } from '@abb/abb-common-ux-react'
import { FileSelector } from 'components/FileSelector'
import { set } from 'lodash'

export default function DeviceTypes() {
  const [activeItem, setActiveItem] = useState(null)
  const refIndex = useRef(null)
  const devTypRef = useRef(null)
  const [checkedBind, setCheckedBind] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [allList, setAllList] = useState([])
  const [checkedItems2, setCheckedItems2] = useState([])
  const [searchData, setSearchData] = useState(null)
  const setBindSop = (item, docId) => {
    axios.post(setDeviceTypeBindSop(), {
      itemId: item.key,
      deviceTypeId: activeItem.key,
      sopDocumentId: docId,
      maintenanceType: item.type,
    })
  }
  const childrenColumns = [
    {
      title: '检查项',
      key: 'title',
      width: '300',
    },
    {
      title: ' ',
      key: 'title',
      render: (item) => {
        return (
          <FileSelector
            path={'/_/ezreport/sop/'}
            onValueChanged={(d) => {
              setBindSop(item, d[0].id)
            }}
            value={checkedBind.find((b) => b.itemId == item.key)?.sopDocumentId}
            emptyText='作业指导书上传'
            valuedText='更新作业指导书'
            showDownload={true}
          />
        )
      },
    },
  ]

  useEffect(() => {
    if (activeItem && allList) {
      fetchBind()
    }
  }, [activeItem, allList])

  const fetchBind = () => {
    axios.get(getDeviceTypeBindList(), { params: { id: activeItem.key, projectType: '8' } }).then((response) => {
      setCheckedBind(response.data)
      let x = response.data.filter((i) => i.isEnabled == true).map((i) => i.itemId)

      setCheckedItems2(x)
      setCheckedItems(allList.filter((l) => !!x.find((i) => i == l.key)))
    })
  }
  const columns = [
    {
      title: '检查项区域',
      key: 'title',
      textAlign: 'center',
      expand: (item, index) => {
        const list = item.children
        if (list.length == 0) return <div style={{ marginLeft: '20px' }}>没有数据</div>
        return (
          <div style={{ padding: '10px 20px 10px 20px' }}>
            <CommonTable
              zebra={true}
              columns={childrenColumns}
              checkedItems={checkedItems2}
              canSelection={true}
              data={list}
              showPage={false}
              onSelectionChange={(items) => {
                const d = checkedItems.filter((c) => !list.find((l) => l == c))
                setCheckedItems([...d, ...items])
              }}
              keyField='key'
            />
          </div>
        )
      },
    },
  ]
  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      onActive={(item) => {
        setActiveItem(item)
        setSearchData({ deviceTypeId: item.key })
        // refIndex.current.query()
      }}
    ></LeftPanel>
  )

  const getResult = (data) => {
    let list = []
    data.forEach((i) => {
      list = [...list, ...i.children]
    })
    setAllList(list)
    return data
  }
  const saveBind = () => {
    let datas = checkedBind.map((i) => {
      return {
        itemId: i.itemId,
        deviceTypeId: i.deviceTypeId,
        maintenanceType: i.maintenanceType,
        isEnabled: false,
      }
    })
    checkedItems.forEach((item) => {
      let d = datas.find((i) => i.itemId == item.key)
      if (d) {
        d.isEnabled = true
      } else {
        datas.push({ itemId: item.key, deviceTypeId: activeItem.key, maintenanceType: item.type, isEnabled: true })
      }
    })
    axios.post(setDeviceTypeBind(), { data: datas }).then((response) => {
      refIndex.current.query()
      fetchBind()
    })
  }

  const toolChildren = (
    <>
      <Button text={'保存'} type='normal' sizeClass='small' icon={'abb/save'} disabled={!activeItem} onClick={saveBind} />
    </>
  )

  const getGetListInput = (params) => {
    return { type: activeItem.type, projectType: '8' }
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        keyField='key'
        columns={columns}
        getList={getMaintenanceCatalogTree}
        getGetListInput={getGetListInput}
        getResult={getResult}
        toolChildren={toolChildren}
        searchData={searchData}
        showPage={false}
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
    </>
  )
}
