import React, { useRef, useImperativeHandle, forwardRef, useState, useEffect } from "react"
import { CommonForm, CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { useSelector } from "react-redux"
import { createTestItem, updateTestItem } from "common/apis/ezreport"

export const TestItemFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [weights, setWeights] = useState({})
  const [values, setValues] = useState({})
  const weightInitData = {
    security: 0,
    structure: 0,
    protection: 0,
    exothermic: 0,
    electromechanical: 0,
    insulation: 0,
  }
  const valueInitData = {
    ok: 0,
    fail: 0,
    none: 0,
  }
  //初始化数据
  const initData = {
    name: "",
    testType: "",
    catalogId: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: "试验项名称",
      fieldName: "name",
      maxLength: 128,
    },
    {
      type: "dropdown",
      label: "维保类别",
      fieldName: "testType",
      options: [
        {
          value: "1",
          label: "维保类别Ⅱ",
        },
        {
          value: "2",
          label: "维保类别Ⅰ",
        },
        {
          value: "3",
          label: "维保类别Ⅲ",
        },
      ],
    },
    {
      type: "checkbox",
      dataType: "text",
      label: "系统试验",
      fieldName: "systemTest",
      maxLength: 128,
    },
    {
      type: 'text',
      dataType: 'number',
      label: '失效倍数',
      fieldName: 'failureMultiple',
    },
  ]
  const onWeightChange = (d) => {
    setWeights(d)
  }
  const onValueChange = (d) => {
    setValues(d)
  }
  useEffect(() => {
    if (formData) {
      console.log(formData)
      setWeights(formData?.weights)
      setValues(formData?.values)
    }
  }, [formData])
  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
    testType: [CommonRule.Require],
    
  }

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      testType: inputdata.testType,
      projectType:'wfm',
      weights: weights,
      values:values,
      failureMultiple:inputdata.failureMultiple,
      systemTest:inputdata.systemTest,
      catalogId: inputdata.catalogId,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      testType: inputdata.testType,
      projectType:'wfm',
      weights: weights,
      values:values,
      failureMultiple:inputdata.failureMultiple,
      catalogId: inputdata.catalogId,
      systemTest:inputdata.systemTest,
    }
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title="试验项信息"
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createTestItem}
      updateData={updateTestItem}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
       <div title={'基本权重信息'} style={{ height: clientHeight - 280 + 'px' }}>
        <CommonForm
          formItem={[
            {
              type: 'text',
              dataType: 'number',
              label: '安全基本权重',
              fieldName: 'security',
              suffix: '%',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '结构基本权重',
              fieldName: 'structure',
              suffix: '%',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '保护基本权重',
              fieldName: 'protection',
              suffix: '%',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '发热基本权重',
              suffix: '%',
              fieldName: 'exothermic',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '机电基本权重',
              suffix: '%',
              fieldName: 'electromechanical',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '绝缘基本权重',
              suffix: '%',
              fieldName: 'insulation',
              maxLength: 128,
            },
          ]}
          initData={weightInitData}
          newFormData={{ ...weightInitData, ...weights }}
          onChange={onWeightChange}
        />
      </div>
      <div title={'分值'} style={{ height: clientHeight - 280 + 'px' }}>
        <CommonForm
          formItem={[
            {
              type: 'text',
              dataType: 'number',
              label: '合格分值',
              fieldName: 'ok',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '异常分值',
              fieldName: 'fail',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '不适用分值',
              fieldName: 'none',
              maxLength: 128,
            }
          ]}
          initData={valueInitData}
          newFormData={{ ...valueInitData, ...values }}
          onChange={onValueChange}
        />
      </div>
    </CommonFormEdit>
  )
})
