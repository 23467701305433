import React, { useState, useEffect, useRef } from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { CommonConfirm, CommonList } from "components/LoongInCommon"
import { MainIndex } from "./MainIndex"
import { deleteImportanceWeightCatalogs, getImportanceWeightCatalogsPage } from "common/apis/maindata"
import axios from "axios"
import { TypeFormEdit } from "./TypeFormEdit"
import { t } from "i18next"
import { useDispatch } from "react-redux"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"

export default () => {
  const { path } = useRouteMatch()
  const [activeItem, setActiveItem] = useState([])
  const refList = useRef(null)
  const [list, setList] = useState(null)
  const [isLoadingList, setIsLoadingList] = useState(false)
  const [typeFormData, setTypeFormData] = useState(null)
  const [typeFormEditEvent, setTypeFormEditEvent] = useState(null)
  const typeFormRef = useRef(null)
  const [activDataIndex, setActivDataIndex] = useState("")
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(null)
  const dispatch = useDispatch()

  // const list = [
  //   {'key':'PowerLoadLevel','title':'电力负荷级别'},
  //   {'key':'MainSwitchFunction','title':'主开关功能'},
  //   {'key':'LineImportance','title':'线路重要程度'},
  // ]

  
  const onTypeFormSumbit = () => {
    if (activDataIndex) {
      setActivDataIndex("")
      setTypeFormData(null)
      getAllList()
    } else {
      getAllList()
    }
  }
  const getAllList = () => {
    setIsLoadingList(true)
    axios
      .post(getImportanceWeightCatalogsPage(), {pageSize:10000})
      .then((response) => {
        if (response.status == 200 && response.data) {
          const items = response.data.items
          setList(items)
          if (items.length > 0) {
            refList.current.setCurrentItem(items[0])
            // setActiveItem(response.data.items[0])
          } else setActiveItem({})
        } else {
          setList([])
        }
      })
      .finally(() => {
        setIsLoadingList(false)
      })
  }

  useEffect(() => {
    getAllList()
  }, [])

  const rightIcon = {
    name: "abb/plus",
    color: "blue",
    sizeClass: "medium",
    onClick: () => {
      setTypeFormData({
        name: "",
      })
      setActivDataIndex("")
      setTypeFormEditEvent({
        name: "setShowSidePanel",
        params: [true],
      })
      // typeFormRef.current.setShowSidePanel(true)
    },
  }

  const myMenu = [
    {
      title: t("b_edit"),
      color: "blue",
      onClick: (item) => {
        setTypeFormData({
          id: item.id,
          name: item.name,
        })
        setActivDataIndex(item.id)
        setTypeFormEditEvent({
          name: "setShowSidePanel",
          params: [true],
        })
        // typeFormRef.current.setShowSidePanel(true)
      },
    },
    {
      title: t("b_del"),
      color: "red",
      onClick: (item) => {
        setDeleteId(item.id)
      },
    },
  ]
  const onItemChange = (item) => {
    console.log(item);
    setActiveItem(item)
  }

  
  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(deleteImportanceWeightCatalogs(),null ,{params:{
        id: deleteId,
      }})
      if (response.status === 200 || response.status === 204) {
        setDeleteId(null)
        getAllList()
        dispatch(showNotification(NotificationType.Success, t("t_hasDelete")))
      } else {
        dispatch(showNotification(NotificationType.Warn, t("t_deleteFail")))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t("t_deleteFail")))
    } finally {
      setIsDeleting(false)
    }
  }
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        padding: 8,
        boxSizing: "border-box",
      }}
      data-cy="admin-device-model"
    >
      <Route path={[`${path}/:id`, path]}>
        <CommonList style={{margin:0}}
          ref={refList}
          data={list}
          keyName="id"
          filterText="name"
          displayName="name"
          onItemChange={onItemChange}
          rightIcon={rightIcon}
          contexMenu={myMenu}
        />
         <TypeFormEdit
          ref={typeFormRef}
          formData={typeFormData}
          onCancel={() => {
            setActivDataIndex("")
            setTypeFormData(null)
          }}
          id={activDataIndex}
          onSubmit={onTypeFormSumbit}
          event={typeFormEditEvent}
        />
        <MainIndex catalog={activeItem.id} title={activeItem.name}/>
        <CommonConfirm
          title={t("t_titleDeleteData")}
          content={t("t_confirmDeleteData")}
          isVisible={deleteId != null}
          onConfirm={onDeleteConfirm}
          confirmText={t("b_confirm")}
          onCancel={() => setDeleteId(null)}
          isLoading={isDeleting}
        />
      </Route>
    </div>
  )
}
