import React, { useState } from 'react'
import { Button } from '@abb/abb-common-ux-react'
import axios from 'axios'
import { sapZbapiGetBomDmx02, testSapZbapiGetBomDmx02 } from 'common/apis/maindata'

export default function SapRfcTest() {
    const [message, setMessage] = useState("")
    const [data, setData] = useState(null)
    const runSapZbapiGetBomDmx02 = async () => {
        try {
            const response = await axios.post(testSapZbapiGetBomDmx02(), {
                "soNum": "503842695",
                "ciPos": "0",
                "tiPos": "1001",
                "material": "6030839"
            })
            console.log(response)
            if (response?.data) {
                setData(response?.data)
                setMessage(JSON.stringify(response?.data?.result))
            } else {
                console.log("error to run")
            }
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    return (
        <div style={{ padding: "24px" }}>
            <Button type="primary-blue" text='测试ZBAPI_GET_BOM_DMX_02' sizeClass='small' style={{ marginLeft: 8 }}
                onClick={() => runSapZbapiGetBomDmx02([])} />
            <div style={{ padding: "12px" }}>
                <div>执行结果：{data?.status}</div>
                {message && <div style={{ backgroundColor: "#fff", padding: "6px" }}>
                    {message}
                </div>}
            </div>
        </div>
    )
}
