export const getTotalReportPage = () => `api/EzReport/TotalReports/page`
export const getTotalReportDetail = (id) => `api/EzReport/TotalReports/detail?id=${id}`
export const getInspenctionReportDetail = (id) => `api/EzReport/TotalReports/inspenction?id=${id}`
export const updateTotalReport = (id) => `api/EzReport/TotalReports/update?id=${id}`
export const approveTotalReport = (id) => `api/EzReport/TotalReports/approve?id=${id}`
export const generateTotalReport = (id) => `api/EzReport/TotalReports/generate?id=${id}`
export const uploadTotalReport = () => `api/EzReport/TotalReports/upload`


export const getReportDetail = () => `api/EzReport/TotalReports/detail`

