import React, { useState, useCallback } from 'react'
import { Button, Dialog, Spinner } from '@abb/abb-common-ux-react'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { getEnv } from 'slices/envSlice'
import { ResponseResult } from 'common/request'
import axios from 'axios'
import { uploadFile, downloadFile } from 'common/apis/maindata'
import { forwardRef } from 'react'
import { useEffect } from 'react'

export const FileSelector = forwardRef(
  (
    { uploadUrl = null, downloadUrl = null, params = null, customerId, path, value, emptyText, valuedText, showDownload, onValueChanged, children },
    ref
  ) => {
    const env = useSelector(getEnv)
    const [isUploading, setIsUploading] = useState(false)
    const [isShowResetConfirm, setIsShowResetConfirm] = useState(false)
    const [data, setData] = useState(null)
    const [directory, setDirectory] = useState(null)
    const onDrop = useCallback(
      async (acceptedFiles, fileRejections, event) => {
        // Do something with the files
        if (!acceptedFiles[0]) {
          return
        }
        setIsUploading(true)
        const form = new FormData()
        form.append('files', acceptedFiles[0])
        axios
          .post(uploadUrl ?? uploadFile(), form, { params: params ?? { customerId: customerId, path: directory } })
          .then((response) => {
            if (response.data) {
              setData(response.data)
              onValueChanged && onValueChanged(response.data)
            } else {
              onValueChanged && onValueChanged({})
            }
          })
          .finally(() => setIsUploading(false))
      },
      [directory]
    )

    useEffect(() => {
      if (path) {
        setDirectory(path)
      }
    }, [path])
    const { getRootProps, getInputProps } = useDropzone({ onDrop, noDragEventsBubbling: false })

    return (
      <>
        <div {...getRootProps({ style: { display: 'inline-block' } })} title='上传文件'>
          <input {...getInputProps({ multiple: false })} />
          {children && children}
          {!children && <div className='file-picker'>{data || value ? valuedText ?? '上传文件' : emptyText ?? '上传文件'}</div>}
          {isUploading && (
            <div className='central-position'>
              <Spinner />
            </div>
          )}
        </div>
        {showDownload && (downloadUrl || data || value) && (
          <a target='about:blank'
            href={
              downloadUrl ??
              ` ${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadUrl ?? `${downloadFile()}?id=${(data ?? [{ id: value }])[0].id}`}`
            }
            className='file-picker'
          >
            下载
          </a>
        )}
        <Dialog
          title='重置'
          dimBackground
          isOpen={isShowResetConfirm}
          standardButtonsOnBottom={[
            {
              text: '确认重置文件',
              type: 'primary-blue',
              handler: () => {
                onValueChanged(null)
                setIsShowResetConfirm(false)
              },
            },
            {
              text: '取消',
              type: 'normal',
              handler: () => setIsShowResetConfirm(false),
            },
          ]}
        >
          确认删除？
        </Dialog>
      </>
    )
  }
)
