import axios from 'axios'
import { getQuestionnaire, saveQuestionnaires } from 'common/apis/questionnaire'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { Button, Card, Checkbox, Dropdown, DropdownOption, Input, NumericInput, RadioGroup, RadioOption } from '@abb/abb-common-ux-react'
import DatePicker from "react-datepicker"
import { getDictionary } from 'slices/dataDictionarySlice'
import { getDataDictionaryDetail } from 'common/dataDictionary'
import { Col, Row } from 'components/Grid'
import "./questionaires.scss"
import { useHistory } from 'react-router-dom'
import { guid } from 'common/utils'
import { getQuestionaireCache, setQuestionaireCache } from 'slices/questionaireSlice'
import { useMountedState } from 'common/useMountedState'



export default function EditDetails() {
  const isMounted = useMountedState()
  const history = useHistory()
  const urlParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [main, setMain] = useState({})
  const [questions, setQuestions] = useState([])
  const [options, setOptions] = useState([])
  const dictionary = useSelector(getDictionary)
  const questionTypes = getDataDictionaryDetail(dictionary, "QuQuestionType")
  const inputTypes = getDataDictionaryDetail(dictionary, "QuInputType")
  const validateTypes = getDataDictionaryDetail(dictionary, "QuValidateType")
  const relevantInfos = getDataDictionaryDetail(dictionary, "QuRelevantInfo")
  const questionaireCache = useSelector(getQuestionaireCache)

  const questionaireId = urlParams.id
  //读取数据
  const getData = (id) => {
    if (!isMounted()) return;
    setIsLoading(true)
    axios.post(getQuestionnaire(id), {}).then((response) => {
      if (!isMounted()) return;
      if (response.status === 200) {
        setMain(response.data.main)
        setQuestions(response.data.questions)
        setOptions(response.data.options)
      } else {
        dispatch(showNotification(NotificationType.Warn, response.data.message))
      }
    }).catch(e => {
      console.log("error: ", e)
      dispatch(showNotification(NotificationType.Alarm, "出现错误"))
    }).finally(() => {
      setIsLoading(false)
    })
  }
  //保存问卷
  const save = () => {
    setIsLoading(true)
    const newquestions = resetQuestionsIndex()
    axios.post(saveQuestionnaires(), { main, questions: newquestions, options }).then((response) => {
      if (!isMounted()) return;
      if (response.status === 200) {
        dispatch(showNotification(NotificationType.Success, "保存成功"))
        dispatch(setQuestionaireCache(null))
        history.push('/maindata/questionnaires')
      } else {
        dispatch(showNotification(NotificationType.Warn, response.data.message))
      }
    }).catch(e => {
      console.log("error: ", e)
      dispatch(showNotification(NotificationType.Alarm, "出现错误"))
    }).finally(() => {
      setIsLoading(false)
    })
  }
  const resetQuestionsIndex = () => {
    let newquestions = [...questions]
    newquestions.forEach(c => {
      if (c.rowIndex2) {
        c.rowIndex = c.rowIndex2
        c.rowIndex2 = 0
      }
    })
    newquestions = newquestions.sort((a, b) => a.rowIndex - b.rowIndex)
    resetRowIndex(newquestions)
    setQuestions(newquestions)
    return newquestions
  }
  //清除问题
  const clearQuestions = () => {
    setQuestions([])
    setOptions([])
  }
  //添加问题
  const addQuestion = (question) => {
    const newQuestion = {
      rowIndex: 0,
      questionId: guid(),
      questionnaireId: main.id,
      questionText: "",
      fillingPrompt: "",
      questionType: "Choice",
      isMultiple: false,
      inputType: "Radio",
      decimalDigits: 0,
      isNeedFeedback: false,
      feedbackPlaceHolder: "",
      isRequired: true,
      minLength: 0,
      maxLength: 500,
      validateType: "",
      maxScore: 0,
      relevantInfo: ""
    }
    const newquestions = [...questions]
    if (question) {
      const idx = newquestions.findIndex(c => c.rowIndex == question.rowIndex)
      newquestions.splice(idx, 0, newQuestion);  // 在新位置插入该元素
    } else {
      newquestions.push(newQuestion)
    }
    resetRowIndex(newquestions)
    setQuestions(newquestions)
    addOption(newQuestion)
    addOption(newQuestion)
    addOption(newQuestion)
    if (!question) {
      setTimeout(() => scrollToBottom(), 200)
    }
  }
  //获取最大编号
  const getMaxRowIndex = (list, colName = "rowIndex") => {
    if (!list || list.length == 0) return 0;
    const maxRowIndex = Math.max(...list.map(item => item[colName]))
    return maxRowIndex
  }
  //获取当前问题的选项
  const getCurrentOptions = (question) => {
    let curOptions = options.filter(c => (question.questionId && c.questionId == question.questionId))
    curOptions = curOptions.sort((a, b) => a.rowIndex - b.rowIndex);
    return curOptions
  }
  const getSortQuestions = () => {
    if (!questions) return []
    const sortQuestions = questions.sort((a, b) => a.rowIndex - b.rowIndex);
    return sortQuestions
  }
  //初始化评分选项
  const initScore = (question) => {
    const newOptions = options.filter(c => !(question.questionId && c.questionId == question.questionId))
    newOptions.push({
      rowIndex: 1, //"顺序",
      questionnaireId: question.questionnaireId,// "问卷调查",
      questionId: question.questionId,// "问题",
      optionText: "非常不满意",//"选项文本"
      optionPicture: "face-1.png",//"选项图标名",
      optionScore: 1,//"选项分值"
    })
    newOptions.push({
      rowIndex: 2, //"顺序",
      questionnaireId: question.questionnaireId,// "问卷调查",
      questionId: question.questionId,// "问题",
      optionText: "不满意",//"选项文本"
      optionPicture: "face-2.png",//"选项图标名",
      optionScore: 2,//"选项分值"
    })
    newOptions.push({
      rowIndex: 3, //"顺序",
      questionnaireId: question.questionnaireId,// "问卷调查",
      questionId: question.questionId,// "问题",
      optionText: "一般",//"选项文本"
      optionPicture: "face-3.png",//"选项图标名",
      optionScore: 3,//"选项分值"
    })
    newOptions.push({
      rowIndex: 4, //"顺序",
      questionnaireId: question.questionnaireId,// "问卷调查",
      questionId: question.questionId,// "问题",
      optionText: "满意",//"选项文本"
      optionPicture: "face-4.png",//"选项图标名",
      optionScore: 4,//"选项分值"
    })
    newOptions.push({
      rowIndex: 5, //"顺序",
      questionnaireId: question.questionnaireId,// "问卷调查",
      questionId: question.questionId,// "问题",
      optionText: "非常满意",//"选项文本"
      optionPicture: "face-5.png",//"选项图标名",
      optionScore: 5,//"选项分值"
    })
    setOptions(newOptions)
  }
  //添加选项
  const addOption = (question, option, newOption) => {
    const curOptions = getCurrentOptions(question);
    // let maxRowIndex = getMaxRowIndex(curOptions)
    // maxRowIndex++
    if (!newOption) {
      newOption = {
        rowIndex: 0, //"顺序",
        questionnaireId: question.questionnaireId,// "问卷调查",
        questionId: question.questionId,// "问题",
        optionText: "",//"选项文本"
        optionPicture: "",//"选项图标名",
        optionScore: 0,//"选项分值"
      }
    }
    options.push(newOption)
    if (option) {
      const idx = curOptions.findIndex(c => c.rowIndex == option.rowIndex)
      curOptions.splice(idx, 0, newOption);  // 在新位置插入该元素
    } else {
      curOptions.push(newOption)
    }
    resetRowIndex(curOptions)
    setOptions([...options])
  }
  //按顺序重置编号
  const resetRowIndex = (list) => {
    list.forEach((c, i) => {
      c.rowIndex = i + 1
    })
  }
  //移动选项
  const moveOption = (question, option, direction) => {
    const curOptions = getCurrentOptions(question);
    const idx = curOptions.findIndex(c => c.rowIndex == option.rowIndex)
    if (direction < 0 && idx > 0) {
      let newIndex = idx - 1
      curOptions.splice(idx, 1);  // 从原位置删除该元素
      curOptions.splice(newIndex, 0, option);  // 在新位置插入该元素
    } else if (direction > 0 && idx < curOptions.length - 1) {
      let newIndex = idx + 1
      curOptions.splice(idx, 1);  // 从原位置删除该元素
      curOptions.splice(newIndex, 0, option);  // 在新位置插入该元素
    }
    resetRowIndex(curOptions)
    setOptions([...options])
  }
  //删除选项
  const deleteOption = (option) => {
    const newoptions = options.filter(c => !((c.id && c.id == option.id) ||
      (c.questionId && c.questionId == option.questionId
        && c.rowIndex == option.rowIndex)))
    resetRowIndex(newoptions)
    setOptions(newoptions)
  }
  //删除问题
  const deleteQuestion = (index) => {
    const question = questions[index]
    const newquestions = [...questions]
    newquestions.splice(index, 1)
    setQuestions(newquestions)
    deleteQuesitonOption(question)
  }
  //清除问题下所有选项
  const deleteQuesitonOption = async (question) => {
    const newOptions = options.filter(c => !(question.questionId && c.questionId == question.questionId))
    await setOptions(newOptions)
  }
  //渲染单选选项
  const renderRadio = (question, option, idx, children) => {
    return (
      <div key={idx} style={{ paddingBottom: "8px" }}>
        <div role="radiogroup" style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }} className="ABB_CommonUX_RadioGroup__root ABB_CommonUX_RadioGroup__light ABB_CommonUX_RadioGroup__smallSize">
          <label className="">
            <input role="radio" disabled aria-checked="false" tabIndex="-1" type="radio" name={"radio" + question.rowIndex} value="false" />
            <span></span>
            <span></span>
          </label>
          <div style={{ display: 'flex', paddingLeft: "10px" }}>
            {children}
          </div>
        </div>
      </div>
    )
  }
  //渲染复选选项
  const renderCheck = (question, option, idx, children) => {
    return (
      <div key={idx} style={{ paddingBottom: "8px" }}>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }} className="ABB_CommonUX_Checkbox__root ABB_CommonUX_Checkbox__light ABB_CommonUX_Checkbox__smallSize">
          <label>
            <input type="checkbox" disabled checked={false} />
            <span></span>
          </label>
          <div style={{ display: 'flex', paddingLeft: "10px" }}>
            {children}
          </div>
        </div>
      </div>
    )
  }
  //渲染评分
  const renderScore = (question, option, idx, children) => {
    return (
      <div key={idx} style={{ paddingBottom: "8px" }}>
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }} >
          <label>
            <span> <img src={'/images/' + option.optionPicture} style={{ height: "28px" }} alt='cover' /></span>
          </label>
          <div style={{ display: 'flex', paddingLeft: "10px" }}>
            {children}
          </div>
        </div>
      </div>
    )
  }
  //渲染选项工具
  const renderOptionTools = (question, option, idx, curOptions) => {
    return (
      <>
        <Input
          style={{ width: "600px", paddingRight: "12px" }}
          sizeClass="small"
          dataType="text"
          maxLength={255}
          showValidationBarWhenInvalid
          showValidationIconWhenInvalid
          placeholder={t("quOption.optionText")}
          showClearIcon={true}
          value={option.optionText}
          onValueChange={(newValue) => {
            option.optionText = newValue
            setOptions([...options])
          }}
        />
        <Button text='上移' disabled={idx == 0} type='discreet-blue' sizeClass="small" onClick={e => moveOption(question, option, -1)} />
        <Button text='下移' disabled={idx == curOptions.length - 1} type='discreet-blue' sizeClass="small" onClick={e => moveOption(question, option, 1)} />
        <Button text='插入' type='discreet-blue' sizeClass="small" onClick={e => addOption(question, option)} />
        <Button text='删除' type='discreet-blue' sizeClass="small" onClick={e => deleteOption(option)} />
      </>
    )
  }
  //渲染选项
  const renderOptions = (question) => {
    const curOptions = getCurrentOptions(question);
    if (curOptions.length > 0) {
      return <div>
        {curOptions.map((option, idx) => {
          if (question.inputType == "Radio") {
            return renderRadio(question, option, idx, renderOptionTools(question, option, idx, curOptions))
          } else if (question.inputType == "Check") {
            return renderCheck(question, option, idx, renderOptionTools(question, option, idx, curOptions))
          } else if (question.inputType == "Score") {
            return renderScore(question, option, idx, renderOptionTools(question, option, idx, curOptions))
          }
        })}
      </div>
    }
  }
  //渲染问题内容
  const renderQuestionContent = (item, index) => {
    return (
      <div style={{ padding: "8px", border: "1px solid #ccc" }}>
        <div className="form-group" style={{ display: "flex" }}>
          <Row>
            <Col flex="60px">
              <NumericInput
                style={{ width: "60px" }}
                placeholder={t("quQuestion.minLength")}
                min={1}
                max={questions.length + 1}
                value={item.rowIndex2 || item.rowIndex}
                type='compact'
                sizeClass='small'
                // prefix=''
                // suffix=''
                onChange={(newValue) => {
                  if (item.rowIndex != newValue) {
                    item.rowIndex2 = newValue
                    let newquestions = [...questions]
                    newquestions = newquestions.sort((a, b) => a.rowIndex - b.rowIndex)
                    resetRowIndex(newquestions)
                    setQuestions(newquestions)
                  }
                }}
              />
            </Col>
            <Col flex="auto">
              <Input
                // prefix={index + 1 + "."}
                style={{ paddingLeft: "4px", width: "570px", }}
                sizeClass="small"
                dataType="text"
                maxLength={255}
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                placeholder={t("t_pleaseInput") + t("quQuestion.questionText")}
                showClearIcon={true}
                value={item.questionText}
                onValueChange={(newValue) => {
                  item.questionText = newValue
                  setQuestions([...questions])
                }}
              />
            </Col>
          </Row>
        </div>
        {/* <div className="form-group" >
          <Input
            sizeClass="small"
            dataType="text"
            maxLength={255}
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            placeholder={t("t_pleaseInput") + t("quQuestion.fillingPrompt")}
            showClearIcon={true}
            value={item.fillingPrompt}
            onValueChange={(newValue) => {
              item.fillingPrompt = newValue
              setQuestions([...questions])
            }}
          />
        </div> */}
        {item.inputType == "Input" &&
          <div className="form-group" >
            <Input
              sizeClass="small"
              dataType="text"
              disabled={true}
              placeholder='在此回答问题'
            />
          </div>
        }
        {item.inputType == "Textarea" &&
          <div className="form-group question-answer" >
            <Input
              sizeClass="small"
              dataType="textarea"
              disabled={true}
              placeholder='在此回答问题'
            />
          </div>
        }
        {item.inputType == "Date" &&
          <DatePicker
            className={"abb-date-picker" + (true ? " disabled" : "")}
            popperClassName="abb-date-picker-popup"
            dateFormat={"yyyy-MM-dd"}
            timeFormat="HH:mm"
            placeholderText="请选择日期"
            disabled={true}
          />
        }
        {
          item.inputType == "Decimal" && <NumericInput
            // discreet={true}
            style={{ width: "150px" }}
            placeholder="输入小数"
            value={0}
            decimals={item.decimalDigits}
            disabled
            type='compact'
            sizeClass='small'
          />
        }
        {
          item.inputType == "Number" && <NumericInput
            // discreet={true}
            style={{ width: "150px" }}
            placeholder="输入数字"
            value={0}
            disabled
            type='compact'
            sizeClass='small'
          />
        }
        {(item.inputType == "Radio") &&
          <div className="form-group" style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {renderOptions(item)}
          </div>
        }
        {(item.inputType == "Check") &&
          <div className="form-group" style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {renderOptions(item)}
          </div>
        }
        {(item.inputType == "Score") &&
          <div className="form-group" style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {renderOptions(item)}
          </div>
        }
        {item.inputType == "Score" && item.isNeedFeedback &&
          <div className="form-group question-answer" >
            <Input
              sizeClass="small"
              dataType="textarea"
              disabled={true}
              placeholder={item.feedbackPlaceHolder}
            />
          </div>
        }
        {
          (item.inputType == "Radio" || item.inputType == "Check") &&
          <Button text='添加选项' type='discreet-blue' sizeClass="small" onClick={e => addOption(item)} />
        }
      </div>
    )
  }
  //渲染问题工具栏
  const renderQuestionTools = (item, index) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, padding: "8px" }}>
        <div >
          <Button text='插入问题' type='discreet-blue' sizeClass="small" onClick={e => addQuestion(item)} />
          <Button text='删除问题' type='discreet-blue' sizeClass="small" onClick={e => deleteQuestion(index)} />
        </div>
        <Dropdown
          style={{ paddingLeft: "4px", width: "100px" }}
          showValidationBarWhenInvalid
          showValidationIconWhenInvalid
          // clearable
          placeholder={t("quQuestion.questionType")}
          // label={t("quQuestion.questionType")}
          value={[{ value: item.questionType }]}
          onChange={e => {
            item.questionType = e && e.length > 0 ? e[0].value : undefined
            if (item.questionType == "Choice") {
              item.inputType = "Radio"
              item.validateType = ""
              item.isMultiple = false
              item.decimalDigits = 0
              const curOptions = getCurrentOptions(item);
              if (curOptions.length == 0) {
                addOption(item)
                addOption(item)
                addOption(item)
              }
            }
            else if (item.questionType == "Text") {
              deleteQuesitonOption(item)
              item.inputType = "Input"
              item.isMultiple = false
              item.decimalDigits = 0
            }
            else if (item.questionType == "Others") {
              deleteQuesitonOption(item)
              item.inputType = "Number"
              item.validateType = ""
              item.isMultiple = false
              item.decimalDigits = 0
            }
            setQuestions([...questions])
          }}
        >
          {
            questionTypes.details.map((qt, idx) => {
              return <DropdownOption key={idx} label={qt.displayText} value={qt.code} />
            })
          }
        </Dropdown>
        <Dropdown
          style={{ paddingLeft: "4px", width: "150px" }}
          showValidationBarWhenInvalid
          showValidationIconWhenInvalid
          // clearable
          placeholder={t("quQuestion.inputType")}
          // label={t("quQuestion.inputType")}
          value={[{ value: item.inputType }]}
          onChange={e => {
            item.inputType = e && e.length > 0 ? e[0].value : undefined
            if (item.inputType == "Check") {
              item.isMultiple = true
            } else {
              item.isMultiple = false
            }
            if (item.inputType == "Score") {
              item.isNeedFeedback = false
              item.feedbackPlaceHolder = ""
              initScore(item)
            } else {
              item.feedbackPlaceHolder = ""
              item.isNeedFeedback = false
            }
            if (item.inputType == "Decimal") {
              item.decimalDigits = 1
            } else {
              item.decimalDigits = 0
            }
            setQuestions([...questions])
          }}
        >
          {
            inputTypes.details.filter(c => c.description == item.questionType).map((qt, idx) => {
              return <DropdownOption key={idx} label={qt.displayText} value={qt.code} />
            })
          }
        </Dropdown>
        {
          item.questionType == "Text" && <Dropdown
            style={{ paddingLeft: "4px", width: "135px" }}
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            clearable
            placeholder={t("quQuestion.validateType")}
            // label={t("quQuestion.validateType")}
            value={[{ value: item.validateType }]}
            onChange={e => {
              item.validateType = e && e.length > 0 ? e[0].value : undefined
              setQuestions([...questions])
            }}
          >
            {
              validateTypes.details.map((qt, idx) => {
                return <DropdownOption key={idx} label={qt.displayText} value={qt.code} />
              })
            }
          </Dropdown>
        }
        {
          item.questionType == "Text" && <NumericInput
            // discreet={true}
            style={{ paddingLeft: "4px", width: "140px" }}
            placeholder={t("quQuestion.minLength")}
            min={0}
            value={item.minLength}
            type='compact'
            sizeClass='small'
            prefix='最少'
            suffix='字'
            onChange={(newValue) => {
              item.minLength = newValue
              setQuestions([...questions])
            }}
          />
        }
        {
          item.questionType == "Text" && <NumericInput
            // discreet={true}
            style={{ paddingLeft: "4px", width: "140px" }}
            placeholder={t("quQuestion.maxLength")}
            min={0}
            value={item.maxLength}
            type='compact'
            sizeClass='small'
            prefix='最多'
            suffix='字'
            onChange={(newValue) => {
              item.maxLength = newValue
              setQuestions([...questions])
            }}
          />
        }
        {
          item.inputType == "Decimal" && <NumericInput
            // discreet={true}
            style={{ paddingLeft: "4px", width: "140px" }}
            placeholder={t("quQuestion.decimalDigits")}
            min={0}
            max={6}
            value={item.decimalDigits}
            type='compact'
            sizeClass='small'
            prefix='小数'
            suffix='位'
            onChange={(newValue) => {
              item.decimalDigits = newValue
              setQuestions([...questions])
            }}
          />
        }
        {/* {
          item.questionType == "Choice" && <Checkbox
            style={{ paddingLeft: "4px", width: "120px" }}
            label={t('quQuestion.isMultiple')}
            value={item.isMultiple}
            disabled={item.inputType != "Dropdown"}
            onChange={() => {
              item.isMultiple = !item.isMultiple
              setQuestions([...questions])
            }}
            sizeClass='small'
          />
        } */}
        <Checkbox
          style={{ paddingLeft: "4px", width: "120px" }}
          label={t('quQuestion.isRequired')}
          value={item.isRequired}
          onChange={() => {
            item.isRequired = !item.isRequired
            setQuestions([...questions])
          }}
          sizeClass='small'
        />
        <Dropdown
          style={{ paddingLeft: "4px", width: "120px" }}
          showValidationBarWhenInvalid
          showValidationIconWhenInvalid
          clearable
          placeholder={t("quQuestion.relevantInfo")}
          // label={t("quQuestion.relevantInfo")}
          value={[{ value: item.relevantInfo }]}
          onChange={e => {
            item.relevantInfo = e && e.length > 0 ? e[0].value : undefined
            setQuestions([...questions])
          }}
        >
          {
            relevantInfos.details.map((qt, idx) => {
              return <DropdownOption key={idx} label={qt.displayText} value={qt.code} />
            })
          }
        </Dropdown>
        {(item.inputType == "Score") &&
          <div style={{ display: "flex" }}>
            <Checkbox
              style={{ paddingLeft: "4px", width: "120px" }}
              label={t('quQuestion.isNeedFeedback')}
              value={item.isNeedFeedback}
              onChange={() => {
                item.isNeedFeedback = !item.isNeedFeedback
                if (item.isNeedFeedback) {
                  item.feedbackPlaceHolder = "请填写不满意反馈"
                } else {
                  item.feedbackPlaceHolder = ""
                }
                setQuestions([...questions])
              }}
              sizeClass='small'
            />
            {item.isNeedFeedback && <Input
              // prefix={index + 1 + "."}
              style={{ paddingLeft: "4px", width: "250px", }}
              sizeClass="small"
              dataType="text"
              maxLength={255}
              showValidationBarWhenInvalid
              showValidationIconWhenInvalid
              placeholder={t("quQuestion.feedbackPlaceHolder")}
              showClearIcon={true}
              value={item.feedbackPlaceHolder}
              onValueChange={(newValue) => {
                item.feedbackPlaceHolder = newValue
                setQuestions([...questions])
              }}
            />}
          </div>
        }
      </div>)
  }
  //渲染所有问题
  const renderQuestions = () => {
    const sortQuestions = getSortQuestions()
    return sortQuestions.map((item, index) => {
      return (
        <div key={index} style={{ backgroundColor: "#fff", borderRadius: "4px", padding: "8px", marginBottom: "12px" }}>
          {renderQuestionTools(item, index)}
          {renderQuestionContent(item, index)}
        </div>
      )
    })


  }
  //渲染问卷调查主数据
  const renderMain = () => {
    return (
      <div style={{ backgroundColor: "#fff", padding: "8px", borderRadius: "4px", }}>
        <div className="form-group">
          <Input
            sizeClass="small"
            dataType="text"
            maxLength={255}
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            placeholder={t("t_pleaseInput") + t("quQuestionnaire.title")}
            showClearIcon={true}
            label={t("quQuestionnaire.title")}
            value={main.title}
            onValueChange={(newValue) => {
              setMain({ ...main, title: newValue })
            }}
          />
        </div>
        <div className="form-group">
          <Input
            sizeClass="small"
            dataType="textarea"
            maxLength={1024}
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            placeholder={t("t_pleaseInput") + t("quQuestionnaire.description")}
            showClearIcon={true}
            label={t("quQuestionnaire.description")}
            value={main.description}
            onValueChange={(newValue) => {
              setMain({ ...main, description: newValue })
            }}
          />
        </div>
      </div>
    )
  }
  //渲染主工具栏
  const renderMainTools = () => {
    return (
      <div style={{ padding: "8px" }}>
        <Row>
          <Col flex="auto">
            <Button text='添加问题' type='discreet-blue' sizeClass="small" onClick={e => addQuestion()} />
            <Button text='清空问题' type='discreet-blue' sizeClass="small" onClick={e => clearQuestions()} />
            <Button text='整理排序' type='discreet-blue' sizeClass="small" onClick={e => resetQuestionsIndex()} />
          </Col>
          <Col flex="300px">
            <Button text='完成编辑' style={{ marginRight: "4px" }} type='primary-blue' sizeClass="small" onClick={e => save()} />
            <Button text='预览问卷' style={{ marginRight: "4px" }} type='primary-blue' sizeClass="small" onClick={e => preview()} />
            <Button text='取消编辑' type='normal' sizeClass="small" onClick={e => backToMain()} />
          </Col>
        </Row>
      </div>
    )
  }
  //回到问卷列表页
  const backToMain = () => {
    dispatch(setQuestionaireCache(null))
    history.push('/maindata/questionnaires')
  }
  const preview = () => {
    dispatch(setQuestionaireCache({ main, questions, options }))
    history.push('/maindata/questionnairePreview/' + main.id)
  }
  const scrollToBottom = () => {
    const div = document.getElementById('divQuestionnaires')
    div.scrollTop = div.scrollHeight
  }
  //初始化问题数据
  useEffect(() => {
    if (questionaireCache) {
      if (!isMounted()) return;
      setMain(JSON.parse(JSON.stringify(questionaireCache.main)))
      setQuestions(JSON.parse(JSON.stringify(questionaireCache.questions)))
      setOptions(JSON.parse(JSON.stringify(questionaireCache.options)))
    }
    else if (questionaireId) {
      getData(questionaireId)
    }
  }, [questionaireId, questionaireCache])
  //返回
  return (
    <div id="divQuestionnaires" className={'admin-main-content with-padding'} style={{ backgroundColor: "#f3f3f3" }}>
      <div>
        {renderMain()}
        {renderMainTools()}
        {renderQuestions()}
        <Button text='添加问题' type='discreet-blue' sizeClass="small" onClick={e => addQuestion()} />
      </div>
    </div>
  )
}
