import React, { useState, useEffect, useRef } from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { CommonList } from "components/LoongInCommon"
import { MainIndex } from "./MainIndex"
import { TypeFormEdit } from "./TypeFormEdit"
import axios from "axios"
import { t } from "i18next"
import {
  showNotification,
  NotificationType,
} from "components/Notification/notificationSlice"
import { useDispatch } from "react-redux"
import { CommonConfirm } from "components/LoongInCommon"
import {
  deleteWechatMenuGroup,
  getWechatMenuGroups,
  publishWechatMenus,
  pullWechatMenus,
} from "common/apis/wechat"
import { Col, Row } from "components/Grid"
import { Button, Icon, Tooltip, WithTooltip } from "@abb/abb-common-ux-react"
import { useMountedState } from "common/useMountedState"

export default () => {
  const { path } = useRouteMatch()
  const [list, setList] = useState(null)
  const [activeItem, setActiveItem] = useState([])
  const [publishItem, setPublishItem] = useState([])
  const [isLoadingList, setIsLoadingList] = useState(false)
  const typeFormRef = useRef(null)
  const [typeFormData, setTypeFormData] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState("")
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(null)
  const refList = useRef(null)
  const dispatch = useDispatch()
  const [typeFormEditEvent, setTypeFormEditEvent] = useState(null)
  const isMounted = useMountedState()

  const onTypeFormSumbit = () => {
    if (activDataIndex) {
      setActivDataIndex("")
      setTypeFormData(null)
      getAllList()
    } else {
      getAllList()
    }
  }
  const getAllList = () => {
    setIsLoadingList(true)
    axios
      .post(getWechatMenuGroups(), { pageSize: 10000 })
      .then((response) => {
        if (response.status == 200 && response.data) {
          const items = response.data.items.map((c) => {
            return {
              ...c,
              displayName: (
                <>
                  <Row>
                    <Col span="20">{c.name}</Col>
                    <>
                      {!c.published && (
                        <Col span="2">
                          <WithTooltip>
                            <div>
                              <Icon color="red" name="abb/disconnect"></Icon>
                            </div>
                            <Tooltip>
                              <div>未发布</div>
                            </Tooltip>
                          </WithTooltip>
                        </Col>
                      )}
                      {c.published && (
                        <Col span="2">
                          <WithTooltip>
                            <div>
                              <Icon color="blue" name="abb/connect"></Icon>{" "}
                            </div>
                            <Tooltip>
                              <div>已发布</div>
                            </Tooltip>
                          </WithTooltip>
                        </Col>
                      )}
                      {c.type === "1" && (
                        <Col span="2">
                          <WithTooltip>
                            <div>
                              <Icon color="blue" name="abb/template"></Icon>
                            </div>
                            <Tooltip>
                              <div>通用菜单</div>
                            </Tooltip>
                          </WithTooltip>
                        </Col>
                      )}
                      {c.type === "2" && (
                        <Col span="2">
                          <WithTooltip>
                            <div>
                              <Icon color="orange" name="abb/user"></Icon>
                            </div>
                            <Tooltip>
                              <div>个性化菜单</div>
                            </Tooltip>
                          </WithTooltip>
                        </Col>
                      )}
                    </>
                  </Row>
                </>
              ),
            }
          })
          setList(items)
          if (items.length > 0) {
            refList.current.setCurrentItem(items[0])
          } else setActiveItem({})
        } else {
          setList([])
        }
      })
      .finally(() => {
        setIsLoadingList(false)
      })
  }

  const publishMenu = (item) => {
    setIsLoadingList(true)
    axios
      .post(publishWechatMenus(), {
        groupId: item.id,
        action: item.published && item.type === "2" ? "0" : "1",
      })
      .then((e) => {
        if (!isMounted()) {
          return
        }
        dispatch(showNotification(NotificationType.Success, "同步微信菜单成功"))
        getAllList()
      })
      .catch((e) => {
        dispatch(
          showNotification(
            NotificationType.Alarm,
            e && e.error && e.error.message
              ? e.error.message
              : "同步微信菜单失败，请稍后重试或联系管理员！"
          )
        )
      })
      .finally(() => {
        setIsLoadingList(false)
      })
  }
  useEffect(() => {
    getAllList()
  }, [])
  const onItemChange = (item) => {
    setActiveItem(item)
  }
  const onSubmit = () => {
    getAllList()
  }
  //添加按钮
  const rightIcon = {
    name: "abb/plus",
    color: "blue",
    sizeClass: "medium",
    onClick: () => {
      setTypeFormData({})
      setActivDataIndex("")
      setTypeFormEditEvent({
        name: "setShowSidePanel",
        params: [true],
      })
    },
  }
  const myMenu = (item) => [
    {
      title: t("b_edit"),
      disabled: item.published,
      onClick: (item) => {
        setTypeFormData(item)
        setActivDataIndex(item.id)
        setTypeFormEditEvent({
          name: "setShowSidePanel",
          params: [true],
        })
      },
    },
    {
      title:
        item.type === "1"
          ? item.published
            ? "重新发布"
            : "发布"
          : item.published
          ? "撤回"
          : "发布",
      color: item.type === "2" && item.published ? "orange" : "blue",
      onClick: (item) => {
        // setPublishItem(item)
        publishMenu(item)
      },
    },
    {
      title: t("b_del"),
      color: "red",
      onClick: (item) => {
        setDeleteId(item.id)
      },
    },
  ]

  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(deleteWechatMenuGroup(), null, {
        params: { id: deleteId },
      })
      if (response.status === 200 || response.status === 204) {
        setDeleteId(null)
        getAllList()
        dispatch(showNotification(NotificationType.Success, t("t_hasDelete")))
      } else {
        dispatch(showNotification(NotificationType.Warn, t("t_deleteFail")))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t("t_deleteFail")))
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        padding: 8,
        boxSizing: "border-box",
      }}
      data-cy="admin-device-model"
    >
      <Route path={[`${path}/:id`, path]}>
        <CommonList
          style={{ margin: 0 }}
          ref={refList}
          data={list}
          keyName="id"
          filterText="name"
          displayName="displayName"
          isLoading={isLoadingList}
          onItemChange={onItemChange}
          rightIcon={rightIcon}
          contexMenu={myMenu}
        />
        <TypeFormEdit
          ref={typeFormRef}
          formData={typeFormData}
          onCancel={() => {
            setActivDataIndex("")
            setTypeFormData(null)
          }}
          id={activDataIndex}
          onSubmit={onTypeFormSumbit}
          event={typeFormEditEvent}
        />
        <MainIndex
          groupId={activeItem.id}
          groupName={activeItem.name}
          onSubmit={onSubmit}
        />
        <CommonConfirm
          title={t("t_titleDeleteData")}
          content={t("t_confirmDeleteData")}
          isVisible={deleteId != null}
          onConfirm={onDeleteConfirm}
          confirmText={t("b_confirm")}
          onCancel={() => setDeleteId(null)}
          isLoading={isDeleting}
        />
      </Route>
    </div>
  )
}
