import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon, Button } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { getUserPage, deleteUser, lockUser, syncUser, unSyncUser, syncAllUser } from 'common/endpoints'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/CommonConfirm"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { fetchGetUsersAsync } from 'slices/userSlice'
import { DropdownButton } from 'components/DropdownButton'

export default () => {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const dispatch = useDispatch()

  const refIndex = useRef(null)

  //列定义
  const columns = [
    {
      title: 'user.userName',
      key: 'userName',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.userName}</del>
              <Tooltip>
                <div>用户已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.userName
      },
    },
    {
      title: 'user.name',
      key: 'name',
    },
    {
      title: 'user.email',
      key: 'email',
    },
    {
      title: 'user.phoneNumber',
      key: 'phoneNumber',
      width: 120,
    },
    {
      title: 'user.isActive',
      key: 'isActive',
      width: 120,
      render: (item, index) => {
        if (item.isActive) return <span className='abb-tag abb-tag-green'>{t('t_yes')}</span>
        else return <span className='abb-tag abb-tag-red'>{t('t_no')}</span>
      },
    },
    {
      title: 'user.creationTime',
      key: 'creationTime',
      width: 145,
      render: (item, index) => {
        return <span>{moment(item.creationTime).format('YYYY-MM-DD HH:mm')}</span>
      },
    },
  ]
  //其他菜单
  const otherMenu = (item, index) => {
    return [
      {
        disabled: userProfile.userName === item.userName,
        onClick: () => changeActive(item),
        color: item.isActive ? 'red' : 'blue',
        title: item.isActive ? t('b_disable') : t('b_enable'),
      },
      {
        onClick: () => onSyncUser([item]),
        title: '同步用户',
      },
      {
        onClick: () => onUnSyncUser([item]),
        title: '复位用户',
      },
    ]
  }
  //修改用户状态
  const changeActive = (item) => {
    setIsOperating(true)
    const payload = {
      userId: item.id,
      locked: !item.isActive,
    }
    axios
      .post(lockUser(), payload)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToSet'))) //`设置成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        dispatch(showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : `用户信息更新失败`))
      })
      .finally(() => {
        setIsOperating(false)
      })
  }

  //修改用户状态
  const onSyncUser = (items) => {
    setIsOperating(true)
    const payload = {
      users: items.map((i) => i.id),
    }
    axios
      .post(syncUser(), payload)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToSet'))) //`设置成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        dispatch(showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : `用户同步失败`))
      })
      .finally(() => {
        setIsOperating(false)
        setCheckItems([])
      })
  }

  //修改用户状态
  const onSyncAllUser = (items) => {
    setIsOperating(true)
    axios
      .post(syncAllUser(), {})
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToSet'))) //`设置成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        dispatch(showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : `用户同步失败`))
      })
      .finally(() => {
        setIsOperating(false)
        setCheckItems([])
      })
  }
  //修改用户状态
  const onUnSyncUser = (items) => {
    setIsOperating(true)
    const payload = {
      users: items.map((i) => i.id),
    }
    axios
      .post(unSyncUser(), payload)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToSet'))) //`设置成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        dispatch(showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : `用户同步失败`))
      })
      .finally(() => {
        setIsOperating(false)
        setCheckItems([])
      })
  }
  //删除时放入删除项
  const onDeleteData = (item) => {
    dispatch(fetchGetUsersAsync())
    setDeletedItems([...deletedItems, item.id])
  }
  const onSelectionChange = (items) => {
    // console.log(items)
    setCheckItems(items)
  }
  const toolChildren = (
    <>
      <DropdownButton disabled={!checkItems || checkItems.length <= 0} title={'批量同步'} icon={'abb/synchronize'}>
        <Button
          style={{ width: '100%' }}
          icon={'abb/synchronize'}
          text='同步选中用户'
          isLoading={isOperating}
          type='ghost'
          sizeClass='small'
          onClick={() => onSyncUser(checkItems)}
        />
        <Button
          text='复位选中用户'
          style={{ width: '100%' }}
          icon={'abb/reset-user-data'}
          isLoading={isOperating}
          type='ghost'
          sizeClass='small'
          onClick={() => onUnSyncUser(checkItems)}
        />{' '}
      </DropdownButton>
      <Button
        style={{ marginLeft: 8 }}
        text='全部同步'
        isLoading={isOperating}
        type='normal'
        sizeClass='small'
        icon={'abb/upload'}
        onClick={onSyncAllUser}
      />
    </>
  )
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getUserPage}
        deleteData={deleteUser}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        toolChildren={toolChildren}
        canAdd
        canEdit
        canDelete
        canSelection //是否开启选择功能
        canBatchDel //是否可以批量删除
        onDeleteData={onDeleteData}
        onSubmit={() => {
          dispatch(fetchGetUsersAsync())
        }}
        onSelectionChange={onSelectionChange}
        refreshAfterDelete={false}
        enableSorting={true}
      />
    </>
  )
}
