import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule, CommonForm } from "components/LoongInCommon"
import { useDispatch, useSelector } from "react-redux"
import { saveQuestionnaires } from "common/apis/questionnaire"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getDictionary } from "slices/dataDictionarySlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { getUsers } from "slices/userSlice"
import { dateFormat, treeMap } from "common/utils"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"
export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event, disabled }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const adminFormEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [rules, setRules] = useState({})
  const dictionary = useSelector(getDictionary)
  const srvArea = getDataDictionaryDetail(dictionary, "srvArea")
  const [innerData, setInnerData] = useState({})

  const questionnaireCategory = getDataDictionaryDetail(dictionary, 'QuestionnaireCategory')
  const questionnaireStatus = getDataDictionaryDetail(dictionary, 'QuestionnaireStatus')
  const quGroup = getDataDictionaryDetail(dictionary, 'QuGroup')
  const users = useSelector(getUsers)

  useEffect(() => {
    setInnerData(formData)
  }, [formData])

  const onChange = (data, preData) => {
    setInnerData(data)
  }
  const onChangeBefore = (data) => {
    if(data.category=='Perennial'){
      data.startTime = null
      data.finishedTime = null
    }
  }

  //初始化数据
  const initData = {
    "title": "",
    "category": "",
    "description": "",
    "startTime": null,
    "finishedTime": null,
    "status": "Draft",
    "maxScore": 0,
    "issuer": null,
    "issueTime": null,
    "group": "",
  }

  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("quQuestionnaire.title"),
      fieldName: "title",
      maxLength: 255,
    },
    {
      type: "text",
      dataType: "textarea",
      label: t("quQuestionnaire.description"),
      fieldName: "description",
      maxLength: 1024,
    },
    {
      type: "dropdown",
      label: t("quQuestionnaire.category"),
      fieldName: "category",
      searchable: true,
      options: questionnaireCategory
        ? questionnaireCategory.details.map((c) => {
          return { label: c.displayText, value: c.code }
        })
        : [],
    },
    {
      type: "date",
      label: t("quQuestionnaire.startTime"),
      fieldName: "startTime",
      dateFormat: "yyyy-MM-dd HH:mm",
      showClearIcon: true,
      showTimeSelect: true,
      visible: innerData?.category!='Perennial'
    },
    {
      type: "date",
      label: t("quQuestionnaire.finishedTime"),
      fieldName: "finishedTime",
      dateFormat: "yyyy-MM-dd HH:mm",
      // disabled: true,
      showClearIcon: true,
      showTimeSelect: true,
      visible: innerData?.category!='Perennial'
    },
    {
      type: "text",
      dataType: "textarea",
      label: t("quQuestionnaire.email"),
      fieldName: "email",
      maxLength: 2048,
    },
    {
      type: "dropdown",
      label: t("quQuestionnaire.group"),
      fieldName: "group",
      searchable: true,
      options: quGroup
        ? quGroup.details.map((c) => {
          return { label: c.displayText, value: c.code }
        })
        : [],
    },
    {
      type: "text",
      dataType: "textarea",
      label: t("quQuestionnaire.sumbitRemindMessage"),
      fieldName: "sumbitRemindMessage",
      maxLength: 500,
    },
    {
      type: "checkbox",
      label: t("quQuestionnaire.noNeedLogin"),
      fieldName: "noNeedLogin",
    },
    {
      type: "dropdown",
      label: t("quQuestionnaire.status"),
      fieldName: "status",
      disabled: true,
      options: questionnaireStatus
        ? questionnaireStatus.details.map((c) => {
          return { label: c.displayText, value: c.code }
        })
        : [],
    },
    {
      type: "dropdown",
      label: t("quQuestionnaire.issuer"),
      fieldName: "issuer",
      disabled: true,
      options: users
        ? users.map((c) => {
          return { label: c.name, value: c.id }
        })
        : [],
    },
    {
      type: "date",
      label: t("quQuestionnaire.issueTime"),
      fieldName: "issueTime",
      dateFormat: "yyyy-MM-dd HH:mm:ss",
      disabled: true,
      showClearIcon: false,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      title: [CommonRule.Require],
      // email: [CommonRule.Email]
    }
    setRules(newrules)
  }, [id])

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
   //开始时间
   let startTime = inputdata.startTime
   if (startTime) {
     startTime = dateFormat(new Date(startTime), 'yyyy-MM-ddTHH:mm:ss+08:00')
     inputdata.startTime = startTime
   }
   //结束时间
   let finishedTime = inputdata.finishedTime
   if (finishedTime) {
     finishedTime = dateFormat(new Date(finishedTime), 'yyyy-MM-ddTHH:mm:ss+08:00')
     inputdata.finishedTime = finishedTime
   }
    return { main: inputdata }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    //开始时间
    let startTime = inputdata.startTime
    if (startTime) {
      startTime = dateFormat(new Date(startTime), 'yyyy-MM-ddTHH:mm:ss+08:00')
      inputdata.startTime = startTime
    }
    //结束时间
    let finishedTime = inputdata.finishedTime
    if (finishedTime) {
      finishedTime = dateFormat(new Date(finishedTime), 'yyyy-MM-ddTHH:mm:ss+08:00')
      inputdata.finishedTime = finishedTime
    }
    return { main: inputdata }
  }


  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("quQuestionnaire.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={saveQuestionnaires}
      updateData={saveQuestionnaires}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onChangeBefore={onChangeBefore}
      onChange={onChange}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
      disabled={disabled}
    >
    </CommonFormEdit>
  )
})
