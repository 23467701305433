import React, { useState } from 'react'
import { Pagination, Table, LoadingIndicator, Icon, Checkbox, Button } from '@abb/abb-common-ux-react'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export const CommonTable = ({
  children, //never
  isLoading, //正在加载
  columns, //列定义(width，align，title，key) align:left 左对齐、right 右对齐和 center 居中对齐
  data, //数据
  zebra, //斑马纹
  sizeClass, //尺寸()
  scrollableBody, //是否滚动
  height, //高度
  width, //宽度
  checkedItems, //["asdasf","asdfasfd"]
  keyField, //主键，默认id
  autoI18n, //是否自动i18n，默认true
  canShow, //是否可以查看
  onShow, //点击查看时
  canEdit, //是否可编辑
  noDataText,
  onEdit, //点击编辑时
  canDelete, //是否可删除
  onDelete, //点击删除时
  showPage, //是否显示分页
  onGotoPage, //跳转分页时
  onSetPageSize,
  rowCount, //行数
  currentPage, //当前页码
  currentPageSize, //当前页行数
  otherMenu, //其他菜单
  enableSorting, //开启排序功能
  onSort, //排序事件
  actionRender, //自定义操作列
  canSelection, //是否开启选择功能
  onSelectionChange, //选择改变时触发
  multipleChoice, //是否开启多选，默认是多选
  checkCanDelete, //检查当前行是否可删除
  checkCanEdit, //检查当前行是否可编辑
  checkCanShow,//检查当前行是否可查看
}) => {
  const { t } = useTranslation()
  const [sortState, setSortState] = useState({ key: '', sortUp: true })
  const [checkAll, setCheckAll] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const [shrinkItems, setShrinkItems] = useState([])
  const [expandItems, setExpandItems] = useState([])
  const changeSortState = (key) => {
    if (!enableSorting) return
    let newSortState = { ...sortState }
    if (newSortState.key == key) {
      newSortState.sortUp = !newSortState.sortUp
    } else {
      newSortState.key = key
      newSortState.sortUp = true
    }
    setSortState(newSortState)
    onSort(newSortState)
  }
  useEffect(() => {
    outsetCheckItems()
  }, [checkedItems, data])
  const outsetCheckItems = () => {
    if (checkedItems && checkedItems.length > 0 && data && data.length > 0) {
      let newCheckItems = []
      checkedItems.forEach((item) => {
        let obj = data.find((c) => c[keyField] === item)
        if (obj) {
          newCheckItems.push(obj)
        }
      })
      setCheckItems(newCheckItems)
    } else {
      setCheckItems([])
    }
  }
  const changeCheckAll = (val) => {
    setCheckAll(val)
    let newCheckItems = []
    if (val) {
      data.forEach((c) => {
        newCheckItems.push(c)
      })
    }
    setCheckItems(newCheckItems)
    onSelectionChange && onSelectionChange(newCheckItems)
  }
  const changeCheckItems = (item, newValue) => {
    //是否多选
    let multiplechoice = multipleChoice === undefined || multipleChoice
    //多选则清除
    let newCheckItems = multiplechoice ? [...checkItems] : []
    let findIdx = newCheckItems.findIndex((c) => c[keyField] == item[keyField])
    if (newValue && findIdx < 0) {
      newCheckItems.push(item)
    } else if (!newValue && findIdx >= 0) {
      newCheckItems.splice(findIdx, 1)
    }
    setCheckItems(newCheckItems)
    onSelectionChange && onSelectionChange(newCheckItems)
  }

  useEffect(() => {
    // setCheckItems([])
    setCheckAll(false)
  }, [data])

  sizeClass = sizeClass || 'small'
  scrollableBody = scrollableBody || true
  // height = height || "100%"
  keyField = keyField || 'id'
  autoI18n = autoI18n == undefined ? true : autoI18n
  if (showPage) {
    onGotoPage = onGotoPage || ((v) => { })
    onSetPageSize = onSetPageSize || ((s, p) => { })
    rowCount = rowCount || 0
    currentPage = currentPage || 1
    currentPageSize = currentPageSize || 10
  }
  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         position: "absolute",
  //         top: "200px",
  //         left: "50%",
  //         transform: "translateX(-50%) translateY(-50%)",
  //         zIndex: 5,
  //       }}
  //     >
  //       <LoadingIndicator type="radial" sizeClass="medium" determinate={false} color="blue" />
  //     </div>
  //   )
  // }
  const changeShrinkItems = (id) => {
    let newshrinkItems = [...shrinkItems]
    let findIdx = newshrinkItems.findIndex((c) => c == id)
    if (findIdx >= 0) {
      newshrinkItems.splice(findIdx, 1)
    } else {
      newshrinkItems.push(id)
    }
    setShrinkItems(newshrinkItems)
  }
  const isShrinkItems = (id) => {
    let findIdx = shrinkItems.findIndex((c) => c == id)
    return findIdx >= 0
  }
  const changeExpandItems = (id) => {
    let newItems = [...expandItems]
    let findIdx = newItems.findIndex((c) => c == id)
    if (findIdx >= 0) {
      newItems.splice(findIdx, 1)
    } else {
      newItems.push(id)
    }

    setExpandItems(newItems)
  }
  const isExpandItems = (id) => {
    let findIdx = expandItems.findIndex((c) => c == id)
    return findIdx >= 0
  }
  const hasExpandCol = (item) => {
    return columns?.findIndex((c) => (!!c.expand) && c.expand(item)) >= 0
  }
  const hasTreeCol = () => {
    return columns?.findIndex((c) => c.isTree !== undefined && c.isTree === true) >= 0
  }
  const getTrTree = (item, index, level) => {
    return (
      <React.Fragment key={level + '.' + index}>
        {getTr(item, index, level)}
        {hasExpandCol(item) && isExpandItems(item[keyField]) && getTrExpand(item, index, columns[0].expand)}
        {hasTreeCol() && isShrinkItems(item[keyField]) && item.children?.map((itm, idx) => getTrTree(itm, idx, level + 1))}
      </React.Fragment>
    )
  }
  const getTrExpand = (item, index, expand) => {
    return (
      <tr>
        <td colSpan={getColSpan()} style={{ backgroundColor: '#efefef', overflow: 'visible' }}>
          {expand && expand(item, index)}
        </td>
      </tr>
    )
  }
  const menuCount = (canShow ? 1 : 0) + (canEdit ? 1 : 0) + (canDelete ? 1 : 0) + (otherMenu ? otherMenu.length : 0)
  const renderOtherMenus = (item, index) => {
    const options = []
    canShow && (!checkCanShow || checkCanShow(item, index)) && options.push((
      <DropdownButtonOption
        key='ddb_3'
        onClick={() => {
          onShow(item, index)
        }}
      >
        {t('b_show')}
      </DropdownButtonOption>
    ))
    canEdit && (!checkCanEdit || checkCanEdit(item, index)) && options.push(
      <DropdownButtonOption
        key='ddb_1'
        onClick={() => {
          onEdit(item, index)
        }}
      >
        {t('b_edit')}
      </DropdownButtonOption>
    )
    const othermenus = otherMenu && otherMenu(item, index)?.map((m, midx) => {
      return (
        <DropdownButtonOption key={'ddbot_' + midx} disabled={m.disabled} onClick={m.onClick}>
          <span style={{ color: m.color }}>{m.title}</span>
        </DropdownButtonOption>
      )
    })
    if(othermenus && othermenus.length>0) options.push(othermenus)
    const deletmenu = canDelete && (!checkCanDelete || checkCanDelete(item, index)) && (
      <DropdownButtonOption key='ddb_2' onClick={() => onDelete(item, index)}>
        <span style={{ color: 'red' }}>{t('b_del')}</span>
      </DropdownButtonOption>
    )
    if(deletmenu) options.push(deletmenu)
    if (options.length > 0) {
      return (
        <DropdownButton>
          {options}
        </DropdownButton>
      )
    }
  }
  const getTr = (item, index, level) => {
    return (
      <tr key={level + '.' + index}>
        {canSelection && (
          <td style={{ width: '60px', borderRightColor: '#eee', position: 'sticky', zIndex: 1, left: -1 }}>
            <Checkbox
              sizeClass='small'
              data={!checkItems.find((c) => c[keyField] == item[keyField])}
              label=''
              value={!!checkItems.find((c) => c[keyField] == item[keyField])}
              onChange={(newValue) => {
                changeCheckItems(item, newValue)
              }}
            />
          </td>
        )}
        {columns.map((col, colIndex) => {
          if (!col) return
          let style = col.style !== undefined ? { ...col.style } : {}
          if (col.width) {
            if ((col.width + '').indexOf('px') < 0) {
              style.width = col.width + 'px'
            } else {
              style.width = col.width
            }
          }

          if (col.align) style.textAlign = col.align
          if (col.wrap) {
            style.whiteSpace = 'pre-wrap'
            style.overflowWrap = 'break-word'
          } else if (col.ellipsis) {
            style.textOverflow = 'ellipsis'
            style.overflow = 'hidden'
            style.whiteSpace = 'nowrap'
          }
          if (col.isTree) {
            style.paddingLeft = 36 * level + 'px'
          }
          let tdStyle = {}
          if (col.fixed) {
            tdStyle = { zIndex: 3, position: 'sticky', padding: '4px 16px' }
            if (col.fixed == 'left') {
              tdStyle.left = col.left || 0
              tdStyle.borderRightColor = '#eee'
            } else {
              tdStyle.right = col.right || 0
              tdStyle.borderLeftColor = '#eee'
            }
          }
          return (
            <td key={level + '.' + index + '.' + colIndex} style={tdStyle}>
              <div style={style}>
                {col.isTree && item.children && item.children.length > 0 && (
                  <Icon
                    onClick={() => {
                      changeShrinkItems(item[keyField])
                    }}
                    style={{ marginRight: '4px', cursor: 'pointer' }}
                    name={!isShrinkItems(item[keyField]) ? 'abb/caret-right' : 'abb/caret-down'}
                    sizeClass='small'
                  />
                )}
                {col.expand && col.expand(item) && (
                  <Icon
                    onClick={() => {
                      changeExpandItems(item[keyField])
                    }}
                    style={{ marginRight: '4px', cursor: 'pointer' }}
                    name={!isExpandItems(item[keyField]) ? 'abb/caret-right' : 'abb/caret-down'}
                    sizeClass='small'
                  />
                )}
                {col.render && col.render(item, index)}
                {!col.render && (col.key === undefined ? '' : item[col.key]?.toString())}
              </div>
            </td>
          )
        })}
        {actionRender && (
          <td
            style={{
              textAlign: 'center',
              overflow: 'visible',
              paddingLeft: '4px',
              paddingRight: '4px',
              marginLeft: '2px',
              borderLeft: '2px solid #eee',
              right: 0,
              width: '60px',
            }}
          >
            {actionRender(item, index)}
          </td>
        )}
        {!actionRender && menuCount > 0 && (
          <td
            style={{
              textAlign: 'center',
              overflow: 'visible',
              paddingLeft: '4px',
              paddingRight: '4px',
              marginLeft: '2px',
              borderLeft: '2px solid #eee',
              right: 0,
              width: '60px',
            }}
          >
            {menuCount == 1 && canShow && (
              <Button
                text={t('b_show')}
                type={'ghost'}
                sizeClass='small'
                icon={'abb/zoom-in'}
                onClick={() => {
                  onShow(item, index)
                }}
              />
            )}
            {menuCount == 1 && canEdit && (
              <Button
                // style={{ color: "blue" }}
                text={t('b_edit')}
                type={'ghost'}
                sizeClass='small'
                icon={'abb/edit'}
                onClick={() => {
                  onEdit(item, index)
                }}
              />
            )}
            {menuCount == 1 && canDelete && (
              <Button
                style={{ color: 'red' }}
                text={t('b_del')}
                type={'ghost'}
                sizeClass='small'
                icon={'abb/close'}
                onClick={() => {
                  onDelete(item, index)
                }}
              />
            )}
            {menuCount > 1 && renderOtherMenus(item, index)}
          </td>
        )}
      </tr>
    )
  }

  if (!columns) return <div>please set columns</div>

  let left = 0
  if (canSelection) left += 65
  for (let i = 0; i < columns.length; i++) {
    const col = columns[i]
    columns[i].left = left
    left += col.width + 33 || 0
  }
  let right = 0
  if (menuCount == 1 || !!actionRender) right += 64
  else if (menuCount > 1) right += 32
  for (let i = columns.length - 1; i >= 0; i--) {
    const col = columns[i]
    columns[i].right = right
    right += col.width + 33 || 0
  }
  const getColSpan = () => {
    return columns.length + (!!actionRender || menuCount > 0 ? 1 : 0) + (canSelection ? 1 : 0)
  }
  // console.log(columns)
  return (
    <>
      <div className='margins borders padding' style={{ overflow: 'visible', minHeight: height ? height + 'px' : undefined }}>
        <div
          style={{
            height: '100%',
            width: width ? width + 'px' : undefined,
            overflow: 'visible',
          }}
        >
          <Table
            className='ABB_CommonUX_Table__scrollableBody'
            sizeClass={sizeClass}
            solidBackground={true}
            borderType={'normal'}
            zebra={zebra}
            scrollableBody={true}
          >
            <thead>
              <tr>
                {canSelection && (
                  <th style={{ padding: '4px 16px', position: 'sticky', borderBottom: '1px solid #000', zIndex: 4, left: 0 }}>
                    <div style={{ width: '33px' }}>
                      {(multipleChoice == undefined || multipleChoice === true) && (
                        <Checkbox
                          sizeClass='small'
                          data={!checkAll}
                          label=''
                          value={checkAll}
                          onChange={(newValue) => {
                            changeCheckAll(newValue)
                          }}
                        />
                      )}
                    </div>
                  </th>
                )}
                {columns.map((col, colIndex) => {
                  let style = { zIndex: 3, borderBottom: '1px solid #000', padding: '4px 16px' }
                  if (col.width) {
                    if ((col.width + '').indexOf('px') < 0) {
                      style.width = col.width + 'px'
                    } else {
                      style.width = col.width
                    }
                  }
                  if (col.align) style.textAlign = col.align
                  if (col.fixed) {
                    style.zIndex = 4
                    style.position = 'sticky'
                    if (col.fixed == 'left') {
                      style.left = col.left || 0
                      style.borderRightColor = '#eee'
                    } else {
                      style.right = col.right || 0
                      style.borderLeftColor = '#eee'
                    }
                  }
                  if ((enableSorting && col.enableSorting == undefined) || col.enableSorting === true) {
                    style.cursor = 'pointer'
                    return (
                      <th style={style} key={colIndex} onClick={() => changeSortState(col.key)}>
                        <div style={{ width: col.width ? col.width + 1 : undefined }}>
                          <span>{autoI18n ? t(col.title) : col.title}</span>
                        </div>
                        {((enableSorting && col.enableSorting == undefined) || col.enableSorting === true) && col.key == sortState.key && (
                          <div style={{ display: 'float', right: 0, top: '12px', position: 'absolute' }}>
                            <Icon name={sortState.sortUp ? 'abb/triangle-up' : 'abb/triangle-down'} sizeClass={sizeClass} />
                          </div>
                        )}
                      </th>
                    )
                  } else {
                    return (
                      <th style={style} key={colIndex}>
                        <div style={{ width: col.width ? col.width + 1 : undefined }}>
                          <span>{autoI18n ? t(col.title) : col.title}</span>
                        </div>
                      </th>
                    )
                  }
                })}
                {(menuCount > 0 || !!actionRender) && (
                  <th
                    style={{
                      width: '60px',
                      textAlign: 'center',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                      zIndex: 3,
                      borderBottom: '1px solid #000',
                      borderLeftColor: '#eee',
                      right: 0,
                    }}
                  >
                    {t('t_operate')}
                  </th>
                )}
              </tr>
            </thead>
            <tbody style={{ borderBottom: '0', borderTop: '0' }}>
              {data && data.length > 0 && data.map((item, index) => getTrTree(item, index, 0))}
              {(!data || data.length === 0) && (
                <tr>
                  <td colSpan={getColSpan()}>
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        margin: '20px',
                      }}
                    >
                      {noDataText ?? t('t_noData')}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: '200px',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                zIndex: 9999,
              }}
            >
              <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
            </div>
          )}
        </div>
      </div>
      {showPage && data && data.length > 0 && (
        <Pagination
          style={{ marginTop: 8 }}
          onGotoPage={(v) => onGotoPage(v)}
          onSetPageSize={onSetPageSize}
          pageSizeOptions={[5, 10, 20, 30, 50]}
          rowCount={rowCount}
          currentPage={currentPage - 1}
          currentPageSize={currentPageSize}
          texts={{
            Showing: t('t_showing'),
            RowsPerPage: t('t_rowsPerPage'),
            Page: t('t_page'),
            GoToPage: t('t_goToPage'),
          }}
        />
      )}
    </>
  )
}
