import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon, Menu, MenuItem, MenuItemDivider, Button, Tag  } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { exportProject, getProjectPage } from 'common/apis/ezreport'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import moment from 'moment'
import { getCustomers, fetchGetCustomersAsync } from 'slices/maindataSlice'
import { getDictionary } from 'slices/dataDictionarySlice'
import { getUsers } from 'slices/userSlice'
import { getDataDictionaryDetail } from 'common/dataDictionary'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Project from './Project'

import { t } from 'i18next'
import { getEnv } from 'slices/envSlice'

export default function Projects() {
  const history = useHistory()

  const userProfile = useSelector(getUserProfile)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const [columns, setColumns] = useState([])
  const dispatch = useDispatch()
  const [searchData, setSearchData] = useState({})
  const env = useSelector(getEnv)

  const customers = useSelector(getCustomers)
  const dictionary = useSelector(getDictionary)
  const dt = getDataDictionaryDetail(dictionary, 'PrjType')
  const users = useSelector(getUsers)
  const refIndex = useRef(null)
  useEffect(() => {
    dispatch(fetchGetCustomersAsync())
  }, [])
  useEffect(() => {
    if (customers && dictionary && users) {
      changeColumns()
      console.log(customers)
    }
  }, [customers, dictionary, users])

  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showDetail(item),
        title: t('t_detail'),
      },
      {
        onClick: () => showStations(item),
        title: '变电站',
      },
    ]
  }
  const onSearchDataChange = (newData, oldData) => {
    setSearchData(newData)
  }
  const searchItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('t_keyword'),
      fieldName: 'filter',
      showClearIcon: true,
    },
    {
      type: 'date',
      label: t('t_dateBeg'),
      fieldName: 'startDate',
      maxDate: searchData.endDate,
      dateFormat: 'yyyy-MM-dd',
      showClearIcon: true,
    },
    {
      type: 'date',
      label: t('t_dateEnd'),
      fieldName: 'endDate',
      minDate: searchData.startDate,
      dateFormat: 'yyyy-MM-dd',
      showClearIcon: true,
    },
    {
      type: 'dropdown',
      label: t('project.manager'),
      searchable:true,
      fieldName: 'manager',
      multiselect:true,
      showClearIcon: true,
      options: users.map((u) => {
        return { value: u.id, label: u.name }
      }),
    },
  ]
  //打开权限树
  const showDetail = (item) => {
    history.push('/evaluation/projects/' + item.id)
  }
  const showStations = (item) => {
    history.push('/evaluation/projects/station/' + item.id)
  }
  //列定义
  const changeColumns = () => {
    setColumns([
      {
        title: 'project.no',
        key: 'no',
      },
      {
        title: 'project.name',
        key: 'name',
      },
      {
        title: 'project.customer',
        key: 'customerId',
        render: (item, index) => {
          const customer = customers.find((c) => c.id == item.customerId)
          return customer ? customer.name : ''
        },
      },
      {
        title: 'project.category',
        key: 'category',
        render: (item, index) => {
          const category = dt?.details.find((d) => d.code == item.category)
          return category?.displayText
        },
      },
      {
        title: 'project.manager',
        key: 'manager',
        render: (item, index) => {
          const category = users.find((d) => d.id == item.manager)
          return category?.name
        },
      },
      {
        title: '项目状态',
        key: 'isDone',
        align: 'right',
        enableSorting:false,
        render: (item, index) => {
          return item.isDone ? <Tag type='success'>已完成</Tag> : <Tag type='warn'>进行中</Tag>
        },
      },   {
        title: 'project.startDate',
        key: 'startDate',
        render: (item) => {
          return moment(item.startDate).format('yyyy-MM-DD')
        },
      },
      {
        title: 'project.endDate',
        key: 'endDate',
        render: (item) => {
          return moment(item.endDate).format('yyyy-MM-DD')
        },
      },
    ])
  }

  const onExport = (item) => {
    const params = { ...searchData,type: '8', scope: item.id }
    window.location.href=`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${exportProject(params)}`
  }
  const getGetListInput = (params) => {
    return {...params, type: '8' }
  }

  const toolChildren = (
    <>
      <Button icon='abb/export' text='导出' onClick={() => setIsOperating(!isOperating)}></Button>
      <Menu
        alignToParent='right'
        onSelect={(item) => {
          onExport(item)
          setIsOperating(!isOperating)
        }}
        isOpen={isOperating}
        trigger='click'
      >
        <MenuItem text='按项目导出' itemId='project' icon='abb/briefcase' />
        <MenuItemDivider />
        <MenuItem text='按站点导出' itemId='station' icon='abb/transformer' />
      </Menu>
    </>
  )
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        getGetListInput={getGetListInput}
        toolChildren={toolChildren}
        searchItem={searchItem}
        searchData={searchData}
        onSearchDataChange={onSearchDataChange}
        columns={columns}
        getList={getProjectPage}
        otherMenu={otherMenu}
        enableSorting={true}
        enableQueryOnSearchDataChange={false}
        />
    </>
  )
}
