import { AES, enc } from 'crypto-js'
import { useDispatch } from 'react-redux'
import { useContext } from 'react'
import { UserContext } from 'contexts/UserContext'
const key = 'jPYpVn_se*$EqKDA~TbIvSqo$Cb0HPfoSxVvWUN6O12#JVStwR5m*vwugsPi8n!pEYgjbfUGkh*)KM_t~&UXbu+nH2W0w9'
const name = 'SEA:backend:user'

export const useCurrentUser = () => {
  const id = useContext(UserContext).user
  return {
    getUser: () => get(id),
    setUser: (user) => set(user, id),
    clear: () => clear(),
  }
}

const get = (id) => {
  const item = localStorage.getItem(name)
  if (!item) {
    return null
  }
  // console.log(AES.decrypt(item, id.toString()))
  try {
    const str = AES.decrypt(item, id.toString())?.toString(enc.Utf8)
    if (!str) {
      return null
    }
    return JSON.parse(str)
  } catch (error) {
    return null
  }
}

const set = (user, id) => {
  if (!user) clear()
  else {
    const str = JSON.stringify(user)
    const r = AES.encrypt(str, id.toString()).toString()
    localStorage.setItem(name, r)
  }
}

const clear = () => {
  localStorage.removeItem(name)
}
