import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGetDataDictionaryAsync } from 'slices/dataDictionarySlice'
import { fetchGetUsersAsync } from 'slices/userSlice'
import { fetchAbpApplicationConfigurationAsync } from 'slices/abpApplicationConfigurationSlice'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { getEnv } from 'slices/envSlice'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import { BrowserAuthError, EventType, InteractionRequiredAuthError, InteractionStatus, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useAzureAdB2CConfig } from 'hooks/useAzureAdB2CConfig'

const BlockPageLoading = () => (
  <div className='loadingindicator-central-wrapper'>
    <LoadingIndicator type='radial' sizeClass='small' determinate={false} color='blue' />
  </div>
)

export default function AuthB2C({ children }) {
  const env = useSelector(getEnv)
  return env.authType === 'AzureB2C' ? <Auth>{children}</Auth> : children
}

function Auth({ children }) {
  const [initialized, setInitialized] = useState(false)
  const [msalInstance, setMsalInstance] = useState(null)
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const env = useSelector(getEnv)
  const config = useAzureAdB2CConfig()
  const user = currentUser.getUser()
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  const msie11 = ua.indexOf('Trident/')
  const msedge = ua.indexOf('Edge/')
  const firefox = ua.indexOf('Firefox')
  const isIE = msie > 0 || msie11 > 0
  const isEdge = msedge > 0
  const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito
  useEffect(() => {
    if (env.initialized) {
      const msalConfig = {
        auth: {
          clientId: config.clientId,
          authority: config.authority ?? `${config.instance}/tfp/${config.domain}/${config.signUpSignInPolicyId}`,
          redirectUri: config.redirectUri,
          knownAuthorities: [config.instance],
          validateAuthority: true,
        },
        cache: {
          cacheLocation: 'localStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: isIE || isEdge || isFirefox,
        },system: {
          iframeHashTimeout: 10000,
        },
      }
      const msalInstance = new PublicClientApplication(msalConfig)
      msalInstance.initialize().then(() => {
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
        }
      })
      msalInstance.enableAccountStorageEvents()
      msalInstance.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS
        ) {
          const account = event.payload.account
          msalInstance.setActiveAccount(account)
        }
      })

      setMsalInstance(msalInstance)
    }
  }, [env.initialized])

  useEffect(() => {
    if (user) {
      if (!initialized && user.result.succeeded) {
        dispatch(fetchGetDataDictionaryAsync())
        dispatch(fetchGetUsersAsync())
        dispatch(fetchAbpApplicationConfigurationAsync())
        setInitialized(true)
      }
    }
  }, [user, initialized])

  return msalInstance ? (
    <MsalProvider instance={msalInstance}>
      <AuthContent>{children}</AuthContent>
    </MsalProvider>
  ) : (
    <BlockPageLoading />
  )
}

const AuthContent = ({ children }) => {
  const env = useSelector(getEnv)
  const isAuthencated = useIsAuthenticated()
  const config = useAzureAdB2CConfig()
  const msal = useMsal()
  const currentUser = useCurrentUser()
  const user = currentUser.getUser()
  const loginRequest = { scopes: config.scopes }

  const getAccessToken = (loginRequest) => {
    return msal.instance
      .acquireTokenSilent(loginRequest)
      .then((response) => {
        if (!response.accessToken || response.accessToken === '') {
          throw new InteractionRequiredAuthError()
        }
        return response
      })
      .catch((error) => {
        console.log('Silent token acquisition fails. Acquiring token using popup. \n', error)
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
          // fallback to interaction when silent call fails
          return msal.instance
            .acquireTokenRedirect(loginRequest)
            .then((response) => {
              console.log(response)
              return response
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          console.log(error)
        }
      })
  }

  useEffect(() => {
    if (!isAuthencated && msal.inProgress === InteractionStatus.None) {
      msal.instance.loginRedirect(loginRequest)
    }
    if (isAuthencated && msal.inProgress === InteractionStatus.None && !user) {
      getAccessToken({
        ...loginRequest,
        forceRefresh: true,
        account: msal.instance.getActiveAccount(),
      })
        .then((response) => {
          console.log(response)
          currentUser.setUser({
            id: response.idTokenClaims.oid,
            name: response.idTokenClaims.name,
            userName: response.account.username,
            token: response.accessToken,
            result: { succeeded: true },
          })
          window.location.reload()
        })
        .catch((e) => {
          console.log('Silent token acquisition fails. Acquiring token using popup. \n', e)
        })
    }


    console.log(isAuthencated)
    console.log(msal.inProgress)
    console.log(user)
  }, [msal, isAuthencated, msal.inProgress])

  return isAuthencated && msal.inProgress === InteractionStatus.None && user ? children : <BlockPageLoading />
}
