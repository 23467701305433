import React, { useState, useEffect } from 'react'
import NavDropdown from './NavDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserProfile, updateUserSetting, getUserProfile, getUserSetting } from 'slices/userProfileSlice'
import { getEnv } from 'slices/envSlice'
import { toggleTheme, getTheme } from 'slices/themeSlice'
import { Theme } from 'common/enums'
import { Icon, Dialog, WithDialog, Link } from '@abb/abb-common-ux-react'
import { updateModuleSettings, terms, privacy } from 'common/endpoints'
import NotificationList from './NotificationList'
import TimezonePanel from './TimezonePanel'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import QRCode from 'qrcode'
import device from '../../common/device'
import ShortcutHelpPanel from './ShortcutHelpPanel'
import ShortcutTips from './ShortcutTips'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from '../../i18n'
import { usePermission } from 'hooks/usePermission'
import { getCurrentTenant } from 'slices/abpApplicationConfigurationSlice'
import { ChangePassword } from 'modules/Profile/ChangePassword'
import { ChangeProfile } from 'modules/Profile/ChangeProfile'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useCurrentProfile } from 'hooks/useCurrentProfile'
import { useMsal } from '@azure/msal-react'

export default function AccountMenu() {
  const userProfile = useSelector(getUserProfile)
  const currentProfile = useCurrentProfile()
  const currentTenant = useSelector(getCurrentTenant)
  const userSetting = useSelector(getUserSetting)
  const history = useHistory()
  const env = useSelector(getEnv)
  const theme = useSelector(getTheme)
  const [showTimezoneSidePanel, setShowTimezoneSidePanel] = useState(false)
  const [showShortcutHelpPanel, setShowShortcutHelpPanel] = useState(false)
  const [showShortcutTips, setShowShortcutTips] = useState(false)
  const dispatch = useDispatch()
  const [qrCode, setQrCode] = useState(null)
  const [passwordFormEvent, setPasswordFormEvent] = useState(null)
  const [profileFormEvent, setProfileFormEvent] = useState(null)
  const [formData, setFormData] = useState(null)
  const msal = useMsal()

  const currentUser = useCurrentUser()
  const { i18n, t } = useTranslation()
  const hasAdminRole = usePermission('AbpIdentity') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasWechatRole = usePermission('Wechat') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasEzreportRole = usePermission('EzReport') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasMaindataRole = usePermission('MainData') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasRemoteSupportRole = usePermission('RemoteSupport')
  const signOut = (e) => {
    if (e.authType === 'AzureB2C' && msal?.instance) {
      currentUser.clear()
      dispatch(updateUserProfile(null))
      msal.instance.logoutRedirect()
    } else {
      currentUser.clear()
      dispatch(updateUserProfile(null))
      window.location.reload()
    }
  }
  const toggleThemeAndUpdateUserSetting = (_theme) => {
    const nextUserSetting = { ...userSetting, theme: _theme }
    dispatch(updateUserSetting(nextUserSetting))

    axios.post(updateModuleSettings(), {
      key: userProfile.id,
      module: 'user',
      value: JSON.stringify(nextUserSetting),
    })
  }

  const switchLanguage = () => {
    let newlang = i18n.language == 'zh_cn' ? 'en_us' : 'zh_cn'
    changeLanguage(newlang)
  }
  const showChangePassword = () => {
    setPasswordFormEvent({
      name: 'resetForm',
      params: [],
      callback: (initItem) => {
        setPasswordFormEvent({
          name: 'setShowSidePanel',
          params: [true],
        })
      },
    })
  }

  const showChangeProfile = () => {
    setProfileFormEvent({
      name: 'resetForm',
      params: [],
      callback: (initItem) => {
        setProfileFormEvent({
          name: 'setShowSidePanel',
          params: [true],
        })
      },
    })
  }
  useEffect(() => {
    if (userSetting?.theme) {
      dispatch(toggleTheme(userSetting.theme))
    }
  }, [userSetting?.theme])

  useEffect(() => {
    QRCode.toDataURL(
      window.location.origin,
      {
        type: 'png',
        color: {
          dark: '#1f1f1f',
          light: '#fff',
        },
      },
      function (err, url) {
        setQrCode(url)
      }
    )
    if (device.isMobile()) {
      const tipsDisplayed = localStorage.getItem('ShortcutTips.displayed')
      setShowShortcutTips(tipsDisplayed ? false : true)
    }
  }, [])
  const closeTips = () => {
    setShowShortcutTips(false)
    localStorage.setItem('ShortcutTips.displayed', 'true')
  }
  const actionTips = () => {
    closeTips()
    setShowShortcutHelpPanel(true)
  }

  return (
    <>
      <div className='header-nav header-nav-right user-nav' data-cy='btn-user-name'>
        <div className='header-nav'>
          <NotificationList />
        </div>

        <NavDropdown
          navButton={
            <>
              <div className='hideclass-ms hideclass-md'>
                欢迎您：{(currentTenant?.name ? currentTenant?.name + ' - ' : '') + currentProfile?.name}
              </div>
              <Icon name='abb/menu' className='hideclass-lg' />
            </>
          }
        >
          <div className='nav-dropdown-body no-padding'>
            <ul style={{ border: 'none' }}>
              {hasAdminRole && (
                <li onClick={() => history.push('/admin')} className='hideclass-lg hideclass-ms'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('admin')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasWechatRole && (
                <li onClick={() => history.push('/wechat')} className='hideclass-lg hideclass-ms'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('wechat')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasMaindataRole && (
                <li onClick={() => history.push('/maindata')} className='hideclass-lg'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('maindata')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasEzreportRole && (
                <li onClick={() => history.push('/ezreport')} className='hideclass-lg'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('ezreport')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasEzreportRole && (
                <li onClick={() => history.push('/wfreport')} className='hideclass-lg'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('wfreport')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasEzreportRole && (
                <li onClick={() => history.push('/inspection')} className='hideclass-lg'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('inspection')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasEzreportRole && (
                <li onClick={() => history.push('/evaluation')} className='hideclass-lg'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('evaluation')}</div>
                    </div>
                  </div>
                </li>
              )}
              {hasRemoteSupportRole && (
                <li onClick={() => history.push('/remoteSupport')} className='hideclass-lg'>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('remoteSupport')}</div>
                    </div>
                  </div>
                </li>
              )}
              <li onClick={() => showChangePassword()} data-cy='btn-update-user-language'>
                <div className='item'>
                  <div className='item-profile'>
                    <div className='item-name'>修改密码</div>
                  </div>
                </div>
              </li>
              <li onClick={() => showChangeProfile()} data-cy='btn-update-user-language'>
                <div className='item'>
                  <div className='item-profile'>
                    <div className='item-name'>修改个人资料</div>
                  </div>
                </div>
              </li>
              <li onClick={() => toggleThemeAndUpdateUserSetting(theme === Theme.Dark ? Theme.Light : Theme.Dark)}>
                <div className='item'>
                  <div className='item-profile'>
                    <div className='item-name'>{t('theme')}</div>
                  </div>
                  <div className='item-icon'>
                    <Icon name={theme === Theme.Light ? 'abb/moon' : 'abb/sun-2'} />
                  </div>
                </div>
              </li>
              <li onClick={() => switchLanguage()} data-cy='btn-update-user-language'>
                <div className='item'>
                  <div className='item-profile'>
                    <div className='item-name'>{t('language')}</div>
                  </div>
                  <div className='item-icon'>{t(i18n.language)}</div>
                </div>
              </li>
              <li onClick={() => setShowTimezoneSidePanel(true)} data-cy='btn-update-user-timezone'>
                <div className='item'>
                  <div className='item-profile'>
                    <div className='item-name'>{t('timeZone')}</div>
                  </div>
                  <div className='item-icon'>{userProfile.timeZone}</div>
                </div>
              </li>
              {env.version && (
                <li>
                  <WithDialog data-cy='dialog-about-mrc'>
                    <div className='item'>
                      <div className='item-profile'>
                        <div className='item-name' data-cy='dialog-about-relation'>
                          {t('about')}{' '}
                        </div>
                      </div>
                      <div className='item-icon'>{t('title')}</div>
                    </div>
                    <Dialog title={t('title')} className='dialog-about-mrc' showCloseButton closeOnEscape closeOnLostFocus dimBackground>
                      <div
                        style={{
                          display: 'flex',
                          marginRight: -48,
                          flexWrap: 'wrap',
                        }}
                      >
                        <div style={{ marginRight: 32 }}>
                          <p>
                            {t('version')} {env.version}
                          </p>
                          <p>{t('tel')}: 400-820-9696</p>
                          <p>
                            {t('feedback')}{' '}
                            <Link discreet href='mailto:service.cndmx@cn.abb.com'>
                              service.cndmx@cn.abb.com
                            </Link>
                          </p>
                          <p>
                            <Link discreet href={env.apiBase + terms} target='_blank'>
                              {t('termOfService')}
                            </Link>
                            <Link discreet href={env.apiBase + privacy} target='_blank' style={{ marginLeft: 8 }}>
                              {t('privacyPolicy')}
                            </Link>
                          </p>
                        </div>
                        {qrCode && <img src={qrCode} style={{ width: 128, height: 128 }} />}
                      </div>
                    </Dialog>
                  </WithDialog>
                </li>
              )}
              {device.isMobile() && (
                <li onClick={() => setShowShortcutHelpPanel(true)}>
                  <div className='item'>
                    <div className='item-profile'>
                      <div className='item-name'>{t('howAddDeskop')}</div>
                    </div>
                    <div className='item-icon'></div>
                  </div>
                </li>
              )}
              <li onClick={() => signOut(env)} data-cy='btn-signout'>
                <div className='item'>
                  <div className='item-profile'>
                    <div className='item-name'>{t('logout')}</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </NavDropdown>
      </div>
      <TimezonePanel isShow={showTimezoneSidePanel} onClose={() => setShowTimezoneSidePanel(false)} />
      <ShortcutHelpPanel isShow={showShortcutHelpPanel} onClose={() => setShowShortcutHelpPanel(false)} />
      <ChangePassword
        formData={formData}
        onCancel={() => {
          setFormData(null)
        }}
        event={passwordFormEvent}
      />
      <ChangeProfile event={profileFormEvent} />
      {showShortcutTips && <ShortcutTips onClose={() => closeTips()} onAction={() => actionTips()}></ShortcutTips>}
    </>
  )
}
