import React from "react"
// import React, { useEffect } from "react"
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getEnv } from "slices/envSlice"
import { getUserProfile } from "slices/userProfileSlice"
import { AppMainContent } from "@abb/abb-common-ux-react"
import { SideNavMenu, SideNavMenuItem } from "components/SideNavMenu"

import Dashboard from "./Dashboard"
import IconList from "./IconList"
import Users from "./Users"
import Role from "./Role"
import Organization from './Organization'
import Tenant from './Tenant'
import Settings from "./Settings"
import DataDictionary from './DataDictionary'
import AuditLogs from "./AuditLogs"

// import IoT from "./IoT"
// import Logs from "./Logs"
import Resource from "./Resource"

import "./admin.scss"
import decode from "jwt-decode"
import { ErrorBoundary } from "common/ErrorBoundary"
import { useTranslation } from "react-i18next"
import { admin } from "../menu.js"
import translate from "../../i18n/translate"
import Sync from "./Sync"
import ImportanceWeight from "./ImportanceWeight"

function Admin() {
  // 动态加载
  // const Dashboard = React.lazy(() => import("./Dashboard"))
  // const Users = React.lazy(() => import("./Users"))
  // const Settings = React.lazy(() => import("./Settings"))
  // const IoT = React.lazy(() => import("./IoT"))
  // const Logs = React.lazy(() => import("./Logs"))
  // const Resource = React.lazy(() => import("./Resource"))

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const navItems = translate(admin, t)

  const env = useSelector(getEnv)
  const userProfile = useSelector(getUserProfile)
  const oauth = useSelector((state) => state.oauth)
  const user = oauth?.accessToken ? decode(oauth?.accessToken) : null

  if (
    // (env.solution === "OnPremise" && user?.role === "admin") ||
    // userProfile?.visibilityCode === "Global"
    userProfile
  ) {
    return (
      <>
        <SideNavMenu>
          {navItems.map((item, index) => (
            <SideNavMenuItem
              key={index}
              itemKey={index}
              icon={item.icon}
              title={item.title}
              permission={item.permission}
              onClick={() => history.push(item.path)}
              active={
                location.pathname === item.path ||
                (item.path !== "/admin" &&
                  location.pathname.indexOf(item.path) === 0)
              }
            />
          ))}
        </SideNavMenu>
        <ErrorBoundary>
          <AppMainContent style={{ boxSizing: "border-box" }}>
            <Switch>
              <Route exact path={path} component={Dashboard} />
              <Route path={`${path}/iconList`} component={IconList} />
              <Route path={`${path}/sync`} component={Sync} />
              <Route path={`${path}/users`} component={Users} />
              <Route path={`${path}/role`} component={Role} />
              <Route path={`${path}/organization`} component={Organization} />
              <Route path={`${path}/tenant`} component={Tenant} />
              <Route path={`${path}/settings`} component={Settings} />
              <Route path={`${path}/dataDictionary`} component={DataDictionary} />
              <Route path={`${path}/auditLogs`} component={AuditLogs} />
              <Route path={`${path}/importance-weight`} component={ImportanceWeight} />
      
              

              {/* <Route path={`${path}/logs`} component={Logs} /> */}
              <Route path={`${path}/resource`} component={Resource} />
              {/* <Route path={`${path}/iot`} component={IoT} /> */}
            </Switch>
          </AppMainContent>
        </ErrorBoundary>
      </>
    )
  } else if (!userProfile) {
    return null
  } else {
    return (
      <div className="grid root-grid">
        <p>{t("youHaveNotVisitPurview")}</p>
      </div>
    )
  }
}

export default Admin
