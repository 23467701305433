import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { DeviceImportanceWeightCatalogs, updateDeviceData } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { getDeviceTypeMetaDatas, getDeviceDatas, getDevice } from 'slices/deviceSlice'
import device from 'common/device'
export const WeightFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [formItem, setFormItem] = useState([])
  const [datas,setDatas]=useState({})
  const [rules, setRules] = useState({});
  //初始化数据
  const initData = {}

  useEffect(() => {
    let items = []
    const d={}
    console.log(formData);
    if(formData){
      formData.forEach((m) => {
        items.push({
          type: 'dropdown',
          dataType: 'text',
          label: m.name,
          options:m.weights.map(w=>{return {label:w.title,value:w.id}}),
          fieldName: m.id,
        })
        d[m.id]=m.currentWeight
      })
    }
    setFormItem(items)
    setDatas(d)
  }, [formData])
  const getUpdateInput = (inputdata) => {
    console.log(inputdata)
    return {
      deviceId:id,
      details:inputdata,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={'设备权重'}
      formItem={formItem}
      initData={initData}
      rules={rules}
      updateData={DeviceImportanceWeightCatalogs}
      getUpdateInput={getUpdateInput}
      id={id}
      formData={datas}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
