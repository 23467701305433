import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip } from '@abb/abb-common-ux-react'
import { getQuestionnairesPage, deleteQuestionnaires, chagngeStatus, copyQuestionaireUrl, restorePublished } from 'common/apis/questionnaire'
import { useDispatch, useSelector } from 'react-redux'
import { CommonConfirm, CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'
import { getUsers } from 'slices/userSlice'
import { getDictionary } from 'slices/dataDictionarySlice'
import { getDataDictionaryDetail } from 'common/dataDictionary'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { ResponseResult } from 'common/request'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import axios from 'axios'
import { index } from 'd3'
import { useSetting } from 'hooks/useSetting'

export default function Questionnaires() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [changeStatusData, setChangeStatusData] = useState(null)
  const [showSendEmailCheck, setShowSendEmailCheck] = useState(false)
  const [isRuning, setIsRuning] = useState(false)
  const [deletedItems, setDeletedItems] = useState([])
  const [currentUrl, setCurrentUrl] = useState(null)
  const users = useSelector(getUsers)
  const dictionary = useSelector(getDictionary)
  const questionnaireCategory = getDataDictionaryDetail(dictionary, 'QuestionnaireCategory')
  const questionnaireStatus = getDataDictionaryDetail(dictionary, 'QuestionnaireStatus')
  const quGroup = getDataDictionaryDetail(dictionary, 'QuGroup')
  const refIndex = useRef(null)
  const setting = useSetting('MainData.Questionnaire.BaseUrl')
  //其他行菜单
  const copyQuestionaire = (item) => {
    setIsRuning(true)
    axios
      .post(copyQuestionaireUrl(item.id))
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToOperate'))) //`操作成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        console.log('error: ', e)
        dispatch(showNotification(NotificationType.Alarm, '出现错误'))
      })
      .finally(() => {
        setIsRuning(false)
      })
  }
  const getQuestionaireUrl = async (item) => {
    const url = `${setting}/${item.noNeedLogin?'questionaireNologin':'questionnaire'}?id=${item.id}`
    await navigator.clipboard.writeText(url)
    setCurrentUrl(url)
  }
  const summaryQuestionaire = (item) => {
    history.push('/maindata/answerSummary/' + item.id)
  }
  const previewQuestionaire = (item) => {
    history.push('/maindata/questionnairePreview/' + item.id)
  }
  const editQuestionaire = (item) => {
    history.push('/maindata/questionnaireEdit/' + item.id)
  }
  const otherMenu = (item, index) => {
    const rtn = []
    rtn.push({
      onClick: () => previewQuestionaire(item),
      title: t('quQuestionnaire.Preview'),
    })
    if (item.status == 'Draft' || item.status == 'Suspend') {
      rtn.push({
        onClick: () => editQuestionaire(item),
        title: t('quQuestionnaire.Edit'),
      })
    }

    if (item.status == 'Draft') {
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Draft', status: 'Published' }),
        title: t('quQuestionnaire.Published'),
      })
    }
    if (item.status == 'Published') {
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Published', status: 'Suspend' }),
        title: t('quQuestionnaire.Suspend'),
      })
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Published', status: 'Finished' }),
        title: t('quQuestionnaire.Finished'),
      })
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Published', status: 'Invalid' }),
        title: t('quQuestionnaire.Invalid'),
      })
    }
    if (item.status == 'Suspend') {
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Suspend', status: 'Published' }),
        title: t('quQuestionnaire.Resume'),
      })
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Suspend', status: 'Finished' }),
        title: t('quQuestionnaire.Finished'),
      })
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Suspend', status: 'Invalid' }),
        title: t('quQuestionnaire.Invalid'),
      })
    }
    if (item.status == 'Finished') {
      rtn.push({
        onClick: () => setChangeStatusData({ id: item.id, category: item.category, oldStatus: 'Finished', status: 'Suspend' }),
        title: t('quQuestionnaire.Resume'),
      })
    }
    if (item.status == 'Published' || item.status == 'Finished') {
      rtn.push({
        onClick: () => summaryQuestionaire(item),
        title: t('quQuestionnaire.summary'),
      })
    }
    rtn.push({
      onClick: () => copyQuestionaire(item),
      title: t('quQuestionnaire.copy'),
    })
    rtn.push({
      onClick: () => getQuestionaireUrl(item),
      title: '获取调查Url地址',
    })
    return rtn
  }
  const onConfirmRestorePublished = ({ id }, sendEmail) => {
    setIsRuning(true)
    axios
      .post(restorePublished(), {
        id,
        sendEmail,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToOperate'))) //`操作成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        console.log('error: ', e)
        dispatch(showNotification(NotificationType.Alarm, '出现错误'))
      })
      .finally(() => {
        setChangeStatusData(null)
        setIsRuning(false)
      })
  }
  const onConfirmChangeStatus = ({ id, status }) => {
    setIsRuning(true)
    axios
      .post(chagngeStatus(), {
        id,
        status,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToOperate'))) //`操作成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        console.log('error: ', e)
        dispatch(showNotification(NotificationType.Alarm, '出现错误'))
      })
      .finally(() => {
        setChangeStatusData(null)
        setIsRuning(false)
      })
  }

  //列定义
  const columns = [
    {
      title: 'quQuestionnaire.title',
      key: 'title',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.title}</del>
              <Tooltip>
                <div>问卷已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.title
      },
    },
    {
      title: 'quQuestionnaire.status',
      key: 'status',
      render: (item, index) => {
        const status = questionnaireStatus?.details.find((d) => d.code == item.status)
        return status?.displayText
      },
    },
    {
      title: 'quQuestionnaire.description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'quQuestionnaire.category',
      key: 'category',
      render: (item, index) => {
        const level = questionnaireCategory?.details.find((d) => d.code == item.category)
        return level?.displayText
      },
    },
    {
      title: 'quQuestionnaire.startTime',
      key: 'startTime',
      render: (item) => {
        return item.startTime ? moment(item.startTime).format('yyyy-MM-DD HH:mm') : ''
      },
    },
    {
      title: 'quQuestionnaire.finishedTime',
      key: 'finishedTime',
      render: (item) => {
        return item.finishedTime ? moment(item.finishedTime).format('yyyy-MM-DD HH:mm') : ''
      },
    },
    {
      title: 'quQuestionnaire.group',
      key: 'group',
      render: (item, index) => {
        const group = quGroup?.details.find((d) => d.code == item.group)
        return group?.displayText
      },
    },
    // {
    //   title: 'quQuestionnaire.sumbitRemindMessage',
    //   key: 'sumbitRemindMessage',
    //   width: 150,
    //   ellipsis: true,
    // },
    {
      title: 'quQuestionnaire.noNeedLogin',
      key: 'noNeedLogin',
      render: (item, index) => {
        return item?.noNeedLogin === true ? '是' : '否'
      },
    },
    {
      title: 'quQuestionnaire.issuer',
      key: 'issuer',
      render: (item, index) => {
        const issuer = users.find((d) => d.id == item.issuer)
        return issuer?.name
      },
    },
    {
      title: 'quQuestionnaire.issueTime',
      key: 'issueTime',
      render: (item) => {
        return item.issueTime ? moment(item.issueTime).format('yyyy-MM-DD HH:mm:ss') : ''
      },
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  const onCheckCanDelete = (item, index) => {
    return item.status == 'Draft'
  }
  const onCheckCanEdit = (item, index) => {
    return item.status == 'Draft' || item.status == 'Suspend'
  }
  const onCheckCanShow = (item, index) => {
    return !(item.status == 'Draft' || item.status == 'Suspend')
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getQuestionnairesPage}
        deleteData={deleteQuestionnaires}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        canAdd
        canEdit
        canShow
        canDelete
        onDeleteData={onDeleteData}
        checkCanDelete={onCheckCanDelete}
        checkCanEdit={onCheckCanEdit}
        checkCanShow={onCheckCanShow}
        enableSorting={true}
      />
      <CommonConfirm
        title={'请确认操作'}
        content={'是否确定要执行操作？'}
        isVisible={changeStatusData != null}
        onConfirm={() => {
          // 如果是恢复成发布，则弹出提示，是否发送邮件
          if (changeStatusData.oldStatus == 'Suspend' && changeStatusData.category == "FixedTime" && changeStatusData.status == "Published") {
            setShowSendEmailCheck(true)
          } else {
            onConfirmChangeStatus(changeStatusData)
          }
        }}
        confirmText={t('b_confirm')}
        onCancel={() => setChangeStatusData(null)}
        isLoading={isRuning}
      />
      <CommonConfirm
        title={'提示'}
        confirmText='是'
        cancelText='否'
        content={'是否重新发送邮件给各分销商'}
        isVisible={showSendEmailCheck}
        onConfirm={() => {
          setShowSendEmailCheck(false)
          onConfirmRestorePublished(changeStatusData, true)
        }}
        onCancel={() => {
          setShowSendEmailCheck(false)
          onConfirmRestorePublished(changeStatusData, false)
        }}
        isLoading={isRuning}
      />
      <CommonConfirm
        title={'问卷调查Url地址'}
        content={`当前调查的Url地址为：${currentUrl}，已经放入剪贴板。`}
        isVisible={currentUrl != null}
        onConfirm={() => setCurrentUrl(null)}
        confirmText={t('b_confirm')}
        onCancel={() => setCurrentUrl(null)}
      />
    </>
  )
}
