import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getDevice, getParent, getDeviceType, getStation } from 'slices/deviceSlice'
import { getFactories } from 'slices/factorySlice'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import moment from 'moment'
import { SlidingCard } from 'components/SlidingCard'

export default function DeviceInfo({ onEdit }) {
  const device = useSelector(getDevice)
  const parent = useSelector(getParent)
  const station = useSelector(getStation)
  const type = useSelector(getDeviceType)
  const [pageIndex, setPageIndex] = useState(0)
  const factories = useSelector(getFactories)
  console.log('parent', parent)
  let profiles = []
  const factory = factories?.find((f) => f.id == device?.factoryId)
  profiles = [
    [
      { title: t('device.displayName'), value: device.displayName },
      { title: t('device.station'), value: station?.name },
      { title: t('device.type'), value: type.name },
      { title: t('device.parentName'), value: parent?.displayName ?? '无' },
      { title: t('device.sn'), value: device.sn },
      { title: t('device.orderNumber'), value: device.orderNumber },
      { title: t('device.factory'), value: factory?.name },
      { title: t('device.productDate'), value: moment(device.productDate).format('yyyy-MM-DD') },
    ],
    [
      { title: t('device.pannelNo'), value: device.pannelNo },
      { title: t('device.pannelName'), value: device.pannelName },
      { title: t('device.busNo'), value: device.busNo },
      { title: t('device.description'), value: device.description },
    ],
  ]

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }
  return (
    <SlidingCard>
      {profiles.map((list) =>
        list.length === 0 ? null : (
          <Table sizeClass='medium' title={t("device.formTitle")} borderType='discreet' key={nanoid()}>
            <tbody>
              {list.map((item) => (
                <tr key={nanoid()}>
                  <td title={item.title}>{item.title}</td>
                  <td>
                    {item.value && (
                      <WithTooltip>
                        <span>{item.value}</span>
                        <Tooltip>
                          <div>
                            {item.title}：{item.value}
                          </div>
                        </Tooltip>
                      </WithTooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      )}
    </SlidingCard>
  )
}
