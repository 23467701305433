import React, { useRef, useImperativeHandle, forwardRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createWechatMenuGroup, getWechatKeywords, getWechatTags, updateWechatMenuGroup } from 'common/apis/wechat'
import axios from 'axios'
import { useMountedState } from 'common/useMountedState'

export const TypeFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const [innerData, setInnerData] = useState({})
  const [formItem, setFormItem] = useState([])
  const [keywords, setKeywords] = useState([])
  const isMounted = useMountedState()

  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const rules = {
    name: [CommonRule.Require],
    type: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    name: '',
    type: '1',
  }
  //表单项
  const changeFormItem = () => {
    const items = [
      {
        type: 'text',
        dataType: 'text',
        label: t('dataDictionary.displayText'),
        fieldName: 'name',
        maxLength: 256,
      },
      {
        type: 'dropdown',
        label: '菜单类别',
        fieldName: 'type',
        options: [
          {
            value: '1',
            label: '标准菜单',
          },
          {
            value: '2',
            label: '个性化菜单',
          },
        ],
      },
    ]
    if (innerData?.type === '2') {
      items.push({
        type: 'dropdown',
        searchable: true,
        showClearIcon: true,
        label: '用户标签',
        fieldName: 'tag_id',
        options: keywords,
      })
    }
    setFormItem(items)
  }

  const fetchKeywords = () => {
    axios
      .post(getWechatTags(), { pageSize: 10000 })
      .then((e) => {
        if (!isMounted()) {
          return
        }
        if (e.data) {
          setKeywords(
            e?.data?.items?.map((i) => {
              return { value: i.id.toString(), label: i.name }
            })
          )
        }
      })
      .then((e) => {
        if (!isMounted()) {
          return
        }
      })
  }

  useEffect(() => {
    fetchKeywords()
  }, [])

  useEffect(() => {
    setInnerData({ ...formData, tag_id: formData?.conditions?.tag_id })
  }, [formData])

  useEffect(() => {
    changeFormItem()
  }, [innerData])

  const onChange = (data, preData) => {
    setInnerData(data)
  }
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      name: inputdata.name,
      type: inputdata.type,
      conditions: { tag_id: inputdata.tag_id },
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      type: inputdata.type,
      conditions: { tag_id: inputdata.tag_id },
    }
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={'菜单类别'}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createWechatMenuGroup}
      updateData={updateWechatMenuGroup}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={innerData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      onChange={onChange}
      event={event}
    />
  )
})
