import { createSlice } from "@reduxjs/toolkit";

const questionaireSlice = createSlice({
  name: "questionaire",
  initialState: {
    questionaireCache: null,
  },
  reducers: {
    setQuestionaireCache(state, action) {
      state.questionaireCache = action.payload
    },
  },
})

export const { setQuestionaireCache,setQuestionaireCache2 } = questionaireSlice.actions;
export const getQuestionaireCache = (state) => state.questionaire.questionaireCache;
export default questionaireSlice.reducer;
