import { useSelector } from 'react-redux'

export const getDataDictionary = (dictionary, code) => {
  const dt = dictionary ? dictionary.find((d) => d.code == code) : null
  return dt
}

export const treeMap = (tree, callback) => {
  return tree.map((item, index) => {
    const v = callback(item)
    if (item.children && item.children.length) {
      return { ...v, children: treeMap(item.children, callback) }
    }
    return { ...v }
  })
}

export const findTree = (tree, callback) => {
  for (const index in tree) {
    let item = tree[index]
    if (callback(item)) {
      return item
    }
    if (item.children) {
      const r = findTree(item.children, callback)
      if (r) {
        return r
      }
    }
  }
  return
}
export const hashCode = function (str) {
  var hash = 0
  if (str.length === 0) return hash
  for (var i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return Math.abs(hash)
}
export const dateFormat = function (d, fmt) {
  var o = {
    'M+': d.getMonth() + 1, //月份
    'd+': d.getDate(), //日
    'H+': d.getHours(), //小时
    'm+': d.getMinutes(), //分
    's+': d.getSeconds(), //秒
    'q+': Math.floor((d.getMonth() + 3) / 3), //季度
    S: d.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return fmt
}
//生成 guid
export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
const regMobile = /^1[3589][0-9]{9}$/
const regIdCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))((0[1-9])|([12]\d)|(3[01]))\d{3}[0-9Xx]$/
const regVehicle1 = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳]$/
const regVehicle2 = /^[京津沪渝冀忌云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁鲁使领][A-Z][A-Z0-9]{4}[A-Z0-9]{1}[I/O/B]$/
const regVehicle3 = /^[京津沪渝冀忌云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽赣贵粤青藏川宁鲁使领][A-Z][A-Z0-9]{4}[A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{1}$/
    
export function isVehicleNumber(vehicleNumber) {
  return regVehicle1.test(vehicleNumber) || regVehicle2.test(vehicleNumber) || regVehicle3.test(vehicleNumber)
}
export function isEmail(value) {
  return value.length > 3 ? regEmail.test(value) : false
}
export function isMobile(value) {
  return value.length >= 11 ? regMobile.test(value) : false
}
export function isIdCard(value) {
  return value.length > 0 ? regIdCard.test(value) : false
}