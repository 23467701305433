import React, { useRef, useImperativeHandle, forwardRef, useEffect } from 'react'
import { CommonForm, CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { createDeviceType, updateDeviceType } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { ImageSelector } from 'components/ImageSelector'
import { useDataDictionary } from 'hooks/useDataDictionary'

export const DeviceTypeFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dt = useDataDictionary('DeviceType')
  const stage = useDataDictionary('ProductStage')
  const [currImg, setCurrImg] = useState(formData?.picture)
  const [weights, setWeights] = useState({})
  //初始化数据
  const initData = {
    parentId: null,
    parentName: '',
    name: '',
    type: null,
    picture: null,
    productStage: null,
    upgradeCycle: 0,
    upgradeScore: 0,
  }

  const weightInitData = {
    security: 0,
    structure: 0,
    protection: 0,
    exothermic: 0,
    electromechanical: 0,
    insulation: 0,
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      disabled: true,
      label: t('deviceType.parentName'),
      fieldName: 'parentName',
      visible: !formData || !!formData.parentName,
      maxLength: 128,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('deviceType.name'),
      fieldName: 'name',
      maxLength: 128,
    },
    {
      type: 'dropdown',
      dataType: 'text',
      label: t('deviceType.type'),
      fieldName: 'type',
      options: dt
        ? dt.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
    {
      type: 'dropdown',
      dataType: 'text',
      label: '产品生命周期',
      fieldName: 'productStage',
      options: stage
        ? stage.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
    {
      type: 'text',
      dataType: 'text',
      label: '设备升级周期',
      fieldName: 'upgradeCycle',
      maxLength: 128,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '设备升级健康分数',
      fieldName: 'upgradeScore',
      maxLength: 128,
    },
    {
      type: 'div',
      label: t('deviceType.image'),
      fieldName: 'picture',
      maxLength: 256,
      render: (item, callback) => {
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/DeviceTypes/'}
              onValueChanged={(d) => {
                setCurrImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
  ]

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
    type: [CommonRule.Require],
  }

  useEffect(() => {
    if (formData) {
      console.log(formData)
      setCurrImg(formData?.picture)
      setWeights(formData?.weights)
    }
  }, [formData])

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      type: inputdata.type,
      upgradeScore: inputdata.upgradeScore,
      upgradeCycle: inputdata.upgradeCycle,
      lifeCycleConfig: inputdata.lifeCycleConfig,
      weights: weights,
      picture: currImg ?? inputdata.picture,
      productStage: inputdata.productStage,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      parentId: inputdata.parentId,
      parentName: inputdata.parentName,
      name: inputdata.name,
      upgradeScore: inputdata.upgradeScore,
      upgradeCycle: inputdata.upgradeCycle,
      lifeCycleConfig: inputdata.lifeCycleConfig,
      weights: weights,
      type: inputdata.type,
      picture: currImg ?? inputdata.picture,
      productStage: inputdata.productStage,
    }
  }

  const onWeightChange = (d) => {
    setWeights(d)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('deviceType.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createDeviceType}
      updateData={updateDeviceType}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      <div title={'设备权重信息'} style={{ height: clientHeight - 280 + 'px' }}>
        <CommonForm
          formItem={[
            {
              type: 'text',
              dataType: 'number',
              label: '安全总分',
              fieldName: 'security',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '结构总分',
              fieldName: 'structure',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '保护总分',
              fieldName: 'protection',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '发热总分',
              fieldName: 'exothermic',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '机电总分',
              fieldName: 'electromechanical',
              maxLength: 128,
            },
            {
              type: 'text',
              dataType: 'number',
              label: '绝缘总分',
              fieldName: 'insulation',
              maxLength: 128,
            },
          ]}
          initData={weightInitData}
          newFormData={{ ...weightInitData, ...weights }}
          onChange={onWeightChange}
        />
      </div>
    </CommonFormEdit>
  )
})
