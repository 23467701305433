import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { createDataDictionary, updateDataDictionary } from 'common/endpoints'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createStationParameterCatalog, updateStationParameterCatalog } from 'common/apis/ezreport'

export const TypeFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const rules = {
    name: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    name: '',
  }
  //表单项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('dataDictionary.displayText'),
      fieldName: 'name',
      maxLength: 256,
    },
  ]
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      name: inputdata.name,
      projectType: 'wfm'
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      projectType: 'wfm' 
    }
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={'站点参数类别'}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createStationParameterCatalog}
      updateData={updateStationParameterCatalog}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
