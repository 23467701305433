import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import {
  getAssociatedDeviceTypes,
  getDeviceMaterials,
  removeAssociatedDeviceType,
  removeDeviceMaterials,
  setDeviceRemommandMaterials,
  setRecommandMaterial,
} from 'common/apis/maindata'
import axios from 'axios'
import { t } from 'i18next'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'

import { CommonIndex } from 'components/LoongInCommon'
import { Table, Tooltip, WithTooltip, Button, ToggleSwitch } from '@abb/abb-common-ux-react'
import { forwardRef } from 'react'
import './organiza.scss'
import { Materials } from './Materials'
import { ImportFormEdit } from './Materials/ImportFormEdit'
export const Material = forwardRef(({ device, onEdit }, ref) => {
  const [searchData, setSearchData] = useState(null)
  const refIndex = useRef(null)
  const searchUserRef = useRef(null)
  const refImport = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (device) {
      setSearchData({ deviceId: device.id })
    }
  }, [device])

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const onWindowResize = () => {}
  const getDeleteInput = (item) => {
    return { deviceId:device.id, materials: [item.id] }
  }
  //列定义
  const columns = [
    {
      title: '物料名称',
      key: 'name',
    },
    {
      title: '采购编号',
      key: 'purchaseCode',
    },
  ]

  const refresh = () => {
    refIndex.current.query()
  }
  const actionRender = (item, index) => {
    return (
      <Button
        sizeClass='small'
        icon='abb/minus'
        shape='pill'
        onClick={() => {
          refIndex.current.setDeleteItem(item)
        }}
      />
    )
  }
  const changeStatus = (item, newStatus) => {
    axios
      .post(setDeviceRemommandMaterials(), {
        deviceId: device.id,
        materialId: item.id,
        recommand: newStatus,
      })
      .then((response) => {
        console.log(response)
        if (response.status == 200 || response.status == 204) {
          dispatch(showNotification(NotificationType.Success, t('t_successToUpdate')))
          refIndex.current.query()
        } else {
          dispatch(showNotification(NotificationType.Success, t('t_failToUpdate')))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(showNotification(NotificationType.Alarm, t('t_failToUpdate')))
      })
      .finally(() => {})
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    refresh,
  }))

  const addMaterials = () => {
    searchUserRef.current.showDialog()
  }
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>设备物料</div>
          <div className='widget-tools device-profile-paginator show-md show-lg' style={{ paddingTop: 10 }}>
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/import'
              onClick={() => refImport.current.showPanel()}
              style={{ opacity: 1, marginRight: 8 }}
            />{' '}
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/plus'
              onClick={() => addMaterials(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>
          <div className='widget-tools device-profile-paginator show-xl'>
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/import'
              onClick={() => refImport.current.showPanel()}
              style={{ opacity: 1, marginRight: 8 }}
            />{' '}
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/plus'
              onClick={() => addMaterials(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>{' '}
        </div>
        <div className='widget-body device-profile-details'>
          <CommonIndex
            className={'admin-main-content3 with-padding'}
            ref={refIndex}
            columns={columns}
            getList={getDeviceMaterials}
            searchData={searchData}
            deleteData={removeDeviceMaterials}
            getDeleteInput={getDeleteInput}
            canDelete
            pageSize={3}
            canSearch={false}
            pageInQuery={false}
            refreshAfterDelete={true}
            tableWidth={'100%'}
            tableHeight={'100%'}
            enableSorting={false}
            refreshWhenOpen={false}
            canSelection={false} //是否开启选择功能
            canBatchDel={false}
            actionRender={actionRender}
            searchClassName='searching-form-simple' //查询样式
            toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
          />
        </div>
      </div>
      <Materials ref={searchUserRef} device={device} role='action' onRefresh={() => setTimeout(() => refIndex.current.query(), 500)} />
      <ImportFormEdit
        ref={refImport}
        device={device}
        onSubmit={() => {
          refIndex.current.query()
        }}
      />
    </div>
  )
})
