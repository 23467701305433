import React, { useState, useCallback, useEffect } from 'react'
import { Button, Dialog, Spinner } from '@abb/abb-common-ux-react'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { getEnv } from 'slices/envSlice'
import { ResponseResult } from 'common/request'
import axios from 'axios'
import { uploadFile, downloadFile } from 'common/apis/maindata'
import { forwardRef } from 'react'
import nofile from './nofile.gif'
export const ImageSelector = ({ uploadUrl = null, downloadUrl = null, params = null, customerId, path, value, onValueChanged }, ref) => {
  const env = useSelector(getEnv)
  const [isUploading, setIsUploading] = useState(false)
  const [isShowResetConfirm, setIsShowResetConfirm] = useState(false)
  const [data, setData] = useState([{ id: value }])
  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    // Do something with the files

    if (!acceptedFiles[0]) {
      return
    }
    setIsUploading(true)
    const form = new FormData()
    form.append('files', acceptedFiles[0])
    axios
      .post(uploadUrl ?? uploadFile(), form, { params: params ?? { customerId: customerId, path: path } })
      .then((response) => {
        if (response.data) {
          setData(response.data)
          onValueChanged && onValueChanged(response.data)
        }
      })
      .finally(() => setIsUploading(false))
  }, [])
  useEffect(() => {
    setData([{ id: value }])
  }, [value])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      <div {...getRootProps({ className: 'image-picker' })} title='上传图片'>
        <img
          alt='图片'
          src={
            downloadUrl ??
            ((data ?? [{ id: value }])[0].id
              ? `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadFile()}?id=${(data ?? [{ id: value }])[0].id}`
              : nofile)
          }
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
        <input {...getInputProps({ multiple: false })} />
        {isUploading && (
          <div className='central-position'>
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}
