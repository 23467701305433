import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { deleteWechatKeyword, deleteWechatTag, getWechatKeywords, getWechatTags } from 'common/apis/wechat'

export default function Factories() {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()

  const refIndex = useRef(null)

  const actionTypes = [
    { label: '回复文字', value: 'text' },
    { label: '回复图文', value: 'news' },
    { label: '回复语音', value: 'voice' },
    { label: '回复图片', value: 'image' },
    { label: '回复视频', value: 'video' },
  ]
  //列定义
  const columns = [
    {
      title: '关键字',
      key: 'keyword',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.keyword}</del>
              <Tooltip>
                <div>工厂已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.keyword
      },
    },
    {
      title: 'factory.name',
      key: 'name',
      //通过渲染的方式将内容渲染出来
    },
    {
      title: '关键字行为',
      key: 'actionType',
      render: (item, index) => {
        const type = actionTypes.find(v=>v.value===item.actionType)
        return type.label
      }, //通过渲染的方式将内容渲染出来
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getWechatKeywords}
        deleteData={deleteWechatKeyword}
        FormEdit={FormEdit}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
    </>
  )
}
