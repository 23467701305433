import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon, Button } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { deleteWechatTag, getWechatTags, pullWechatTags } from 'common/apis/wechat'
import axios from 'axios'
import { useMountedState } from 'common/useMountedState'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'

export default function Factories() {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [isLoading, setIsLoading] = useState(false)

  const refIndex = useRef(null)

  //列定义
  const columns = [
    {
      title: 'factory.name',
      key: 'name',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = item.id<=2
        if (isDeleted)
          return (
            <WithTooltip>
              <div><Icon name='abb/lock-closed' style={{marginRight:10}} ></Icon>{item.name}</div>
              <Tooltip>
                <div>微信保留标签</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }

  const pullTags = () => {
    setIsLoading(true)
    axios
      .post(pullWechatTags())
      .then((e) => {
        if (!isMounted()) {
          return
        }
        dispatch(showNotification(NotificationType.Success, '同步微信标签成功'))
        refIndex.current.query()
      })
      .catch((e) => {
        dispatch(
          showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : '同步微信标签失败，请稍后重试或联系管理员！')
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const toolChildren = <Button text='从微信同步' isLoading={isLoading} type='normal' sizeClass='small' icon={'abb/download'} onClick={pullTags} />
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getWechatTags}
        deleteData={deleteWechatTag}
        FormEdit={FormEdit}
        toolWidth={280}
        isLoading={isLoading}
        toolChildren={toolChildren}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        checkCanEdit={(item, index) => item.id > 2}
        checkCanDelete={(item, index) => item.id > 2}
        enableSorting={true}
      />
    </>
  )
}
