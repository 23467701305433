import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon, Button } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { deleteWechatKeyword, deleteWechatMaterial, deleteWechatTag, getWechatKeywords, getWechatMaterials, getWechatTags, pullWechatMaterials } from 'common/apis/wechat'
import { useMountedState } from 'common/useMountedState'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import axios from 'axios'

export default function Factories() {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const [isLoading, setIsLoading] = useState(false)

  const refIndex = useRef(null)

  const actionTypes = [
    { label: '文字', value: 'text' },
    { label: '缩略图', value: 'thumb' },
    { label: '语音', value: 'voice' },
    { label: '图片', value: 'image' },
    { label: '视频', value: 'video' },
  ]
  //列定义
  const columns = [
    {
      title: 'factory.name',
      key: 'name',
      //通过渲染的方式将内容渲染出来
    },
    {
      title: '类型',
      key: 'contentType',
      render: (item, index) => {
        const type = actionTypes.find(v=>v.value===item.contentType)
        return type.label
      }, //通过渲染的方式将内容渲染出来
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }

  
  const pullTags = () => {
    setIsLoading(true)
    axios
      .post(pullWechatMaterials())
      .then((e) => {
        if (!isMounted()) {
          return
        }
        dispatch(showNotification(NotificationType.Success, '同步微信素材成功'))
        refIndex.current.query()
      })
      .catch((e) => {
        dispatch(
          showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : '同步微信素材失败，请稍后重试或联系管理员！')
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const toolChildren = <Button text='从微信同步' isLoading={isLoading} type='normal' sizeClass='small' icon={'abb/download'} onClick={pullTags} />
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        isLoading={isLoading}
        toolChildren={toolChildren}
        getList={getWechatMaterials}
        deleteData={deleteWechatMaterial}
        FormEdit={FormEdit}
        toolWidth={280}
        canAdd
        canDelete
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
    </>
  )
}
