import React, { useState, useEffect, forwardRef, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getDeviceTypeMetaDatas, getDeviceDatas } from 'slices/deviceSlice'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import { DeviceImportanceWeightCatalogs } from 'common/apis/maindata'
import { useParam } from 'hooks/useParam'
import axios from 'axios'
import { WeightFormEdit } from './WeightFormEdit'

export const Weight =  forwardRef(({ onEdit, onCancel, event }, ref) => {
  const [values, setValues] = useState([])
  const id = useParam('id')
  const [isLoadingList, setIsLoadingList] = useState(false)
  const formDataRef = useRef(null)

  const getAllList = () => {
    setIsLoadingList(true)
    axios
      .get(DeviceImportanceWeightCatalogs(), { params: {deviceId:id } })
      .then((response) => {
        if (response.status == 200 && response.data) {
          const items = response.data
          setValues(items)
        } else {
          setValues([])
        }
      })
      .finally(() => {
        setIsLoadingList(false)
      })
  }
  useEffect(() => {
    if (event === 'showArgsSidePanel') {
      setShowDataSidePanel(true)
    }
  }, [event])

  const setShowDataSidePanel = (visible) => {
    formDataRef.current.setShowSidePanel(visible)
  }
  useEffect(() => {
    getAllList()
  }, [])
  console.log(values)
  return (
    <>
      <Table sizeClass='medium' key={nanoid()}>
        <tbody>
          {values.map((item) => (
            <tr key={nanoid()}>
              <td style={{ width: '50%' }} title={item?.name}>
                {item?.name}
              </td>
              <td>{item?.weights.find(w=>w.id==item.currentWeight)?.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <WeightFormEdit ref={formDataRef} formData={values} id={id} onSubmit={onEdit} onCancel={onCancel}/>
    </>
  )
})
