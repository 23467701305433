import React, { useRef, forwardRef } from 'react'
import { CommonTree } from 'components/LoongInCommon'

import { t } from 'i18next'
// import {  getClientHeight,} from "slices/appSlice"

export const LeftPanel = forwardRef(({ tree, onActive,onAdd,onRemove }, ref) => {
  const treeRef = useRef(null)
  //组织架构树的数据
  //传递引用方法
  //返回页面
  const myMenu = [
    {
      title: '添加子目录',
      onClick: (item) => {
        onAdd && onAdd(item)
      },
    },{
      title: '删除目录',
      onClick: (item) => {
        onRemove && onRemove(item)
      },
    },
  ]
  return (
    <>
      <CommonTree ref={treeRef} items={tree} menu={myMenu} onActive={onActive} />
    </>
  )
})
