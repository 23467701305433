
export const SwitchType = {
    breaker: 'breaker',
    switch: 'switch',
    disconnector: 'disconnector',
    contactor: 'contactor',
    loadSwitch: 'loadSwitch',
    fuseContactor: 'fuseContactor',
    earthingSwitch: 'earthingSwitch',
    switch3PS: 'switch3PS',
    switchLoad3PS: 'switchLoad3PS',
    switch2PS: 'switch2PS',
    switchEarthing2PS: 'switchEarthing2PS',
    fuse2: 'fuse2',
    MT: 'MT',
    capacitor:'capacitor',
    none: 'none'
}
export const PTType = {
    PT: "PT",
    socketPT: "socketPT",
    downPT2: "downPT2",
    downDisconnectorPT2: "downDisconnectorPT2",
    upPT2: "upPT2",
    upPT3PS: "upPT3PS",
    upArrester: "upArrester",
    upArrester_upPT2: "upArrester_upPT2",
    none: 'none'
}
export const EarthingType = {
    downArrester: "downArrester",
    earthingSwitch: "earthingSwitch"
}
export const SwitchTypes = [

    { value: 'switch', label: '隔离断路器' },
    { value: 'breaker', label: '断路器' },
    { value: 'loadSwitch', label: '负荷开关' },
    { value: 'fuseContactor', label: '接触器' },
    { value: 'fuse2', label: '熔断器' },
    { value: 'switch3PS', label: '三工位开关' },
    { value: 'switchLoad3PS', label: '三工位负荷开关' },
    { value: 'switch2PS', label: '二工位开关' },
    { value: 'switchEarthing2PS', label: '二工位接地开关' },
    { value: 'none', label: '空' },
]

export const PTTypes = [
    { value: 'downPT2', label: '电压互感器' },
    { value: 'downDisconnectorPT2', label: '带隔离' },
    { value: 'PT', label: '普通 PT' },
    { value: 'fusePT', label: '熔断器 PT' },
    { value: 'socketPT', label: '可拔插 PT' },
    { value: 'downArrester', label: '避雷器' },
    { value: 'none', label: '空' },
]

export const UpPTTypes = [
    { value: 'upPT2', label: '电压互感器 PT' },
    { value: 'upPT3PS', label: '三工位 PT' },
    { value: 'upArrester', label: '避雷器' },
    { value: 'upArrester_upPT2', label: '避雷器+PT' },
    { value: 'none', label: '空' },
]

export const EarthingTypes = [    
    { value: 'earthingSwitch', label: '接地开关' },
    { value: 'downArrester', label: '避雷器' },
    { value: 'none', label: '空' },
]

// 低压开关类型
export const SwitchTypesLV = [
    { value: 'switch', label: '隔离断路器' },
    { value: 'contactorLV', label: '接触器' },
    { value: 'fuseContactor', label: '熔断接触器' },
    { value: 'breaker', label: '断路器' }, 
    { value: 'breakerOverload_shortCircuit', label: '断路器(过载,短路)' },
    { value: 'breakerOverload', label: '断路器(过载)' },
   
    { value: 'fuse2', label: '熔断器' }, 
    { value: 'switchFuse', label: '熔断开关' },
    { value: 'reactor', label: '电抗器' }, 
    { value: 'capacitor', label: '电容器' }, 
    { value: 'filter', label: '滤波器' },  
    { value: 'thermorelay', label: '热继电器' },
    { value: 'softStarter', label: '软启动器' },
    { value: 'CT2', label: '电流互感器' },
    { value: 'none', label: '空' },
]

export const CTTypes = [

    { value: 'CT', label: '电流互感器' },
    { value: 'MCU', label: 'MCU' }, 
    { value: 'transducer', label: '变频器' }, 
    { value: 'VVVF', label: '变频器(VVVF)' }, 
    { value: 'none', label: '空' },
]

export const SwitchOffsets={
    capacitor:{x:-10,y:-5},
    transducer:{x:-10,y:0},
    switchFuse:{x:0,y:-5},
    thermorelay:{x:-10,y:0},
    softStarter:{x:-5,y:-10},
    breakerOverload_shortCircuit:{x:-28,y:0},
    breakerOverload:{x:-28,y:0},
    MCU:{x:-10,y:0},
    VVVF:{x:-10,y:0}
}

export const DeviceTypes = [
    { value: 'Full', label: '基础柜' },
    { value: 'PT', label: 'PT柜' },
    { value: 'BusbarPT', label: '母线PT柜' }, 
    { value: 'RM', label: '计量柜' },
    { value: 'Feeder', label: '馈线柜' },
    { value: 'BusTie', label: '母联柜' },
    { value: 'FeederLV', label: '馈线(LV)' },
    { value: 'BusTieLV', label: '母联柜(LV)' },
    { value: 'PTLV', label: 'PT柜(LV)' },
    { value: 'ATS', label: 'ATS双电源' },
    
]

export const DeviceModels = [
    { value: 'PAIS_UniGear_ZS1_500', label: 'UniGear ZS1 500' },
    { value: 'PAIS_UniGear_ZS1_650', label: 'UniGear ZS1 650' },
    { value: 'PAIS_UniGear_ZS1_800', label: 'UniGear ZS1 800' },
    { value: 'PAIS_UniGear_ZS1_1000', label: 'UniGear ZS1 1000' },
    { value: 'PAIS_UniGear_ZS3_2', label: 'UniGear ZS3.2' },

    { value: 'PGIS', label: 'PGIS' },
    { value: 'SGIS', label: 'SGIS' },

]
export const StatusVariables = [
    'CB_OPEN',
    'CB_CLOSE', 'ME_OPEN', 'ME_CLOSE', 
    'MT_TESTING_POSITION', 'MT_WORKING_POSITION', 
    'PRIMARY_CURRENT_A', 'PRIMARY_CURRENT_B', 'PRIMARY_CURRENT_C', 
    'Three_Pos_Switch_Position_BB1', 'Three_Pos_Switch_Position_BB2',
    'MAIN_SWITCH_CLOSE','MAIN_SWITCH_OPEN',
]

export const SwitchStatus = {
    Unkown: -1,
    Open: 15,
    Close: 16,
    Off: 17,
    On: 18,
    Intermediate: 2001,
    Faulty: 2002,
    Earthing: 1000


}
export const GetSwitchStatus = (switchType, deviceStatus, busbarId) => {

    switch (switchType) {
        case SwitchType.switch3PS:
        case SwitchType.switchLoad3PS:
        case SwitchType.switch2PS:
        case SwitchType.switchEarthing2PS:
            return Parse3PSSwitchStatus(busbarId === 'BB2' ? deviceStatus?.Three_Pos_Switch_Position_BB2 : deviceStatus?.Three_Pos_Switch_Position_BB1)

        case SwitchType.earthingSwitch:
            return ParseSwitchStatus(deviceStatus?.ME_OPEN, deviceStatus?.ME_CLOSE)

        case SwitchType.MT:
            return ParseSwitchStatus(deviceStatus?.MT_TESTING_POSITION, deviceStatus?.MT_WORKING_POSITION)

        default:
            if(deviceStatus?.CB_OPEN && deviceStatus?.CB_CLOSE)
                return ParseSwitchStatus(deviceStatus?.CB_OPEN, deviceStatus?.CB_CLOSE)
            else
                return ParseSwitchStatus(deviceStatus?.MAIN_SWITCH_OPEN, deviceStatus?.MAIN_SWITCH_CLOSE)

    }
}
export const ParseSwitchStatus = (openValue, closeValue) => {

    if (!openValue || !closeValue)
        return SwitchStatus.Unkown;
    if (closeValue == 0 && openValue == 1) {
        return SwitchStatus.Open;
    }
    else if (closeValue == 1 && openValue == 1) {
        return SwitchStatus.Intermediate;
    }
    else if (closeValue == 0 && openValue == 0) {
        return SwitchStatus.Faulty;
    }
    else if (closeValue == 1 && openValue == 0) {
        return SwitchStatus.Close;
    }
    else
        return SwitchStatus.Unkown;
}
export const Parse3PSSwitchStatus = (status) => {
    if (!status)
        return SwitchStatus.Unkown;

    if (status == 1) {//接通
        return SwitchStatus.Close;
    } else if (status == 2) {//隔离
        return SwitchStatus.Open;
    }
    else if (status == 3) {//接地
        return SwitchStatus.Earthing;
    }
    else
        return SwitchStatus.Unkown;
}
export const GroupTemplate = {
    id: new Date().getTime().toString(),
    index: 0,
    name: "单线图",
    devices: []
}
export const DeviceTemplate = {
    id: new Date().getTime().toString(),
    index: 999,
    equipmentId: '',
    type: "Full",
    title: "",
    titleBottom: "",
    desc: "",
    offsetX: 0,
    offsetY: 0,
    displayOptions: {},

}
export const DisplayOptionsTemplates = {
    Full: {
        upBusbarIn: false,
        upCableOutLine: false,
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        bottomLeftHorizontalLine: false,
        bottomRightHorizontalLine: false,
        downCableOutLine: true,
        downInLine: false,
        ME: true,
        CT: true,
        PT: true,
        socketPT: true,
        switchType: SwitchType.breaker,
        socket: true,
        backIn: true,
        backInBusbar: true,
        isTransition:false

    },
    PT: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        socketPT: true

    },
    BusbarPT: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        socketPT: true,
        doubleBusbar: false,
        switchType3PS: SwitchType.switch3PS,
        switchType3PS2: SwitchType.switch3PS,

    },
    RM: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: false,
        bottomLeftHorizontalLine: false,
        bottomRightHorizontalLine: true,
        CT: true,
        socketPT: true
    },
    Feeder: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        topLeftHorizontalLine2: false,
        topRightHorizontalLine2: false,
        bottomLeftHorizontalLine: false,
        bottomRightHorizontalLine: false,
        downCableOutLine: true,
        ME: true,
        CT: true,
        CT2: false,
        PT: true,
        upPT: false,
        socketPT: true,
        switchType: SwitchType.breaker,
        switchType3PS: SwitchType.switch3PS,
        switchType3PS2: SwitchType.switch3PS,
        PTType: PTType.downPT2,
        upPTType: "none",
        earthingType: "earthingSwitch",
        arrester: true,
        upArrester: false,
        DXN: true,
        doubleBusbar: false,


    },
    BusTie: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,

        bottomLeftHorizontalLine: false,
        bottomRightHorizontalLine: false,
        downCableOutLine: true,
        ME: true,
        CT: true,
        CT2: false,
        PT: false,
        upPT: false,
        upPT2: false,
        socketPT: true,
        switchType: SwitchType.breaker,
        switchType3PS: SwitchType.switch3PS,
        switchType3PS2: SwitchType.switch3PS,
        arrester: false,
        upArrester: false,
        DXN: true,
        PTType: PTType.none,
        upArrester2: false,

    },
    FeederLV: {
        
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        topBranchLine:true,
        topBeginBranchLine:false,
        downCableOutLine: true, 
        switchType: "breakerOverload_shortCircuit",
        switchType2: "contactorLV",
        switchType3: "CT", 
        socket:true,
        downBackLine:false,
        CT: false,
        socketTop:true,
        reactorOutLine:false,


    },
    BusTieLV: {
        
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        busTieIn:true,
       
        switchType: "breakerOverload_shortCircuit",
       
        socket:true,
        downBackLine:false,
        CT: true,

    },
    PTLV: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        CT: true

    },
    ATS: {
        topLeftHorizontalLine: true,
        topRightHorizontalLine: true,
        CT: true

    },
}