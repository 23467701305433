import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { getDeviceTypeMetaDatas, getDeviceDatas } from 'slices/deviceSlice'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import { useParam } from 'hooks/useParam'
import { DataFormEdit } from './DataFormEdit'
export const Data = forwardRef(({ onEdit, onCancel, event }, ref) => {
  const metaDatas = useSelector(getDeviceTypeMetaDatas)
  const datas = useSelector(getDeviceDatas)
  const [values, setValues] = useState([])
  const formDataRef = useRef(null)
  const id = useParam('id')
  const [DataFormData, setDataFormData] = useState(null)
  const setShowDataSidePanel = (visible) => {
    setDataFormData(null)
    let data = {}
    metaDatas.forEach((m) => (data[m.id] = datas?.find((d) => d.metaDataId == m.id)?.value ?? ''))
    setDataFormData(data)
    formDataRef.current.setShowSidePanel(visible)
  }
  useEffect(() => {
    if (event === 'showDataSidePanel') {
      setShowDataSidePanel(true)
    }
  }, [event])
  useEffect(() => {
    const v = metaDatas.map((m) => {
      return { title: m.name, value: datas?.find((d) => d.metaDataId == m.id)?.value ?? '' }
    })
    setValues(v)
  }, [])
  return (
    <>
      <Table sizeClass='medium'>
        <tbody>
          {values.map((item) => (
            <tr key={nanoid()}>
              <td style={{ width: '50%' }} title={item.title}>
                {item.title}
              </td>
              <td>
                {item.value && (
                  <WithTooltip>
                    <span>{item.value}</span>
                    <Tooltip>
                      <div>
                        {item.title}：{item.value}
                      </div>
                    </Tooltip>
                  </WithTooltip>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DataFormEdit ref={formDataRef} formData={DataFormData} id={id} onSubmit={onEdit} onCancel={onCancel} />
    </>
  )
})
