import React, { useRef, useImperativeHandle, forwardRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { getEnv } from 'slices/envSlice'

import {
  createWechatKeyword,
  createWechatMaterial,
  createWechatMenu,
  downloadWechatMaterial,
  updateWechatKeyword,
  updateWechatMaterial,
  updateWechatMenu,
  uploadWechatMaterial,
} from 'common/apis/wechat'
import { ImageSelector } from 'components/ImageSelector'
import { FileSelector } from 'components/FileSelector'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const [innerData, setInnerData] = useState({})
  const [formItem, setFormItem] = useState([])
  const [currPath, setCurrPath] = useState(null)
  const env = useSelector(getEnv)

  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const rules = {
    name: [CommonRule.Require],
    contentType: [CommonRule.Require],
    path: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    keyword: '',
    name: '',
    description: '',
    contentType: '',
  }
  //表单项
  const changeFormItem = () => {
    const items = [
      {
        type: 'text',
        dataType: 'text',
        label: '名称',
        fieldName: 'name',
        maxLength: 256,
      },
      {
        type: 'dropdown',
        dataType: 'text',
        label: '素材类型',
        fieldName: 'contentType',
        options: [
          { label: '语音', value: 'voice' },
          { label: '图片', value: 'image' },
          { label: '视频', value: 'video' },
          { label: '缩略图', value: 'thumb' },
        ],
      },
    ]
    if (innerData?.contentType === 'image'|| innerData?.contentType === 'thumb') {
      items.push({
        type: 'div',
        label: '上传图片',
        fieldName: 'path',
        maxLength: 256,
        render: (item, callback) => {
          return (
            <div style={{ paddingTop: '12px' }}>
              <ImageSelector
                uploadUrl={uploadWechatMaterial()}
                downloadUrl={currPath && `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadWechatMaterial(currPath)}`}
                onValueChanged={(d) => {
                  setCurrPath(d)
                }}
              />
            </div>
          )
        },
      })
    }
    if (
      innerData?.contentType === 'voice' ||
      innerData?.contentType === 'video'
    ) {
      items.push({
        type: 'div',
        label: '素材文件',
        fieldName: 'path',
        maxLength: 256,
        render: (item, callback) => {
          console.log(item)
          console.log(currPath)
          return (
            <div style={{ paddingTop: '12px' }}>
              <FileSelector
                uploadUrl={uploadWechatMaterial()}
                showDownload={true}
                downloadUrl={currPath && `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadWechatMaterial(currPath)}`}
                onValueChanged={(d) => {
                  setCurrPath(d)
                  console.log(d)
                }}
              />
            </div>
          )
        },
      })
    }
    items.push({
      type: 'text',
      dataType: 'textarea',
      label: '关键字说明',
      fieldName: 'keyDescription',
      maxLength: 256,
    })
    setFormItem(items)
  }
  useEffect(() => {
    setInnerData(readActionParameter(formData))
    setCurrPath(null)
  }, [formData])

  useEffect(() => {
    changeFormItem()
  }, [innerData, currPath])

  const onChange = (data, preData) => {
    setInnerData(data)
  }
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      name: inputdata.name,
      description: inputdata.keyDescription,
      contentType: inputdata.contentType,
      content: getContent(inputdata),
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      description: inputdata.keyDescription,
      contentType: inputdata.contentType,
      content: getContent(inputdata),
    }
  }

  const getContent = (inputdata) => {
    if (inputdata.contentType === 'image'||inputdata.contentType === 'voice'||inputdata.contentType === 'thumb' ||inputdata.contentType === 'video') {
      return { path: currPath }
    }
  }

  const readActionParameter = (inputdata) => {
    return { ...inputdata, ...inputdata?.actionParameters, keyDescription: inputdata?.description }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='菜单'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createWechatMaterial}
      updateData={updateWechatMaterial}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={innerData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onChange={onChange}
      height={clientHeight - 280}
      event={event}
    />
  )
})
