import axios from 'axios'
import { getQuestionnaire, saveQuAnswer, saveQuestionnaires } from 'common/apis/questionnaire'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { Button, Card, Checkbox, Dropdown, DropdownOption, Input, NumericInput, RadioGroup, RadioOption, Radio } from '@abb/abb-common-ux-react'
import DatePicker from "react-datepicker"
import { getDictionary } from 'slices/dataDictionarySlice'
import { getDataDictionaryDetail } from 'common/dataDictionary'
import { Col, Row } from 'components/Grid'
import "./questionaires.scss"
import { useHistory } from 'react-router-dom'
import { getQuestionaireCache, setQuestionaireCache } from 'slices/questionaireSlice'
import { useMountedState } from 'common/useMountedState'
import moment from 'moment'
import { isEmail, isIdCard, isMobile, isVehicleNumber } from 'common/utils'


export default function DetailsPreview() {
  const isMounted = useMountedState()
  const history = useHistory()
  const urlParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [main, setMain] = useState({})
  const [questions, setQuestions] = useState([])
  const [options, setOptions] = useState([])
  const dictionary = useSelector(getDictionary)
  const questionTypes = getDataDictionaryDetail(dictionary, "QuQuestionType")
  const inputTypes = getDataDictionaryDetail(dictionary, "QuInputType")
  const validateTypes = getDataDictionaryDetail(dictionary, "QuValidateType")
  const relevantInfos = getDataDictionaryDetail(dictionary, "QuRelevantInfo")
  const questionaireCache = useSelector(getQuestionaireCache)

  const questionaireId = urlParams.id
  const initData = (data) => {

    setMain(data.main)
    setQuestions(data.questions)
    setOptions(data.options)
  }
  const checkData = () => {
    //检查必填
    let nos = ''
    const sortQuestions = questions.sort((a, b) => a.rowIndex - b.rowIndex);
    sortQuestions.forEach(c => {
      if (c.isRequired && ((c.inputType == "Score" && !c.answerChoice) || (c.inputType != "Score" && !c.answerText && !c.answerChoice))) {
        nos += c.rowIndex + ","
      }
    })
    if (nos)
      return "第" + nos.substring(0, nos.length - 1) + "题必填"
    //检查字数过少
    sortQuestions.forEach(c => {
      if (c.questionType == "Text") {
        if (c.minLength > 0 && c.answerText.length < c.minLength) {
          nos += "第" + c.rowIndex + "题字数要大于" + c.minLength + ','
        }
      }
    })
    if (nos)
      return nos.substring(0, nos.length - 1)
    //检查正则
    sortQuestions.forEach(c => {
      if (c.validateType) {
        //Email:邮箱，Mobile:手机，IdNumber:身份证号，LicensePlateNo:车牌号
        switch (c.validateType) {
          case "Email":
            if (!isEmail(c.answerText)) {
              nos += "第" + c.rowIndex + "题不是合法的邮箱" + ','
            }
            break;
          case "Mobile":
            if (!isMobile(c.answerText)) {
              nos += "第" + c.rowIndex + "题不是合法的手机" + ','
            }
            break;
          case "IdNumber":
            if (!isIdCard(c.answerText)) {
              nos += "第" + c.rowIndex + "题不是合法的身份证号" + ','
            }
            break;
          case "LicensePlateNo":
            if (!isVehicleNumber(c.answerText)) {
              nos += "第" + c.rowIndex + "题不是合法的车牌号" + ','
            }
            break;

          default:
            break;
        }
      }
    })
    if (nos)
      return nos.substring(0, nos.length - 1)
  }
  //读取数据
  const getData = (id) => {
    if (!isMounted()) return
    setIsLoading(true)
    axios.post(getQuestionnaire(id), {}).then((response) => {
      if (!isMounted()) return
      if (response.status === 200) {
        initData(response.data)
      } else {
        dispatch(showNotification(NotificationType.Warn, response.data.message))
      }
    }).catch(e => {
      console.log("error: ", e)
      dispatch(showNotification(NotificationType.Alarm, "出现错误"))
    }).finally(() => {
      setIsLoading(false)
    })
  }
  const sumbitQuAnswer = () => {
    const err = checkData()
    if (err) {
      dispatch(showNotification(NotificationType.Alarm, err))
      setIsLoading(false)
      return
    }
    dispatch(showNotification(NotificationType.Success, "预览状态无法提交"))
    // setIsLoading(true)
    // const err = checkData()
    // if (err) {
    //   dispatch(showNotification(NotificationType.Alarm, err))
    //   setIsLoading(false)
    //   return
    // }
    // const data = { main, answerQuestions: questions, answerOptions: options }
    // main.questionnaireId = main.id
    // main.id = undefined
    // data.answerQuestions.forEach(c => {
    //   c.id = undefined
    // })
    // data.answerOptions.forEach(c => {
    //   c.optionId = c.id
    //   c.id = undefined
    // })
    // axios.post(saveQuAnswer(), data).then((response) => {
    //   if (!isMounted()) return;
    //   if (response.status === 200) {
    //     dispatch(showNotification(NotificationType.Success, "提交成功"))
    //     dispatch(setQuestionaireCache(null))
    //     history.push('/maindata/questionnaires')
    //   } else {
    //     dispatch(showNotification(NotificationType.Warn, response.data.message))
    //   }
    // }).catch(e => {
    //   console.log("error: ", e)
    //   dispatch(showNotification(NotificationType.Alarm, "出现错误"))
    // }).finally(() => {
    //   setIsLoading(false)
    // })
  }

  //获取当前问题的选项
  const getCurrentOptions = (question) => {
    let curOptions = options.filter(c => (question.questionId && c.questionId == question.questionId))
    curOptions = curOptions.sort((a, b) => a.rowIndex - b.rowIndex);
    return curOptions
  }
  const getSortQuestions = () => {
    if (!questions) return []
    const sortQuestions = questions.sort((a, b) => a.rowIndex - b.rowIndex);
    return sortQuestions
  }
  //渲染选项工具
  const renderOptionTools = (question, option, idx, curOptions) => {
    return (
      <div style={{ fontSize: "12px" }}>
        {option.optionText}
      </div>
    )
  }
  const getImage = (question, option) => {
    if (question.answerChoice == option.rowIndex.toString()) {
      return '/images/' + option.optionPicture
    } else {
      return '/images/0-' + option.optionPicture
    }
  }
  const renderScore = (question, curOptions) => {
    return (
      <div style={{
        display: "flex", width: "100%", padding: "8px"
      }}>{
          curOptions.map((option, index) => {
            return (
              <div key={index}
                style={{
                  border: "1px solid #eee",
                  width: "20%",
                  cursor: "pointer",
                  padding: "4px 0"
                }}
                onClick={(e) => {
                  question.answerChoice = option.rowIndex.toString()
                  setQuestions([...questions])
                }}>
                <div style={{ textAlign: "center" }}>
                  <img src={getImage(question, option)} style={{ cursor: "pointer" }} alt='cover' />
                  <div style={{
                    fontSize: "12px",
                  }}>
                    {option.optionText}
                  </div>
                </div>

              </div>
            )
          })
        }
      </div>)
  }
  const getOptionCheck = (question, option) => {
    const choice = ',' + (question.answerChoice ?? '') + ','
    return choice.indexOf(',' + option.rowIndex.toString() + ',') >= 0
  }
  const setOptionCheck = (question, option) => {
    const answerChoice = (question.answerChoice ?? '')
    let choice = (answerChoice ? (',' + answerChoice) : '') + ','
    const isCheck = choice.indexOf(',' + option.rowIndex.toString() + ',') >= 0
    if (isCheck) {
      choice = choice.replace(',' + option.rowIndex.toString() + ',', ",")
    } else {
      choice += option.rowIndex.toString() + ","
    }
    if (choice.startsWith(',')) {
      choice = choice.substring(1);
    }
    if (choice.endsWith(',')) {
      choice = choice.substring(0, choice.length - 1);
    }
    question.answerChoice = choice
    setQuestions([...questions])
  }
  const renderCheck = (question, curOptions) => {
    return (<div style={{ width: "100%", padding: "8px" }}>{
      curOptions.map((option, index) => {
        return <div key={index} className='qu-check' style={{ paddingLeft: "8px", paddingBottom: "4px" }}>
          <Checkbox
            label={option.optionText}
            value={getOptionCheck(question, option)}
            sizeClass="small"
            style={{ marginLeft: "0px", }}
            onChange={() => {
              setOptionCheck(question, option)
            }}
            monochrome={false}
          /></div>
      })
    }
    </div>)
  }

  const renderRadio = (question, curOptions) => {
    return (
      <div role="radiogroup" className="qu-radio-group ABB_CommonUX_RadioGroup__root ABB_CommonUX_RadioGroup__light ABB_CommonUX_RadioGroup__smallSize">
        {
          curOptions.map((option, index) => {
            return <div className='qu-radio' key={index}><label
              onClick={(e) => {
                question.answerChoice = option.rowIndex
                setQuestions([...questions])
                e.stopPropagation()
                e.preventDefault()
              }}>
              <input role="radio" aria-checked="false" tabIndex={question.answerChoice == option.rowIndex ? "0" : "-1"} type="radio" name={"radio-"+question.rowIndex} 
              value={option.rowIndex + ''} checked={question.answerChoice == option.rowIndex}  onChange={(e) => {}}/>
              <span></span>
              <span>{option.optionText}</span>
            </label></div>
          })
        }
      </div>
    )
  }

// const renderRadio1 = (question, curOptions) => {
//   return (<RadioGroup
//     className='qu-radio-group'
//     value={question.answerChoice}
//     sizeClass="small"
//     onChange={(newVal) => {
//       question.answerChoice = newVal
//       setQuestions([...questions])
//     }}
//     orientation="vertical"
//   >{
//       curOptions.map((option, index) => {
//         return <RadioOption style={{ border: "1px solid #eee", paddingLeft: "8px", paddingBottom: "4px" }}
//           key={index} value={option.rowIndex + ''} text={option.optionText} />
//       })
//     }
//   </RadioGroup>)
// }
//渲染选项
const renderOptions = (question) => {
  const curOptions = getCurrentOptions(question);
  if (curOptions.length > 0) {
    if (question.inputType == "Radio") {
      return renderRadio(question, curOptions)
    } else if (question.inputType == "Check") {
      return renderCheck(question, curOptions)
    } else if (question.inputType == "Score") {
      return renderScore(question, curOptions)
    }
  }
}
//渲染问题内容
const renderQuestionContent = (item, index) => {
  return (
    <div style={{ padding: "8px" }}>
      <div style={{ display: "flex", fontSize: "14px", fontWeight: 500, padding: "8px 0" }}>
        {renderRequired(item)}{index + 1 + "." + item.questionText}
      </div>
      {item.inputType == "Input" &&
        <div className="form-group" style={{ marginBottom: "4px" }}>
          <Input
            sizeClass="small"
            dataType="text"
            maxLength={item.maxLength}
            placeholder=''
            showClearIcon={true}
            value={item.answerText}
            onValueChange={(newValue) => {
              item.answerText = newValue
              setQuestions([...questions])
            }}
          />
        </div>
      }
      {item.inputType == "Textarea" &&
        <div className="form-group question-answer" style={{ marginBottom: "4px" }}>
          <Input
            sizeClass="small"
            dataType="textarea"
            maxLength={item.maxLength}
            placeholder=''
            showClearIcon={true}
            value={item.answerText}
            onValueChange={(newValue) => {
              item.answerText = newValue
              setQuestions([...questions])
            }}
          />
        </div>
      }
      {item.inputType == "Date" &&
        // <DatePicker
        //   className={"abb-date-picker" + (true ? " disabled" : "")}
        //   popperClassName="abb-date-picker-popup"
        //   dateFormat={"yyyy-MM-dd"}
        //   timeFormat="HH:mm"
        //   placeholderText="请选择日期"
        // />
        <DatePicker
          className="abb-date-picker input-sm"
          popperClassName="abb-date-picker-popup"
          dateFormat="yyyy-MM-dd"
          placeholderText="YYYY-MM-DD"
          value={item.answerText}
          onChange={e => {
            item.answerText = moment(e).isValid() ? moment(e).format('yyyy-MM-DD') : ''
            setQuestions([...questions])
          }} />
      }
      {
        item.inputType == "Decimal" && <NumericInput
          // discreet={true}
          style={{ width: "150px" }}
          placeholder="输入小数"
          value={parseFloat(item.answerText ?? 0)}
          decimals={item.decimalDigits}
          type='compact'
          sizeClass='small'
          onChange={(newValue) => {
            item.answerText = newValue.toString()
            setQuestions([...questions])
          }}
        />
      }
      {
        item.inputType == "Number" && <NumericInput
          // discreet={true}
          style={{ width: "150px" }}
          placeholder="输入数字"
          value={parseInt(item.answerText ?? 0)}
          type='compact'
          sizeClass='small'
          onChange={(newValue) => {
            item.answerText = newValue.toString()
            setQuestions([...questions])
          }}
        />
      }
      {(item.inputType == "Radio") &&
        <div className="form-group" style={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginBottom: "4px" }}>
          {renderOptions(item)}
        </div>
      }
      {(item.inputType == "Check") &&
        <div className="form-group" style={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginBottom: "4px" }}>
          {renderOptions(item)}
        </div>
      }
      {(item.inputType == "Score") &&
        <div className="form-group" style={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginBottom: "4px" }}>
          {renderOptions(item)}
        </div>
      }
      {item.inputType == "Score" && item.isNeedFeedback &&
        <div className="form-group question-answer" style={{ marginBottom: "4px" }} >
          <Input
            sizeClass="small"
            dataType="textarea"
            maxLength={1024}
            placeholder={item.feedbackPlaceHolder}
            showClearIcon={true}
            value={item.answerText}
            onValueChange={(newValue) => {
              item.answerText = newValue
              setQuestions([...questions])
            }}
          />
        </div>
      }
    </div>
  )
}
//渲染所有问题
const renderQuestions = () => {
  const sortQuestions = getSortQuestions()
  return sortQuestions.map((item, index) => {
    return (
      <div key={index} style={{ borderRadius: "4px", padding: "4px" }}>
        {renderQuestionContent(item, index)}
      </div>
    )
  })


}
//渲染问卷调查主数据
const renderMain = () => {
  return (
    <div style={{ padding: "8px", borderRadius: "4px", }}>
      <div style={{ textAlign: "center", fontSize: "20px", color: "rgb(0, 149, 255)" }}>
        <label>{main.title}</label>
      </div>
      <div style={{ textAlign: "center", fontSize: "14px", color: "#777" }}>
        {main.description}
      </div>
    </div>
  )
}
//渲染主工具栏
const renderMainTools = () => {
  return (
    <div style={{ padding: "8px" }}>
      <Row>
        <Col flex="auto">

        </Col>
        <Col flex="200px">
          {(main?.status=="Draft" || main?.status=="Suspend") &&<Button text='编辑问卷' style={{ marginRight: "4px" }} type='primary-blue' sizeClass="small" onClick={e => editQuestionaire()} />
          }
          <Button text='返回列表' type='normal' sizeClass="small" onClick={e => backToMain()} />
        </Col>
      </Row>
    </div>
  )
}
const renderRequired = (question) => {
  if (question.isRequired) {
    return <span style={{ fontSize: "12px", color: "red" }}>*</span>
  }

}
//回到问卷列表页
const backToMain = () => {
  dispatch(setQuestionaireCache(null))
  history.push('/maindata/questionnaires')
}
const editQuestionaire = (item) => {
  history.push('/maindata/questionnaireEdit/' + main.id)
}
//初始化问题数据
useEffect(() => {
  if (questionaireCache) {
    if (!isMounted()) return
    initData(JSON.parse(JSON.stringify(questionaireCache)))
  }
  else if (questionaireId) {
    getData(questionaireId)
  }
}, [questionaireId, questionaireCache])
//返回
return (
  <div id="divQuestionnaires"
    style={{ backgroundColor: "#f3f3f3", display: "flex", padding: "16px", justifyContent: "center" }}>
    <div style={{ backgroundColor: "#fff", padding: "12px", width: "375px" }}>
      {renderMainTools()}
      {renderMain()}
      {renderQuestions()}
      <Button text='提交' disabled={isLoading} isLoading={isLoading} style={{ margin: "0 20px", width: "80%" }} type='primary-blue' sizeClass="small" onClick={e => { sumbitQuAnswer() }} />
    </div>
  </div>
)
}

