import React, { useRef, useImperativeHandle, forwardRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createWechatKeyword, createWechatMenu, getWechatMaterialsByContentType, updateWechatKeyword, updateWechatMenu } from 'common/apis/wechat'
import { ImageSelector } from 'components/ImageSelector'
import axios from 'axios'
import { useMountedState } from 'common/useMountedState'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const [innerData, setInnerData] = useState({})
  const [formItem, setFormItem] = useState([])
  const [rules, setRules] = useState({})
  const isMounted = useMountedState()
  const [materials, setMaterials] = useState([])

  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const changeRules = () => {
    const r = {
      keyword: [CommonRule.Require],
      name: [CommonRule.Require],
      actionType: [CommonRule.Require],
    }
    if (innerData?.actionType === 'text') {
      r['content'] = [CommonRule.Require]
    }
    if (innerData?.actionType === 'video') {
      r['title'] = [CommonRule.Require]
      r['description'] = [CommonRule.Require]
    }
    if (innerData?.actionType === 'image' || innerData?.actionType === 'voice' || innerData?.actionType === 'video') {
      r['mediaId'] = [CommonRule.Require]
    }
    if (innerData?.actionType === 'news') {
      r['title'] = [CommonRule.Require]
      r['mediaId'] = [CommonRule.Require]
      r['url'] = [CommonRule.Require]
      r['description'] = [CommonRule.Require]

    }
    setRules(r)
  }
  //初始化数据
  const initData = {
    keyword: '',
    name: '',
    description: '',
    actionType: '',
    actionParameters: '',
  }
  //表单项
  const changeFormItem = () => {
    const items = [
      {
        type: 'text',
        dataType: 'text',
        label: '关键字',
        fieldName: 'keyword',
        maxLength: 256,
      },
      {
        type: 'text',
        dataType: 'text',
        label: '名称',
        fieldName: 'name',
        maxLength: 256,
      },
      {
        type: 'dropdown',
        dataType: 'text',
        label: '关键字行为',
        fieldName: 'actionType',
        options: [
          { label: '回复文字', value: 'text' },
          { label: '回复图文', value: 'news' },
          { label: '回复语音', value: 'voice' },
          { label: '回复图片', value: 'image' },
          { label: '回复视频', value: 'video' },
        ],
      },
    ]
    if (innerData?.actionType === 'text') {
      items.push({
        type: 'text',
        dataType: 'textarea',
        label: '文本内容',
        fieldName: 'content',
        maxLength: 256,
      })
    }
    if (innerData?.actionType === 'video') {
      items.push({
        type: 'text',
        dataType: 'text',
        label: '标题',
        fieldName: 'title',
        maxLength: 256,
      })
      items.push({
        type: 'text',
        dataType: 'textarea',
        label: '描述',
        fieldName: 'description',
        maxLength: 256,
      })
    }

    if (innerData?.actionType === 'image' || innerData?.actionType === 'voice' || innerData?.actionType === 'video') {
      items.push({
        type: 'dropdown',
        dataType: 'text',
        label: '选择素材',
        fieldName: 'mediaId',
        options: materials,
      })
    }
    if (innerData?.actionType === 'news') {
      items.push({
        type: 'text',
        dataType: 'text',
        label: '标题',
        fieldName: 'title',
        maxLength: 256,
      })
      items.push({
        type: 'dropdown',
        dataType: 'text',
        label: '封面素材',
        fieldName: 'mediaId',
        options: materials,
      })
      items.push({
        type: 'text',
        dataType: 'text',
        label: '跳转链接',
        fieldName: 'url',
        maxLength: 256,
      })
      items.push({
        type: 'text',
        dataType: 'textarea',
        label: '描述',
        fieldName: 'description',
        maxLength: 256,
      })
    }
    items.push({
      type: 'text',
      dataType: 'textarea',
      label: '关键字说明',
      fieldName: 'keyDescription',
      maxLength: 256,
    })
    setFormItem(items)
  }

  const fetchMaterials = (type) => {
    axios
      .get(getWechatMaterialsByContentType(), { params: { contentType: type === 'news' ? 'image' : type } })
      .then((e) => {
        if (!isMounted()) {
          return
        }
        if (e.data) {
          setMaterials(
            e?.data?.map((i) => {
              return { value: i.mediaId, label: i.name }
            })
          )
        }
      })
      .then((e) => {
        if (!isMounted()) {
          return
        }
      })
  }

  useEffect(() => {
    fetchMaterials(innerData.actionType)
    setInnerData(readActionParameter(formData))
  }, [formData])

  useEffect(() => {
    fetchMaterials(innerData.actionType)
  }, [innerData])
  useEffect(() => {
    changeFormItem()
    changeRules()
  }, [innerData, materials])
  const onChange = (data, preData) => {
    setInnerData(data)
  }
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      keyword: inputdata.keyword,
      name: inputdata.name,
      description: inputdata.keyDescription,
      actionType: inputdata.actionType,
      actionParameters: getActionParameter(inputdata),
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      keyword: inputdata.keyword,
      name: inputdata.name,
      description: inputdata.keyDescription,
      actionType: inputdata.actionType,
      actionParameters: getActionParameter(inputdata),
    }
  }

  const getActionParameter = (inputdata) => {
    if (inputdata.actionType === 'text') {
      return { content: inputdata.content }
    }
    if (inputdata.actionType === 'video') {
      return { title: inputdata.title, description: inputdata.description, mediaId: inputdata.mediaId }
    }
    if (inputdata.actionType === 'image' || inputdata.actionType === 'voice') {
      return { mediaId: inputdata.mediaId }
    }
    if (inputdata.actionType === 'news') {
      return { title: inputdata.title, url: inputdata.url, description: inputdata.description, mediaId: inputdata.mediaId }
    }
  }

  const readActionParameter = (inputdata) => {
    return { ...inputdata, ...inputdata?.actionParameters, keyDescription: inputdata?.description }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='菜单'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createWechatKeyword}
      updateData={updateWechatKeyword}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={innerData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onChange={onChange}
      height={clientHeight - 280}
      event={event}
    />
  )
})
