import React, { useRef, useImperativeHandle, forwardRef, useState } from 'react'
import { CommonForm, CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LoadingIndicator, Spinner, TabControl, TabItem } from '@abb/abb-common-ux-react'
import SidePanel from 'components/SidePanel'
import axios from 'axios'
import { getUsers } from 'slices/userSlice'
import { useEffect } from 'react'
import { getInstrumentPage, importInstrument, importMaterials } from 'common/apis/maindata'
import { ImageSelector } from 'components/ImageSelector'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { importTestData } from 'common/apis/singlereports'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { tr } from 'date-fns/locale'

export const ImportFormEdit = forwardRef(({ id,catalog, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [isSumbiting, setIsSumbiting] = useState(false)
  const [fileName, setFileName] = useState(null)
  const [file, setFile] = useState(null)
  const dispatch = useDispatch()

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    // Do something with the files
    if (!acceptedFiles[0]) {
      return
    }
    setFile(acceptedFiles[0])
    setFileName(acceptedFiles[0].name)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ accept: '.xlsx', onDrop })

  //初始化数据
  const initData = {}
  //表单数据项
  const formItem = [
    {
      type: 'div',
      label: '模板文件',
      fieldName: 'templete',
      maxLength: 256,
      render: (item, callback) => {
        console.log(item)
        return (
          <div style={{ paddingTop: '12px' }}>
            <Button text='下载模板' href='/templates/material.xlsx'/>
          </div>
        )
      },
    },
    {
      type: 'div',
      label: '上传文件',
      fieldName: 'file',
      maxLength: 256,
      render: (item, callback) => {
        console.log(item)
        return (
          <div style={{ paddingTop: '12px' }}>
            <div {...getRootProps({ className: 'file-picker' })} title='上传文件'>
              <input {...getInputProps({ multiple: false })} />
              {fileName ?? '上传文件'}
              {isSumbiting && (
                <div className='central-position'>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        )
      },
    },
  ]

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
  }

  
  
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showPanel,
  }))
  //显示边栏

  const saveForm = async () => {
    setIsSumbiting(true)
    await onSubmitThis()
    setIsSumbiting(false)
    resetForm()
    setShowSidePanel(false)
  }

  const showPanel = () => {
    setShowSidePanel(true)
  }

  const resetForm = () => {
    setFile(null)
    setFileName(null)
  }

  const onSubmitThis = async () => {
    if (!file) {
      dispatch(showNotification(NotificationType.Alarm, t('t_formError')))
      return
    }
    let input = {}
    formEditRef.current.validate((valid, formData) => {
      if (valid) {
        //做其他校验，返回错误信息则返回
        input = formData
      } else {
        dispatch(showNotification(NotificationType.Alarm, t('t_formError')))
        return
      }
    })
    try {
      const form = new FormData()
      form.append('file', file)
      form.append('catalogId', catalog.key)
      console.log(catalog);
      const response = await axios.post(importMaterials(), form)
      if (response.status === 200||response.status === 204) {
        dispatch(showNotification(NotificationType.Success, '导入成功'))
        console.log('update sucess')
      } else {
        dispatch(showNotification(NotificationType.Alarm, '导入失败'))
        console.log('error to get tree grant')
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, e?.error.message ?? '导入失败'))
      console.log(e)
    } finally {
    }
    setShowSidePanel(false)
    onSubmit && onSubmit()
  }
  const cancel = () => {
    setShowSidePanel(false)
    onCancel && onCancel()
  }

  //返回页面
  return (
    <SidePanel show={showSidePanel} size={400}>
      <div className='side-panel-header'>
        <div className='side-panel-title'>导入物料</div>
      </div>
      <div className='side-panel-body'>
        {!isSumbiting && (
          <>
            <TabControl type='panel'>
              <TabItem title='导入物料'>
                <div style={{ height: clientHeight - 280 + 'px' }}>
                  <CommonForm initData={initData} ref={formEditRef} rules={rules} formItem={formItem}></CommonForm>
                </div>
              </TabItem>
            </TabControl>
            <div className='form-group-button' style={{ marginTop: '5px' }}>
              <Button
                style={{ marginRight: '5px' }}
                text={t('b_inport')}
                type='primary-blue'
                sizeClass='small'
                onClick={saveForm}
                isLoading={isSumbiting}
              />
              <Button text={t('b_cancel')} type='normal' sizeClass='small' onClick={cancel} />
            </div>
          </>
        )}
        {isSumbiting && (
          <div style={{ flex: 1 }}>
            <div className='central-position'>
              <LoadingIndicator sizeClass='small' color='blue' type='radial' />
            </div>
          </div>
        )}
      </div>
    </SidePanel>
  )
})
